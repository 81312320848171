import React from 'react';
import { Route } from 'react-router-dom';

// Safety Components
import FormAccidentWithInjury from '../component/Navbar/Safety-SubMenus/formAccidentWithInjury';
import FormAccidentWithoutInjury from '../component/Navbar/Safety-SubMenus/formAccidentWithoutInjury';
import IncidentListing from '../component/Navbar/Safety-SubMenus/incidentListing';
import IncidentLog from '../component/Navbar/Safety-SubMenus/incidentLog';
import InjuriesReport from '../component/Navbar/Safety-SubMenus/injuriesReport';

// Export routes directly as an array
const SafetyRoutes = [
    // Safety => Sub-Menus
    <Route key="form-accident-with-injury" path="safety/form-accident-with-injury" element={<FormAccidentWithInjury />} />,
    <Route key="form-accident-without-injury" path="safety/form-accident-without-injury" element={<FormAccidentWithoutInjury />} />,
    <Route key="incident-listing" path="safety/incident-listing" element={<IncidentListing />} />,
    <Route key="incident-log" path="safety/incident-log" element={<IncidentLog />} />,
    <Route key="injuries-report" path="safety/injuries-report" element={<InjuriesReport />} />
];

export default SafetyRoutes;
