import React from 'react';
import styles from '../../../../css/styles';
import { StyledTableCell, StyledTableRow } from '../../../../helper/employee/employeeHelpers';
import { CommanAppBarWithAddButton } from '../../../Comman/commanAppBar';
import sharedImports from '../../../Comman/sharedImports';
import Dropdown  from '../../../Comman/Dropdown';
import PaginationControls from 'component/Comman/paginationControls';
import PageSizeSelector from 'component/Comman/PageSizeSelector';
import { TextareaAutosize } from '@mui/material';
import { Edit, Save } from '@mui/icons-material';
const { AppBar, Toolbar, Box, Paper, Grid, Table, Button, TableRow, TableHead, CssBaseline, SearchOutlinedIcon, TableContainer, CancelOutlinedIcon, NoteAddIcon, Typography, TableBody, UnfoldMoreOutlinedIcon, TextField } = sharedImports;
const drawerWidth = 300;

const reportListRows = [
    { staticId: '1', parameterUniqueCode: 'Viraj Vala', code: 'OPD', parameter_name: '15/11/2024', uom: 'Completed', normal_range: '0-100' },
    { staticId: '2', parameterUniqueCode: 'Rushi', code: 'OPD', parameter_name: '23/08/2024', uom: 'Completed', normal_range: '0-100' },
    { staticId: '3', parameterUniqueCode: 'Pranay, EMP001', code: 'OPD', parameter_name: '02/08/2024', uom: 'Completed', normal_range: '0-100' },
];

export default function TestParameterUpdate(props) {
    const [rowCount, setRowCount] = React.useState(0);
    const [data, setData] = React.useState(reportListRows);
    
    const [pagination, setPagination] = React.useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [formData, setFormData] = React.useState({
        code: '',
        name: '',
        sample: '',
        main_group: '',
        sub_group: '',
    });

    const [dropdownOptions, setDropdownOptions] = React.useState({
        samples: [],
        main_groups: [],
        sub_groups: [],
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    React.useEffect(() => {
        // Simulate API call
        const fetchDropdownData = async () => {
            const fetchedData = {
                samples: [
                    { id: '1', name: 'Consumable' },
                    { id: '2', name: 'Laboratory' },
                    { id: '3', name: 'Medicine' },
                    { id: '4', name: 'SETRID-COLD' },
                ],
                main_groups: [
                    { id: '1', name: 'Consumable' },
                    { id: '2', name: 'Laboratory' },
                    { id: '3', name: 'Medicine' },
                    { id: '4', name: 'SETRID-COLD' },
                ],
                sub_groups: [
                    { id: '1', name: 'Consumable' },
                    { id: '2', name: 'Laboratory' },
                    { id: '3', name: 'Medicine' },
                    { id: '4', name: 'SETRID-COLD' },
                ],
            };
            setDropdownOptions(fetchedData);
        };
    
        fetchDropdownData();
    }, []);  

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
    
        // Update specific row data
        setData((prevData) => {            
            const updatedData = [...prevData];
            updatedData[index] = {
                ...updatedData[index],
                [name]: value,
            };
            return updatedData;
        });
    };

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <AppBar
                        sx={{
                            width: { sm: `calc(100% - ${drawerWidth}px)` },
                            ml: { sm: `${drawerWidth}px` },
                            mt: { xs: '53px', sm: '64px' },
                            zIndex: 1099
                        }}
                        elevation={1}
                    >
                        <Toolbar sx={[styles.h, styles.bgWhite]}>
                            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Grid item xs={12} md={5}>
                                    <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                        Test Parameter Update
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={7}>
                                    <Button variant='contained' sx={{ textTransform: 'none', m: 1 }} startIcon={<Save />}> Save </Button>
                                    <Button variant='contained' startIcon={<Edit />}> Edit </Button>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <Box sx={{ mt: 8, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={1} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Code</Box>
                                    </Typography>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                                placeholder='Code'
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Name</Box>
                                    </Typography>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Name'
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>
                                    <Dropdown label="Sample" name="sample" value={formData.sample} options={dropdownOptions.samples} handleChange={handleChange} />
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} sx={{ mb: 3 }}>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Dropdown label="Main Group" name="main_group" value={formData.main_group} options={dropdownOptions.main_groups} handleChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Dropdown label="Sub Group" name="sub_group" value={formData.sub_group} options={dropdownOptions.sub_groups} handleChange={handleChange} />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                <Grid item>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<SearchOutlinedIcon />}>Search</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<NoteAddIcon />}>Export To Excel</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <PageSizeSelector pageSize={pagination.pageSize} setPagination={setPagination} />
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span' sx={{ fontWeight: 'bold', mr: 2 }}>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                    <TableRow sx={styles.TableRow}>
                                        <StyledTableCell sx={{ width: '10% !important' }}> Static Id <UnfoldMoreOutlinedIcon /> </StyledTableCell>
                                        <StyledTableCell sx={{ width: '15% !important' }}> Parameter Unique Code <UnfoldMoreOutlinedIcon /> </StyledTableCell>
                                        <StyledTableCell sx={{ width: '10% !important' }}> Code <UnfoldMoreOutlinedIcon /> </StyledTableCell>
                                        <StyledTableCell sx={{ width: '20% !important' }}> Parameter Name <UnfoldMoreOutlinedIcon /> </StyledTableCell>
                                        <StyledTableCell sx={{ width: '10% !important' }}> UOM <UnfoldMoreOutlinedIcon /> </StyledTableCell>
                                        <StyledTableCell sx={{ width: '15% !important' }}> Normal Range <UnfoldMoreOutlinedIcon /> </StyledTableCell>
                                        <StyledTableCell sx={{ width: '8% !important' }}> View </StyledTableCell>
                                        <StyledTableCell sx={{ width: '8% !important' }}> View Static </StyledTableCell>
                                    </TableRow>

                                    </TableHead>
                                    <TableBody>
                                        {data.map((row, index) => (
                                            <StyledTableRow key={row.staticId} sx={styles.TableRow}>
                                                <StyledTableCell>{row.staticId}</StyledTableCell>
                                                <StyledTableCell>{row.parameterUniqueCode}</StyledTableCell>
                                                <StyledTableCell>
                                                    <TextField
                                                        name="code"
                                                        placeholder="Code"
                                                        value={row.code}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <TextField
                                                        name="parameter_name"
                                                        placeholder="Parameter Name"
                                                        value={row.parameter_name}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <TextField
                                                        name="uom"
                                                        placeholder="UOM"
                                                        value={row.uom}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <TextareaAutosize
                                                        name="normal_range"
                                                        placeholder="Normal Range"
                                                        value={row.normal_range}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        minRows={3}
                                                        style={{
                                                            width: '100%',
                                                            padding: '8px',
                                                            borderRadius: '4px',
                                                            border: '1px solid #ccc',
                                                            resize: 'vertical',
                                                        }}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell>View</StyledTableCell>
                                                <StyledTableCell>View</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <PaginationControls
                                pagination={pagination}
                                rowCount={rowCount}
                                setPagination={setPagination}
                            />
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}