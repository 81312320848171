import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from "../component/Login/loginPage";
import Dashboard from "../component/dashboard";
import PublicFormPage from 'component/public/phsyical-link-test';
import LabFormPage from 'component/public/lab-link-test';
import PreJoineDetail from 'component/public/pre-joine-detail'
import DoctorForm from 'component/public/doctor-link-test';
import DiagnosticRoutes from './diagnosticRoutes';
import ClinicalRoutes from './clinicalRoutes';
import HRRoutes from './hrRoutes';
import SafetyRoutes from './safetyRoutes';
import PreJoineeRoutes from './preJoineeRoutes';
import MISRoutes from './misRoutes';
import AdminRoutes from './adminRoutes';
import ExaminationRoutes from './examinationRoutes';
import OPDRoutes from './opdRoutes';
import AppointmentRoutes from './appointmentRoutes';
import MyDetailsRoutes from './mydetailsRoutes';
import StockRoutes from './stockRoutes';
import ChangeLngRoutes from './changeLangRoutes';
import SupportRoutes from './supportsRoutes';

export default function RoutesDefine() {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/public-form/:id" element={<PublicFormPage />} />
            <Route path="/lab-form/:id" element={<LabFormPage />} />
            <Route path="doctor-form/:id" element={<DoctorForm />} />
            <Route path="pre-joine-detail/:id" element={<PreJoineDetail />} />
            <Route path="/dashboard" element={<Dashboard />}>
                {AdminRoutes}
                {AppointmentRoutes}
                {ChangeLngRoutes}
                {ClinicalRoutes}
                {DiagnosticRoutes}
                {ExaminationRoutes}
                {HRRoutes}
                {MISRoutes}
                {MyDetailsRoutes}
                {OPDRoutes}
                {PreJoineeRoutes}
                {SafetyRoutes}
                {StockRoutes}
                {SupportRoutes}
            </Route>
        </Routes>
    );
}