import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Container, Box, TextField, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import { SendGetRequest } from 'api/config/ApiCall';
import { getBloodGroups } from '../../helper/employee/employeeHelpers';
import Reports from './reports';
import DrReports from './drReport';


const ViewLabDetails = () => {
    const { id } = useParams();
    const drLocation = useLocation();

    // Access the second parameter from the state
    const isDoctor = drLocation?.state?.isDoctor;

    const [data, setData] = useState({
        id: '',
        full_name: '',
        email: '',
        lab: '',
        appointment_date_time: '',
        appointment_time: '',
        reportType: '',
        dob: '',
        report: null,
        reportStatus: '',
        fitnessStatus: '',
        doctorComments: '',
        phone_number: '',
        blood_group: '',
        location: {
            address: null,
            lat: '',
            lng: ''
        }
        // Add other fields as needed
    });
    const bloodGroups = getBloodGroups();

    const fetchData = React.useCallback(async () => {
        try {
            const res = await SendGetRequest("publicPreJoineeDetail", { id });
            const data = res?.data[0];
            setData(data);
        } catch (error) {
            console.error('Error fetching job data:', error);
        }
    }, [id]);

    useEffect(() => {
        fetchData();
    }, [fetchData, id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    if (!data) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h5" component="div">
                        Health Care
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container maxWidth="xl">
                <Box mt={4} textAlign="left">
                    <Typography variant="h5" component="h1" gutterBottom>
                        Pre joinee details
                    </Typography>
                </Box>
            {/* </Container>
            <Container maxWidth="xl"> */}
                <Box mt={4} textAlign="left">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Lab Name"
                                name="labName"
                                value={data?.lab_name || ''}
                                disabled
                                fullWidth
                                size='small'
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Name"
                                name="Name"
                                value={data?.full_name || ''}
                                disabled
                                fullWidth
                                size='small'
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Phone Number"
                                name="phoneNumber"
                                value={data?.phone_number || ''}
                                onChange={handleChange}
                                fullWidth
                                disabled
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Email"
                                name="email"
                                value={data?.email || ''}
                                onChange={handleChange}
                                fullWidth
                                disabled
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth size="small">
                                <InputLabel>Blood Group</InputLabel>
                                <Select
                                    label="Blood Group"
                                    name="bloodGroup"
                                    value={data?.blood_group || ''}
                                    disabled
                                >
                                    {bloodGroups.map((boolGroup, index) => (
                                        <MenuItem sx={{ ml: 3 }} key={index} value={boolGroup?.value}>
                                            {boolGroup?.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* Add other fields as needed */}
                    </Grid>
                </Box>
            </Container>
            <Container maxWidth="xl">
                <Box mt={4} textAlign="left">
                    <Typography variant="h5" component="h1" gutterBottom>
                        Address Details
                    </Typography>
                </Box>
            </Container>
            <Container maxWidth="xl">
                <Box mt={4} textAlign="left">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="location"
                                value={data.location.address}
                                disabled
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Container maxWidth="xl">
                <Box mt={4} textAlign="left">
                    <Typography variant="h5" component="h1" gutterBottom>
                        Reports Details
                    </Typography>
                </Box>
            </Container>
            {isDoctor && <DrReports lab_id={data.lab} data={data} reportStatus={data?.reportStatus} /> }
            
            {(!isDoctor && data.lab )&& <Reports lab_id={data.lab} id={data.id} dob={data.dob} reportStatus={data?.reportStatus} />}
        </>
    );
};

export default ViewLabDetails;