import React from 'react';
import { Route } from 'react-router-dom';

// Support Components
import Expense from '../component/Navbar/Support-SubMenus/Expense/expense';
import ExpenseCreate from '../component/Navbar/Support-SubMenus/Expense/expenseCreate';
import UpdateExpense from '../component/Navbar/Support-SubMenus/Expense/updateExpense';
import ExpenseReport from '../component/Navbar/Support-SubMenus/Expense/expenseReport';
import AmbulanceLog from '../component/Navbar/Support-SubMenus/Ambulance/ambulanceLog';
import AmbulanceCheck from '../component/Navbar/Support-SubMenus/Ambulance/ambulanceCheck';
import VehicleMaintenance from '../component/Navbar/Support-SubMenus/Ambulance/vehicleMaintenance';
import VehicleMaintenanceUpdate from '../component/Navbar/Support-SubMenus/Ambulance/vehicleMaintenanceUpdate';
import VehicleReport from '../component/Navbar/Support-SubMenus/Ambulance/vehicleReport';
import AmbulanceMasterSetup from '../component/Navbar/Support-SubMenus/Ambulance/ambulanceMasterSetup';
import AssetMaintenance from '../component/Navbar/Support-SubMenus/AssetManagement/assetMaintenance';
import AssetMaintenanceCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetMaintenance/assetMaintenanceCreate';
import UpdateAssetMaintenance from '../component/Navbar/Support-SubMenus/AssetManagement/assetMaintenance/updateMaintenance';
import AssetAudit from '../component/Navbar/Support-SubMenus/AssetManagement/assetAudit';
import AssetAuditCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetAuditCreate';
import AssetAdjustment from '../component/Navbar/Support-SubMenus/AssetManagement/assetAdjustment';
import AssetAdjustmentCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetAdjustmentCreate';
import AssetMaintenanceSchedule from '../component/Navbar/Support-SubMenus/AssetManagement/assetMaintenanceSchedule';
import AssetOperationalStatus from '../component/Navbar/Support-SubMenus/AssetManagement/assetOperationalStatus';
import AssetOperationalStatusCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetOperationalStatusCreate';
import AssetCheck from '../component/Navbar/Support-SubMenus/AssetManagement/assetCheck';
import AssetMovement from '../component/Navbar/Support-SubMenus/AssetManagement/assetMovement';
import AssetMovementCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetMovementCreate';
import AssetRestoration from '../component/Navbar/Support-SubMenus/AssetManagement/assetRestoration';
import AssetRestorationCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetRestorationCreate';
import AssetCheckLog from '../component/Navbar/Support-SubMenus/AssetManagement/assetCheckLog';
import AssetCheckLogCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetCheckLogCreate';
import AssetIncharge from '../component/Navbar/Support-SubMenus/AssetManagement/assetIncharge';
import AssetInchargeCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetInchargeCreate';
import AssetIssueReturn from '../component/Navbar/Support-SubMenus/AssetManagement/assetIssueReturn';
import AssetCalibrationLog from '../component/Navbar/Support-SubMenus/AssetManagement/assetCalibrationLog';
import AssetCalibrationLogCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetCalibrationLogCreate';
import AssetSetup from '../component/Navbar/Support-SubMenus/AssetManagement/assetSetup';
import AssetManagementReport from '../component/Navbar/Support-SubMenus/AssetManagement/assetManagementReport';
import AssetManagementMasterSetup from '../component/Navbar/Support-SubMenus/AssetManagement/assetManagementMasterSetup';
import AssetMaintenanceAlerts from '../component/Navbar/Support-SubMenus/AssetManagement/assetMaintenanceAlerts';
import AssetMaster from '../component/Navbar/Support-SubMenus/AssetManagement/assetMaster';
import AssetMasterCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetMasterCreate';
import AssetCategoryMaster from '../component/Navbar/Support-SubMenus/AssetManagement/assetCategoryMaster';
import AssetCategoryMasterCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetCategoryMasterCreate';
import AssetLocationMaster from '../component/Navbar/Support-SubMenus/AssetManagement/assetLocationMaster';
import AssetLocationMasterCreate from '../component/Navbar/Support-SubMenus/AssetManagement/assetLocationMasterCreate';
import BioMedicalWaste from '../component/Navbar/Support-SubMenus/BioMedicalWaste/bioMedicalWaste';
import BioMedicalWasteUpdate from '../component/Navbar/Support-SubMenus/BioMedicalWaste/bioMedicalWasteUpdate';
import BioMedicalMasterSetup from '../component/Navbar/Support-SubMenus/BioMedicalWaste/bioMedicalMasterSetup';
import BioMedicalVendorMaster from '../component/Navbar/Support-SubMenus/BioMedicalWaste/bioMedicalVendorMaster';
import BioMedicalVendorMasterCreate from '../component/Navbar/Support-SubMenus/BioMedicalWaste/bioMedicalVendorMasterCreate';
import ClaimAnalysis from '../component/Navbar/Support-SubMenus/Claim/claimAnalysis';
import HealthConnect from '../component/Navbar/Support-SubMenus/HealthConnect/healthConnect';
import HealthConnectUpdate from '../component/Navbar/Support-SubMenus/HealthConnect/healthConnectUpdate';
import HealthConnectReport from '../component/Navbar/Support-SubMenus/HealthConnect/healthConnectReport';
import WellnessActivityReport from '../component/Navbar/Support-SubMenus/WellnessActivity/wellnessActivityReport';
import TicketMaster from '../component/Navbar/Support-SubMenus/Communication/ticketMaster';
import TicketMasterCreate from '../component/Navbar/Support-SubMenus/Communication/ticketMasterCreate';
import TicketMasterUpdate from '../component/Navbar/Support-SubMenus/Communication/ticketMasterUpdate';
import CanteenInspectionDetail from '../component/Navbar/Support-SubMenus/Canteen/canteenInspectionDetail';
import CanteenInspectionDetailUpdate from '../component/Navbar/Support-SubMenus/Canteen/canteenInspectionDetailUpdate';
import CanteenEmployeeAssign from '../component/Navbar/Support-SubMenus/Canteen/canteenEmployeeAssign';
import CanteenEmployeeAssignCreate from '../component/Navbar/Support-SubMenus/Canteen/canteenEmployeeAssignCreate';
import PantryInspectionSingleEntry from '../component/Navbar/Support-SubMenus/Canteen/pantryInspectionSingleEntry';
import PantryInspectionSingleEntryUpdate from '../component/Navbar/Support-SubMenus/Canteen/pantryInspectionSingleEntryUpdate';
import PantryInspectionSingleEntryCreate from '../component/Navbar/Support-SubMenus/Canteen/pantryInspectionSingleEntryCreate';
import PantryInspectionBulk from '../component/Navbar/Support-SubMenus/Canteen/pantryInspectionBulk';
import PantryInspectionBulkCreate from '../component/Navbar/Support-SubMenus/Canteen/pantryInspectionBulkCreate';
import CheckingParameterItem from '../component/Navbar/Support-SubMenus/Canteen/checkingParameterItem';
import EmployeeHygieneParameter from '../component/Navbar/Support-SubMenus/Canteen/employeeHygieneParameter';
import PantryItemAllocation from '../component/Navbar/Support-SubMenus/Canteen/pantryItemAllocation';
import InspectionReport from '../component/Navbar/Support-SubMenus/Canteen/inspectionReport';
import ImportPantry from '../component/Navbar/Support-SubMenus/Canteen/importPantry';
import ImportCanteen from '../component/Navbar/Support-SubMenus/Canteen/importCanteen';
import CanteenMasterSetup from '../component/Navbar/Support-SubMenus/Canteen/canteenMasterSetup';
import CanteenMaster from '../component/Navbar/Support-SubMenus/Canteen/canteenMaster';
import CanteenMasterUpdate from '../component/Navbar/Support-SubMenus/Canteen/canteenMasterUpdate';
import CanteenMasterCreate from '../component/Navbar/Support-SubMenus/Canteen/canteenMasterCreate';
import PantryMaster from '../component/Navbar/Support-SubMenus/Canteen/pantryMaster';
import PantryMasterUpdate from '../component/Navbar/Support-SubMenus/Canteen/pantryMasterUpdate';
import PantryMasterCreate from '../component/Navbar/Support-SubMenus/Canteen/pantryMasterCreate';
import EmployeeHygieneMaster from '../component/Navbar/Support-SubMenus/Canteen/employeeHygieneMaster';
import EmployeeHygieneMasterUpdate from '../component/Navbar/Support-SubMenus/Canteen/employeeHygieneMasterUpdate';
import EmployeeHygieneMasterCreate from '../component/Navbar/Support-SubMenus/Canteen/employeeHygieneMasterCreate';
import CheckingParameterMaster from '../component/Navbar/Support-SubMenus/Canteen/checkingParameterMaster';
import CheckingParameterMasterUpdate from '../component/Navbar/Support-SubMenus/Canteen/checkingParameterMasterUpdate';
import CheckingParameterMasterCreate from '../component/Navbar/Support-SubMenus/Canteen/checkingParameterMasterCreate';
import CheckingParameterItemMaster from '../component/Navbar/Support-SubMenus/Canteen/checkingParameterItemMaster';
import CheckingParameterItemMasterUpdate from '../component/Navbar/Support-SubMenus/Canteen/checkingParameterItemMasterUpdate';
import CheckingParameterItemMasterCreate from '../component/Navbar/Support-SubMenus/Canteen/checkingParameterItemMasterCreate';
import TypeMaster from '../component/Navbar/Support-SubMenus/HealthLibrary/typeMaster';
import TypeMasterUpdate from '../component/Navbar/Support-SubMenus/HealthLibrary/typeMasterUpdate';
import TypeMasterCreate from '../component/Navbar/Support-SubMenus/HealthLibrary/typeMasterCreate';
import TagsMaster from '../component/Navbar/Support-SubMenus/HealthLibrary/tagsMaster';
import TagsMasterUpdate from '../component/Navbar/Support-SubMenus/HealthLibrary/tagsMasterUpdate';
import TagsMasterCreate from "../component/Navbar/Support-SubMenus/HealthLibrary/tagsMasterCreate";
import CategoryMaster from "../component/Navbar/Support-SubMenus/HealthLibrary/categoryMaster";
import CategoryMasterUpdate from "../component/Navbar/Support-SubMenus/HealthLibrary/categoryMasterUpdate";
import CategoryMasterCreate from "../component/Navbar/Support-SubMenus/HealthLibrary/categoryMasterCreate";
import SubCategoryMaster from "../component/Navbar/Support-SubMenus/HealthLibrary/subCategoryMaster";
import SubCategoryMasterUpdate from "../component/Navbar/Support-SubMenus/HealthLibrary/subCategoryMasterUpdate";
import SubCategoryMasterCreate from "../component/Navbar/Support-SubMenus/HealthLibrary/subCategoryMasterCreate";
import HealthDocumentUpload from "../component/Navbar/Support-SubMenus/HealthLibrary/healthDocumentUpload";
import HealthDocumentUploadUpdate from "../component/Navbar/Support-SubMenus/HealthLibrary/healthDocumentUploadUpdate";
import HealthDocumentUploadCreate from "../component/Navbar/Support-SubMenus/HealthLibrary/healthDocumentUploadCreate";

// Export routes directly as an array
const SupportRoutes = [
    <Route path="support/expense" element={<Expense />} />,
    <Route path="support/expense/create" element={<ExpenseCreate />} />,
    <Route path="support/expense/update/:id" element={<UpdateExpense />} />,
    <Route path="support/expense/expense-report" element={<ExpenseReport />} />,
    <Route path="support/ambulance/ambulance-log" element={<AmbulanceLog />} />,
    <Route path="support/ambulance/ambulance-check" element={<AmbulanceCheck />} />,
    <Route path="support/ambulance/vehicle-maintenance" element={<VehicleMaintenance />} />,
    <Route path="support/ambulance/vehicle-maintenance/update" element={<VehicleMaintenanceUpdate />} />,
    <Route path="support/ambulance/vehicle-report" element={<VehicleReport />} />,
    <Route path="support/ambulance/ambulance-master-setup" element={<AmbulanceMasterSetup />} />,
    <Route path="support/asset-management/asset-maintenance" element={<AssetMaintenance />} />,
    <Route path="support/asset-management/asset-maintenance/create" element={<AssetMaintenanceCreate />} />,
    <Route path="support/asset-management/asset-maintenance/update/:id" element={<UpdateAssetMaintenance />} />,
    <Route path="support/asset-management/asset-audit" element={<AssetAudit />} />,
    <Route path="support/asset-management/asset-audit/create" element={<AssetAuditCreate />} />,
    <Route path="support/asset-management/asset-adjustment" element={<AssetAdjustment />} />,
    <Route path="support/asset-management/asset-adjustment/create" element={<AssetAdjustmentCreate />} />,
    <Route path="support/asset-management/asset-maintenance-schedule" element={<AssetMaintenanceSchedule />} />,
    <Route path="support/asset-management/asset-operational-status" element={<AssetOperationalStatus />} />,
    <Route path="support/asset-management/asset-operational-status/create" element={<AssetOperationalStatusCreate />} />,
    <Route path="support/asset-management/asset-check" element={<AssetCheck />} />,
    <Route path="support/asset-management/asset-movement" element={<AssetMovement />} />,
    <Route path="support/asset-management/asset-movement/create" element={<AssetMovementCreate />} />,
    <Route path="support/asset-management/asset-restoration" element={<AssetRestoration />} />,
    <Route path="support/asset-management/asset-restoration/create" element={<AssetRestorationCreate />} />,
    <Route path="support/asset-management/asset-check-log" element={<AssetCheckLog />} />,
    <Route path="support/asset-management/asset-check-log/create" element={<AssetCheckLogCreate />} />,
    <Route path="support/asset-management/asset-incharge" element={<AssetIncharge />} />,
    <Route path="support/asset-management/asset-incharge/create" element={<AssetInchargeCreate />} />,
    <Route path="support/asset-management/asset-issue-return" element={<AssetIssueReturn />} />,
    <Route path="support/asset-management/asset-calibration-log" element={<AssetCalibrationLog />} />,
    <Route path="support/asset-management/asset-calibration-log/create" element={<AssetCalibrationLogCreate />} />,
    <Route path="support/asset-management/asset-setup" element={<AssetSetup />} />,
    <Route path="support/asset-management/asset-management-report" element={<AssetManagementReport />} />,
    <Route path="support/asset-management/asset-management-master-setup" element={<AssetManagementMasterSetup />} />,
    <Route path="support/asset-management/asset-management-master-setup/asset-maintenance-alerts" element={<AssetMaintenanceAlerts />} />,
    <Route path="support/asset-management/asset-management-master-setup/asset-master" element={<AssetMaster />} />,
    <Route path="support/asset-management/asset-management-master-setup/asset-master/create" element={<AssetMasterCreate />} />,
    <Route path="support/asset-management/asset-management-master-setup/asset-category-master" element={<AssetCategoryMaster />} />,
    <Route path="support/asset-management/asset-management-master-setup/asset-category-master/create" element={<AssetCategoryMasterCreate />} />,
    <Route path="support/asset-management/asset-management-master-setup/asset-location-master" element={<AssetLocationMaster />} />,
    <Route path="support/asset-management/asset-management-master-setup/asset-location-master/create" element={<AssetLocationMasterCreate />} />,
    <Route path="support/bio-medical-waste" element={<BioMedicalWaste />} />,
    <Route path="support/bio-medical-waste/update" element={<BioMedicalWasteUpdate />} />,
    <Route path="support/bio-medical-waste/bio-medical-master-setup" element={<BioMedicalMasterSetup />} />,
    <Route path="support/bio-medical-waste/bio-medical-master-setup/bio-medical-vendor-master" element={<BioMedicalVendorMaster />} />,
    <Route path="support/bio-medical-waste/bio-medical-master-setup/bio-medical-vendor-master/create" element={<BioMedicalVendorMasterCreate />} />,
    <Route path="support/claim/claim-analysis" element={<ClaimAnalysis />} />,
    <Route path="support/health-connect" element={<HealthConnect />} />,
    <Route path="support/health-connect/update" element={<HealthConnectUpdate />} />,
    <Route path="support/health-connect/health-connect-report" element={<HealthConnectReport />} />,
    <Route path="support/wellness-activity/wellness-activity-report" element={<WellnessActivityReport />} />,
    <Route path="support/communication/ticket-master" element={<TicketMaster />} />,
    <Route path="support/communication/ticket-master/create" element={<TicketMasterCreate />} />,
    <Route path="support/communication/ticket-master/update" element={<TicketMasterUpdate />} />,
    <Route path="support/canteen/canteen-inspection-detail" element={<CanteenInspectionDetail />} />,
    <Route path="support/canteen/canteen-inspection-detail/update" element={<CanteenInspectionDetailUpdate />} />,
    <Route path="support/canteen/canteen-employee-assign" element={<CanteenEmployeeAssign />} />,
    <Route path="support/canteen/canteen-employee-assign/create" element={<CanteenEmployeeAssignCreate />} />,
    <Route path="support/canteen/pantry-inspection-single-entry" element={<PantryInspectionSingleEntry />} />,
    <Route path="support/canteen/pantry-inspection-single-entry/update" element={<PantryInspectionSingleEntryUpdate />} />,
    <Route path="support/canteen/pantry-inspection-single-entry/create" element={<PantryInspectionSingleEntryCreate />} />,
    <Route path="support/canteen/pantry-inspection-bulk" element={<PantryInspectionBulk />} />,
    <Route path="support/canteen/pantry-inspection-bulk/create" element={<PantryInspectionBulkCreate />} />,
    <Route path="support/canteen/checking-parameter-item" element={<CheckingParameterItem />} />,
    <Route path="support/canteen/employee-hygiene-parameter" element={<EmployeeHygieneParameter />} />,
    <Route path="support/canteen/pantry-item-allocation" element={<PantryItemAllocation />} />,
    <Route path="support/canteen/inspection-report" element={<InspectionReport />} />,
    <Route path="support/canteen/import-pantry" element={<ImportPantry />} />,
    <Route path="support/canteen/import-canteen" element={<ImportCanteen />} />,
    <Route path="support/canteen/canteen-master-setup" element={<CanteenMasterSetup />} />,
    <Route path="support/canteen/canteen-master" element={<CanteenMaster />} />,
    <Route path="support/canteen/canteen-master/update" element={<CanteenMasterUpdate />} />,
    <Route path="support/canteen/canteen-master/create" element={<CanteenMasterCreate />} />,
    <Route path="support/canteen/pantry-master" element={<PantryMaster />} />,
    <Route path="support/canteen/pantry-master/update" element={<PantryMasterUpdate />} />,
    <Route path="support/canteen/pantry-master/create" element={<PantryMasterCreate />} />,
    <Route path="support/canteen/employee-hygiene-master" element={<EmployeeHygieneMaster />} />,
    <Route path="support/canteen/employee-hygiene-master/update" element={<EmployeeHygieneMasterUpdate />} />,
    <Route path="support/canteen/employee-hygiene-master/create" element={<EmployeeHygieneMasterCreate />} />,
    <Route path="support/canteen/checking-parameter-master" element={<CheckingParameterMaster />} />,
    <Route path="support/canteen/checking-parameter-master/update" element={<CheckingParameterMasterUpdate />} />,
    <Route path="support/canteen/checking-parameter-master/create" element={<CheckingParameterMasterCreate />} />,
    <Route path="support/canteen/checking-parameter-item-master" element={<CheckingParameterItemMaster />} />,
    <Route path="support/canteen/checking-parameter-item-master/update" element={<CheckingParameterItemMasterUpdate />} />,
    <Route path="support/canteen/checking-parameter-item-master/create" element={<CheckingParameterItemMasterCreate />} />,
    <Route path="support/health-library/type-master" element={<TypeMaster />} />,
    <Route path="support/health-library/type-master/update" element={<TypeMasterUpdate />} />,
    <Route path="support/health-library/type-master/create" element={<TypeMasterCreate />} />,
    <Route path="support/health-library/tags-master" element={<TagsMaster />} />,
    <Route path="support/health-library/tags-master/update" element={<TagsMasterUpdate />} />,
    <Route path="support/health-library/tags-master/create" element={<TagsMasterCreate />} />,
    <Route path="support/health-library/category-Master" element={<CategoryMaster />} />,
    <Route path="support/health-library/category-Master/update" element={<CategoryMasterUpdate />} />,
    <Route path="support/health-library/category-Master/create" element={<CategoryMasterCreate />} />,
    <Route path="support/health-library/sub-category-master" element={<SubCategoryMaster />} />,
    <Route path="support/health-library/sub-category-master/update" element={<SubCategoryMasterUpdate />} />,
    <Route path="support/health-library/sub-category-master/create" element={<SubCategoryMasterCreate />} />,
    <Route path="support/health-library/sub-category-master/create" element={<SubCategoryMasterCreate />} />,
    <Route path="support/health-library/health-document-upload" element={<HealthDocumentUpload />} />,
    <Route path="support/health-library/health-document-upload/update" element={<HealthDocumentUploadUpdate />} />,
    <Route path="support/health-library/health-document-upload/create" element={<HealthDocumentUploadCreate />} />
];

export default SupportRoutes;
