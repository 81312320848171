import sharedImports from 'component/Comman/sharedImports';
import styles from 'css/styles';
import { testSetupFilterValues, componentMap } from '../helper';
import { Suspense } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
const {
    React,
    AppBar,
    Toolbar,
    Box,
    Grid,
    Select,
    Button,
    MenuItem,
    FormControl,
    SaveIcon,
    Typography,
    FormControlLabel,
    Checkbox,
} = sharedImports;
const drawerWidth = 300;
export default function TestMaster(props) {
    const filterValues = testSetupFilterValues();
    const [formData, setFormData] = React.useState({
        test_setup: "",
        isNormal: false,
        setOrder: false,
    });
    const componentRef = React.useRef(null);
    const handleChange = async (event) => {
        const { name, checked, type, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? (checked ? true : false) : value
        }));
    };
    const renderDynamicComponent = () => {
        const Component = componentMap[formData?.test_setup] || componentMap['blood-group'];
        return <Component ref={componentRef} formData={formData?.test_setup ? formData?.test_setup : 'blood-group'} />;
    };
    const handleSave = () => {
        console.log('componentRef.current0', componentRef.current);            

        if (componentRef.current && componentRef.current.handleSave) {
            console.log('componentRef.current1', componentRef.current);            
            componentRef.current.handleSave();
        }
    };
    return (
        <>
            <React.Fragment>
                <Box sx={styles.root}>
                    <AppBar
                        sx={{
                            width: { sm: `calc(100% - ${drawerWidth}px)` },
                            ml: { sm: `${drawerWidth}px` },
                            mt: { xs: '53px', sm: '64px' },
                            zIndex: 1099
                        }}
                        elevation={1}
                    >
                        <Toolbar sx={[styles.h, styles.bgWhite]}>
                            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Grid item xs={12} md={11}>
                                    <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                        Test Setup
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={1}>
                                    <React.Fragment>
                                        <Button variant='contained' sx={{ textTransform: 'none', m: 1, mb: 2 }} startIcon={<SaveIcon />} onClick={handleSave}> Save </Button>
                                    </React.Fragment>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <Box sx={styles.firstForm}>
                        <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Test</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="test_setup"
                                                displayEmpty
                                                value={formData.test_setup}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                onChange={handleChange}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                {filterValues.map((boolGroup, index) => (
                                                    <MenuItem sx={{ ml: 3 }} key={index} value={boolGroup.value}>
                                                        {boolGroup.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        name="isNormal"
                                        checked={formData.isNormal}
                                        onChange={handleChange}
                                        control={<Checkbox />}
                                        label="IsNormal"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        name="setOrder"
                                        checked={formData.setOrder}
                                        onChange={handleChange}
                                        control={<Checkbox />}
                                        label="Set Order"
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Suspense fallback={<CircularProgress />}>
                        {renderDynamicComponent()}
                    </Suspense>
                </Box>
            </React.Fragment>
        </>
    )
}