const baseURL = process.env.REACT_APP_API_BASE_URL;
export const ApiConfig = {
  baseUrl: baseURL,
  login: "user/login",
  createUser: "user/user",
  userUrl: "user/user",
  // getUserViewData: "user/get-user-view-data",
  listAllUser: "user/user-list",
  expenseUrl: "expense/expense",
  listAllExpense: "expense/expense-list",
  createExpense: "expense/expense",
  assetMaintenanceList: "asset-maintenance/asset-maintenance-list",
  assetMaintenance: "asset-maintenance/asset-maintenance",
  userRoleUrl: "user-role/role",
  singleUserRoleUrl: "user-role/edit",
  userPermissionUrl: "user-permission/permission",
  listAllEmployeeDepartment: "employee-master-setup/employee-department-list",
  employeeDepartmentUrl: "employee-master-setup/employee-department",
  importEmployeeExcelFileUrl: "user/import-employees",
  listAllEmployeeTypeGroup: "employee-master-setup/employee-type-group-list",
  employeeTypeGroupMain: "employee-master-setup/employee-type-group",
  listAllEmployeeType: "employee-master-setup/employee-type-list",
  employeeTypeMain: "employee-master-setup/employee-type",
  listAllEmployeeSubType: "employee-master-setup/employee-sub-type-list",
  employeeSubTypeMain: "employee-master-setup/employee-sub-type",
  listAllEmployeeGrade: "employee-master-setup/employee-grade-list",
  employeeGradeMain: "employee-master-setup/employee-grade",
  listAllEmployeeCompany: "employee-master-setup/employee-company-list",
  employeeCompanyMain: "employee-master-setup/employee-company",
  listAllEmployeeContractorSubBusiness:
    "employee-master-setup/employee-contractor-subBusiness-list",
  employeeContractorSubBusinessMain:
    "employee-master-setup/employee-contractor-subBusiness",
  listAllEmployeeTargetGroup:
    "employee-master-setup/employee-target-group-list",
  employeeTargetGroupMain: "employee-master-setup/employee-target-group",
  listAllEmployeeProcessType:
    "employee-master-setup/employee-process-type-list",
  employeeProcessTypeMain: "employee-master-setup/employee-process-type",
  listAllEmployeeSkill: "employee-master-setup/employee-skill-list",
  employeeSkillMain: "employee-master-setup/employee-skill",
  listAllEmployeeGroup: "employee-master-setup/employee-group-list",
  employeeGroupMain: "employee-master-setup/employee-group",
  listAllEmployeeLegalEntity:
    "employee-master-setup/employee-legal-entity-list",
  employeeLegalEntityMain: "employee-master-setup/employee-legal-entity",
  listAllEmployeeQualification:
    "employee-master-setup/employee-qualification-list",
  employeeQualificationMain: "employee-master-setup/employee-qualification",
  listAllEmployeeDesignatedPosition:
    "employee-master-setup/employee-designated-position-list",
  employeeDesignatedPositionMain:
    "employee-master-setup/employee-designated-position",
  listAllEmployeeWorkLocation:
    "employee-master-setup/employee-work-location-list",
  employeeWorkLocationMain: "employee-master-setup/employee-work-location",
  importLocation: "employee-master-setup/import-location",
  employeeDocumentUploadUrl: "employee-master-setup/employee-image-upload",
  createEmployeeFamily: "employee-master-setup/employee-family-create",
  listAllEmployeeFamily: "employee-master-setup/list-employee-family",
  deleteEmployeeFamily: "employee-master-setup/employee-family",
  singleList: "employee-master-setup/employee-family-single-list",
  updateFamilyRow: "employee-master-setup/employee-family-edit",
  createEmployeeEmployment: "employee-master-setup/employee-employment-create",
  listAllEmployeeEmployment: "employee-master-setup/list-employee-employment",
  deleteEmployeeEmployment: "employee-master-setup/employee-employment",
  employmentSingleList: "employee-master-setup/employee-employment-single-list",
  updateEmploymentRow: "employee-master-setup/employee-employment-edit",
  getAllInputJobDetailData: "user/get-input-job-details",
  // listUserByRole: "user/user-list-by-role",
  listEmployeeCustomSearch: "employee-master-setup/employee-custom-search",
  employeeCustomFields: "employee-master-setup/employee-custom-fields",
  getSubEmployeeType: "user/get-sub-employee-type",
  getEmployeeType: "user/get-employee-type",
  insertLanguage: "language/insert-language",
  getLanguage: "language/get-language",
  getAllEmployeeTemporaryData: "user/get-all-empl-tem-data",
  saveTemDataToEmployee: "user/save-tem-data-to-employee",
  deleteEmployeeTemData: "user/delete-employee-tem-data",
  // pre-joinee
  importPreJoineeEmployee: "pre-joinee/import-pre-joinee-employee",
  preJoineeList: "pre-joinee/pre-joinee-list",
  prejoineeDetial:"pre-joinee/pre-joinee-detail",
  preJoineeAssignPermanentCode: "pre-joinee/assign-permanent-code",
  preJoineeDoctorAssign: "pre-joinee/pre-joinee-doctor-assign",
  sendEmailToHrPrejoinee: "pre-joinee/send-email-to-hr-prejoinee",
  sendLinkToPreJoinee: "pre-joinee/send-public-link-to-pre-joinee",

  downloadPreJoineeTemplate: "pre-joinee/template",
  accidentWithInjury: "safty/accident-with-injury",
  getAccidentWithoutInjury: "safty/list-without-injury",
  importUser: "user/import-user",
  saveTemUser: "user/save-tem-data-to-user",
  getAllUserTemporaryData: "user/get-all-user-tem-data",
  deleteUserTemData: "user/delete-user-tem-data",
  documentUploadUrl: "admin-tool/import-document",
  listAllDocumentUrl: "admin-tool/list-all-document",
  exportEmployeeSubType: "user/export-employee-sub-type",
  exportDepartment: "user/export-department",
  exportDesignation: "user/export-designation",
  exportTargetGroup: "user/export-target-group",
  exportGrade: "user/export-grade",
  fetchDataBasedOnEmployeeCode: "user/fetch-data-based-on-employee-code",
  insertEmployeeTransferIn: "employee-management/insertEmployeeTransferIn",
  getAllEmployeeTransferIn: "employee-management/getAllEmployeeTransferIn",
  deleteEmployeeTransferIn: "employee-management/deleteEmployeeTransferIn",
  getSingleEmployeeTransferIn:
    "employee-management/getSingleEmployeeTransferIn",
  updateEmployeeTransferIn: "employee-management/updateEmployeeTransferIn",
  insertEmployeeTransferOut: "employee-management/insertEmployeeTransferOut",
  getAllEmployeeTransferOut: "employee-management/getAllEmployeeTransferOut",
  deleteEmployeeTransferOut: "employee-management/deleteEmployeeTransferOut",
  getSingleEmployeeTransferOut:
    "employee-management/getSingleEmployeeTransferOut",
  updateEmployeeTransferOut: "employee-management/updateEmployeeTransferOut",
  changePassword: "user/change-password",
  importDependentEmployee: "user/import-dependent-employee",
  getFirstAidTrainingLog: "employee-management/getFirstAidTrainingLog",
  getFirtAidDashboardData: "user/get-first-aid-dashboard-data",
  getReportFilter: "hr-report/get-report-filter",
  getReportList: "hr-report/get-report-list",
  listAllEmployeeJobTitle: "employee-master-setup/employee-job-title-list",
  employeeJobTitleMain: "employee-master-setup/employee-job-title",
  listAllEmployeeMedicalCoverage:
    "employee-master-setup/employee-medical-coverage-list",
  employeeMedicalCoverageMain:
    "employee-master-setup/employee-medical-coverage",
  listAllLabsUrl: "lab/labs",
  singleLabsUrl: "lab/single-listing",
  listAllPackagesUrl: "packages/packages",
  singlePackageUrl: "packages/single-listing",
  employeeActiveTestReports: "packages/get-active-test-reports",
  listAllEmployeeDesignation: "employee-master-setup/employee-designation-list",
  employeeDesignationMain: "employee-master-setup/employee-designation",
  employeeTestReportsMain: "employee-master-setup/employee-test-reports",
  employeeTestReportsList: "employee-master-setup/employee-test-reports-list",
  getNearByLabs: "public/nearby-lab",
  addTemporaryPrejoinee: "public/temporary-pre-joinee",
  retrieveLabReports: "public/retrieve-lab-reports",
  uploadReportDocument: "public/upload-report-document",
  publicPreJoineeDetail: "public/pre-joinee-list-detail",
  configureAdminSettings: "admin-tool/configure-admin-settings",
  fetchAdminSettingData: "admin-tool/fetch-admin-setting-data",
  deleteReportDocument: "public/delete-report-document",
  updateAppoinmentStatus: "public/update-appointment-status",
  doctorPreJoineeDetail: "public/doctor-pre-joinee-detail",
  updateReportDocumentStatus: "public/update-report-document-status",
  getPackagesByDob: "public/get-package-and-report-by-age",
  validatePreJoineeEmployee: "pre-joinee/validate-pre-joinee-employee",
  fetchDiagnosticSetup: "diagnostic/fetch-diagnostic-setup",
  addDoctor: "doctor/add-dr",
  doctorList: "doctor/list-dr",
  changeReportStatus: "public/change-report-status",
  changeFitnessStatus: "public/change-fitness-status",
  // Doctor Import APIs
  downloadDoctorTemplate: "doctor/template",
  validateDoctorImport: "doctor/validate-doctor-import",
  importDoctors: "doctor/import-doctors",
  // Doctor Activity Type endpoints
  doctorActivityTypeList: "doctor/doctor-activity-type",
  doctorActivityTypeMain: "doctor/doctor-activity-type",
  // Doctor Master endpoints
  doctorMasterList: "doctor/doctor-master",
  doctorMasterMain: "doctor/doctor-master",
  // Diagnostic Test endpoints
  diagnosticTestsList: "diagnostic/tests",
  diagnosticTest: "diagnostic/test-by-id",
  saveDiagnosticSetup: "diagnostic/save-diagnostic-setup",
};
