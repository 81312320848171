import React from 'react';
import { Route } from 'react-router-dom';

import StockStatus from "../component/Navbar/Stock-SubMenus/Stock/stockStatus";
import StockBatch from "../component/Navbar/Stock-SubMenus/Stock/stockBatch";
import StockEnquiry from "../component/Navbar/Stock-SubMenus/Stock/stockEnquiry";
import StockInward from "../component/Navbar/Stock-SubMenus/Stock/stockInward";
import StockInwardRequest from "../component/Navbar/Stock-SubMenus/Stock/stockInwardRequest";
import StockInwardReturn from "../component/Navbar/Stock-SubMenus/Stock/stockInwardReturn";
import StockIssue from "../component/Navbar/Stock-SubMenus/Stock/stockIssue";
import StockIssueRequest from "../component/Navbar/Stock-SubMenus/Stock/stockIssueRequest";
import StockIssueReturn from "../component/Navbar/Stock-SubMenus/Stock/stockIssueReturn";
import StockIssueBulk from "../component/Navbar/Stock-SubMenus/Stock/stockIssueBulk";
import StockIssueCount from "../component/Navbar/Stock-SubMenus/Stock/stockIssueCount";
import OtherStockBatch from "../component/Navbar/Stock-SubMenus/Stock/otherStockBatch";
import OtherStockIssue from "../component/Navbar/Stock-SubMenus/Stock/otherStockIssue";
import StockAdjustment from "../component/Navbar/Stock-SubMenus/Stock/stockAdjustment";
import StockTransfer from "../component/Navbar/Stock-SubMenus/Stock/stockTransfer";
import StockTransferRequest from "../component/Navbar/Stock-SubMenus/Stock/stockTransferRequest";
import StockTransaction from "../component/Navbar/Stock-SubMenus/Stock/stockTransaction";
import Report from "../component/Navbar/Stock-SubMenus/Stock/report";
import ImportStockItemRestrictive from "../component/Navbar/Stock-SubMenus/Stock/importStockItemRestrictive";
import StockReset from "../component/Navbar/Stock-SubMenus/Stock/stockReset";
import StockDiscard from "../component/Navbar/Stock-SubMenus/Stock/stockDiscard";
import FirstAidInspection from "../component/Navbar/Stock-SubMenus/FirstAid/firstAidInspection";
import ImportFirstAid from "../component/Navbar/Stock-SubMenus/FirstAid/importFirstAid";
import NursingListing from "../component/Navbar/Stock-SubMenus/Pharmacy/nursingListing";
import PharmacyQueueListing from "../component/Navbar/Stock-SubMenus/Pharmacy/pharmacyQueueListing";
import PharmacyIssueReturn from "../component/Navbar/Stock-SubMenus/Pharmacy/pharmacyIssueReturn";
import SubGroupForInWholeUnit from "../component/Navbar/Stock-SubMenus/Master/subGroupForInWholeUnit";
import StockItemPlanning from "../component/Navbar/Stock-SubMenus/Master/stockItemplanning";
import StockItemParameterUpdate from "../component/Navbar/Stock-SubMenus/Master/stockItemParameterUpdate";
import StockItemDetailUpdate from "../component/Navbar/Stock-SubMenus/Master/stockItemDetailUpdate";

// Export routes directly as an array
const StockRoutes = [
    <Route path="stock/stock-status" element={<StockStatus />} />,
    <Route path="stock/stock-batch" element={<StockBatch />} />,
    <Route path="stock/stock-enquiry" element={<StockEnquiry />} />,
    <Route path="stock/stock-inward" element={<StockInward />} />,
    <Route path="stock/stock-inward-request" element={<StockInwardRequest />} />,
    <Route path="stock/stock-inward-return" element={<StockInwardReturn />} />,
    <Route path="stock/stock-issue" element={<StockIssue />} />,
    <Route path="stock/stock-issue-request" element={<StockIssueRequest />} />,
    <Route path="stock/stock-issue-return" element={<StockIssueReturn />} />,
    <Route path="stock/stock-issue-bulk" element={<StockIssueBulk />} />,
    <Route path="stock/stock-issue-count" element={< StockIssueCount />} />,
    <Route path="stock/other-stock-batch" element={<OtherStockBatch />} />,
    <Route path="stock/other-stock-issue" element={<OtherStockIssue />} />,
    <Route path="stock/stock-adjustment" element={<StockAdjustment />} />,
    <Route path="stock/stock-transfer" element={<StockTransfer />} />,
    <Route path="stock/stock-transfer-request" element={<StockTransferRequest />} />,
    <Route path="stock/stock-transaction" element={<StockTransaction />} />,
    <Route path="stock/report" element={<Report />} />,
    <Route path="stock/import-stock-item-restrictive" element={<ImportStockItemRestrictive />} />,
    <Route path="stock/stock-reset" element={<StockReset />} />,
    <Route path="stock/stock-discard" element={<StockDiscard />} />,
    <Route path="stock/first-aid/first-aid-inspection" element={<FirstAidInspection />} />,
    <Route path="stock/first-aid/import-first-aid" element={<ImportFirstAid />} />,
    <Route path="stock/pharmacy/nursing-listing" element={<NursingListing />} />,
    <Route path="stock/pharmacy/pharmacy-queue-listing" element={<PharmacyQueueListing />} />,
    <Route path="stock/pharmacy/pharmacy-issue-return" element={<PharmacyIssueReturn />} />,
    <Route path="stock/master/sub-group-for-in-whole-unit" element={<SubGroupForInWholeUnit />} />,
    <Route path="stock/master/stock-item-planning" element={<StockItemPlanning />} />,
    <Route path="stock/master/stock-item-parameter-update" element={<StockItemParameterUpdate />} />,
    <Route path="stock/master/stock-item-detail-update" element={<StockItemDetailUpdate />} />,

];

export default StockRoutes;
