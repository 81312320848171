import React from 'react';
import styles from '../../../../css/styles';
import { StyledTableCell, StyledTableRow } from '../../../../helper/employee/employeeHelpers';
import { CommanAppBarWithAddButton } from '../../../Comman/commanAppBar';
import sharedImports from '../../../Comman/sharedImports';
import EditOutlinedIcon from '@mui/icons-material/EditLocationTwoTone';
import Dropdown  from '../../../Comman/Dropdown';
import { SendGetRequest } from '../../../../api/config/ApiCall';
import PaginationControls from 'component/Comman/paginationControls';
const { Box, Paper, Grid, Table, Button, TableRow, TableHead, CssBaseline, SearchOutlinedIcon, TableContainer, CancelOutlinedIcon, NoteAddIcon, FileUploadOutlinedIcon, TableBody, TextField } = sharedImports;

const reportListRows = [
    { employeeCode: '30001800', employeeName: 'Viraj Vala', result: 'OPD', remark: '15/11/2024', status: 'Completed' },
    { employeeCode: '9', employeeName: 'Rushi', result: 'OPD', remark: '23/08/2024', status: 'Completed' },
    { employeeCode: '9', employeeName: 'Pranay, EMP001', result: 'OPD', remark: '02/08/2024', status: 'Completed' },
];

export default function TestListingOpd(props) {
    const [rowCount, setRowCount] = React.useState(0);
    
    const [pagination, setPagination] = React.useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [state, setState] = React.useState({
        examinationType: '',
        round: '',
        departmentGroup: '',
        department: '',
        testStatus: '',
        test: '',
        parameter: '',
        range: '',
    });

    const [dropdownOptions, setDropdownOptions] = React.useState({
        examinationTypes: [],
        rounds: [],
        employeeDepartment: [],
        departmentGroups: [],
        testStatuses: [],
        tests: [],
        parameters: [],
        ranges: [],
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState((prev) => ({ ...prev, [name]: value }));
    };

    // Function to fetch dropdown data dynamically
    const fetchDropdownData = async (inputType) => {
        try {
            const response = await SendGetRequest("getAllInputJobDetailData", { type: inputType });
            if (inputType === "employeeDepartment") {
                setDropdownOptions((prev) => ({
                    ...prev,
                    employeeDepartment: response.data.employeeDepartment,
                }));
            }
        } catch (error) {
            console.error(`Error fetching data for ${inputType}:`, error);
        }
    };

    React.useEffect(() => {
        // Simulate API call
        const fetchDropdownData = async () => {
            const fetchedData = {
                examinationTypes: [
                    { id: '1', name: 'Periodic' },
                    { id: '2', name: 'Laboratory' },
                    { id: '3', name: 'Medicine' },
                    { id: '4', name: 'SETRID-COLD' },
                ],
                rounds: [
                    { id: '1', name: 'Consumable' },
                    { id: '2', name: 'Laboratory' },
                    { id: '3', name: 'Medicine' },
                    { id: '4', name: 'SETRID-COLD' },
                ],
                departmentGroups: [
                    { id: '1', name: 'Consumable' },
                    { id: '2', name: 'Laboratory' },
                    { id: '3', name: 'Medicine' },
                    { id: '4', name: 'SETRID-COLD' },
                ],
                employeeDepartment: [
                    { id: '1', name: 'Consumable' },
                    { id: '2', name: 'Laboratory' },
                    { id: '3', name: 'Medicine' },
                    { id: '4', name: 'SETRID-COLD' },
                ],
                tests: [
                    { "id": "1", "name": "ANGIOGRAPHY" },
                    { "id": "2", "name": "BLOOD GROUP" },
                    { "id": "3", "name": "Cardiac Profile" },
                    { "id": "4", "name": "CBC" },
                    { "id": "5", "name": "COLLAGEN PROFILE" },
                    { "id": "6", "name": "Dengue Test" },
                    { "id": "7", "name": "DIABETIC PROFILE" },
                    { "id": "8", "name": "ECG" },
                    { "id": "9", "name": "ECHO" },
                    { "id": "10", "name": "HEPATIC PROFILE" },
                    { "id": "11", "name": "HIV" },
                    { "id": "12", "name": "Hormonal Profile" },
                    { "id": "13", "name": "Iron Profile" },
                    { "id": "14", "name": "LIPID PROFILE" },
                    { "id": "15", "name": "Malaria Test" },
                    { "id": "16", "name": "MAMMOGRAPHY" },
                    { "id": "17", "name": "MANTOUX" },
                    { "id": "18", "name": "Other" },
                    { "id": "19", "name": "Pancreatic Profile" }
                ],
                parameters: [
                    { id: '1', name: 'Consumable' },
                    { id: '2', name: 'Laboratory' },
                    { id: '3', name: 'Medicine' },
                    { id: '4', name: 'SETRID-COLD' },
                ],
                ranges: [
                    { id: '1', name: 'Consumable' },
                    { id: '2', name: 'Laboratory' },
                    { id: '3', name: 'Medicine' },
                    { id: '4', name: 'SETRID-COLD' },
                ],
            };
            setDropdownOptions(fetchedData);
        };
    
        fetchDropdownData();
    }, []);  

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <CommanAppBarWithAddButton appBarTitle="Lipid Profile Update" handleUrl="#"/>
                    <Box sx={{ mt: 8, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={1} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3} lg={3}>
                                    <Dropdown label="Examination Type" name="examinationType" value={state.examinationType} options={dropdownOptions.examinationTypes} handleChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>
                                    <Dropdown label="Round" name="round" value={state.round} options={dropdownOptions.rounds} handleChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}></Grid>
                                <Grid item xs={12} md={3} lg={3}></Grid>
                            </Grid>
                            <Grid container spacing={1} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3} lg={3}>
                                    <Dropdown label="Department Group" name="departmentGroup" value={state.departmentGroup} options={dropdownOptions.departmentGroups} handleChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>
                                    <Dropdown label="Department" name="department" value={state.department} options={dropdownOptions.employeeDepartment} handleChange={handleChange} onOpen={() => fetchDropdownData("employeeDepartment")} />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}></Grid>
                            </Grid>

                            <Grid container spacing={1} sx={{ mb: 3 }}>
                                <Grid item xs={12} md={3} lg={3}>
                                    <Dropdown label="Test" name="test" value={state.test} options={dropdownOptions.tests} handleChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Dropdown label="Parameter" name="parameter" value={state.parameter} options={dropdownOptions.parameters} handleChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Dropdown label="Ranges" name="ranges" value={state.range} options={dropdownOptions.ranges} handleChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>
                            </Grid>
                            <Grid container spacing={1} sx={{ mb: 3 }}>
                                <Grid item xs={12} md={3}>
                                    <input
                                        accept="image/*"
                                        id="contained-button-file"
                                        type="file"
                                        name="document"
                                        onChange={handleChange}
                                        multiple // Allow multiple file selection
                                    />
                                    <label htmlFor="contained-button-file"></label>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                <Grid item>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<SearchOutlinedIcon />}>Search</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<NoteAddIcon />}>Export</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileUploadOutlinedIcon />}>Import From Excel</Button>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} sx={{ mt: 1, pr: 3 }} justifyContent='end'>
                                <TextField
                                    id="filled-search"
                                    type="search"
                                    size='small'
                                    sx={{ mr: 1 }}
                                />
                                <Button variant='outlined' sx={{ textTransform: 'none' }}>Apply All</Button>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ fontWeight: 'bold' }}></Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span' sx={{ fontWeight: 'bold', mr: 2 }}>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Employee Code</StyledTableCell>
                                            <StyledTableCell>Employee Name</StyledTableCell>
                                            <StyledTableCell>Result</StyledTableCell>
                                            <StyledTableCell>Remark</StyledTableCell>
                                            <StyledTableCell>Status</StyledTableCell>
                                            <StyledTableCell>Edit</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.examination} sx={styles.TableRow}>
                                                <StyledTableCell>{row.employeeCode}</StyledTableCell>
                                                <StyledTableCell>{row.employeeName}</StyledTableCell>
                                                <StyledTableCell> {row.result} </StyledTableCell>
                                                <StyledTableCell>{row.remark}</StyledTableCell>
                                                <StyledTableCell>{row.status}</StyledTableCell>
                                                <StyledTableCell><EditOutlinedIcon /></StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <PaginationControls
                                pagination={pagination}
                                rowCount={rowCount}
                                setPagination={setPagination}
                            />
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}