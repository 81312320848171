import React, { useState, useEffect } from "react";
import {
    Box,
    AppBar,
    Button,
    Toolbar,
    Grid,
    Typography,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Paper,
} from "@mui/material";
import {StyledTableCell, StyledTableRow} from "helper/employee/employeeHelpers";
import { useNavigate } from "react-router-dom";
import { SendGetRequest, SendPostRequest } from 'api/config/ApiCall';
import { enqueueSnackbar } from "notistack";
import styles from "css/styles";
import SearchIcon from "@mui/icons-material/Search";
import PageSizeSelector from 'component/Comman/PageSizeSelector';
import PaginationControls from 'component/Comman/paginationControls';
const drawerWidth = 300;

const DoctorServiceFav = () => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [doctors, setDoctors] = useState([]);
    const [services, setServices] = useState([]);
    const [formData, setFormData] = useState({
        doctorId: '',
        serviceId: '',
        favoriteOrder: '',
        isActive: true
    });
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [globalFilter, setGlobalFilter] = useState("");
    const [diagnosticName, setDiagnosticName] = useState("");
    const [data, setData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [isSearched, setIsSearched] = useState(false);

    const fetchDoctors = async () => {
        try {
            const response = await SendGetRequest('doctorList', {});
            if (response.status) {
                setDoctors(response.data.drUsers || []);
            }
        } catch (error) {
            console.error("Error fetching doctors:", error);
            enqueueSnackbar("Error fetching doctors", { variant: "error" });
        }
    };

    const fetchServices = async () => {
        try {
            const response = await SendGetRequest('servicesList', {});
            if (response.status) {
                setServices(response.data || []);
            }
        } catch (error) {
            console.error("Error fetching services:", error);
            enqueueSnackbar("Error fetching services", { variant: "error" });
        }
    };

    const fetchData = async () => {
        try {
            const response = await SendGetRequest('doctorServiceFavList', {
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
                globalFilter: globalFilter,
                diagnosticName: diagnosticName
            });
            if (response.status) {
                setData(response.data || []);
                setRowCount(0);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            enqueueSnackbar("Error fetching data", { variant: "error" });
        }
    };

    useEffect(() => {
        fetchDoctors();
        fetchServices();
        if (!isSearched) {
            fetchData();
        }
    }, [pagination, isSearched]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormData({
            doctorId: '',
            serviceId: '',
            favoriteOrder: '',
            isActive: true
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const response = await SendPostRequest('addDoctorServiceFav', formData);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                handleClose();
                fetchData();
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        } catch (error) {
            console.error("Error adding service favorite:", error);
            enqueueSnackbar("Error adding service favorite", { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = () => {
        if (diagnosticName.trim()) {
            setIsSearched(true);
            fetchData();
        }
    };

    const handleClear = () => {
        if (isSearched) {
            setDiagnosticName("");
            setIsSearched(false);
            fetchData();
        }
    };

    return (
        <Box>
            <AppBar
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    mt: { xs: '53px', sm: '64px' },
                    zIndex: 1099
                }}
                elevation={1}
            >
                <Toolbar sx={[styles.h, styles.bgWhite]}>
                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid item>
                            <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                Doctor Service Favorites
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button 
                                variant='contained' 
                                sx={{ textTransform: 'none', m: 1 }} 
                                onClick={handleClickOpen}
                            >
                                Add Service Favorite
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <Box sx={{ mt: 8, p: 3 }}>
                {/* Search Section */}
                <Paper sx={{ p: 2, mb: 3 }}>
                    <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                        <TextField
                            label="Diagnostic Name"
                            variant="outlined"
                            size="small"
                            value={diagnosticName}
                            onChange={(e) => setDiagnosticName(e.target.value)}
                            placeholder="Diagnostic Name"
                        />
                        <Button
                            variant="contained"
                            startIcon={<SearchIcon />}
                            onClick={handleSearch}
                            disabled={!diagnosticName.trim()}
                        >
                            Search
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={handleClear}
                            disabled={!isSearched}
                        >
                            Clear
                        </Button>
                    </Box>
                </Paper>

                <Box sx={{ mt: 2, mb: 7, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                    <Box component="div" xs={12}>
                        <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                <PageSizeSelector pageSize={pagination.pageSize} setPagination={setPagination} />

                                <Grid item>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>entries </Box>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                <TextField
                                    id="filled-search"
                                    type="search"
                                    size='small'
                                    value={globalFilter}
                                    onChange={(e) => setGlobalFilter(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <TableContainer component={Paper} sx={{ mb: 2 }}>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <StyledTableRow sx={styles.TableRow}>
                                        <StyledTableCell >
                                            Sr no.
                                        </StyledTableCell>
                                        <StyledTableCell >
                                            Doctor Name
                                        </StyledTableCell>
                                        <StyledTableCell >
                                            Service Name
                                        </StyledTableCell>
                                        <StyledTableCell >
                                           Favorite Order
                                        </StyledTableCell>
                                        <StyledTableCell >
                                           Status
                                        </StyledTableCell>

                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        rowCount === 0 ? (
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={7} align="center">
                                                    No data found
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ) : (
                                            data.map((row, index) => (
                                                <StyledTableRow key={row.id} sx={styles.TableRow}>
                                                    <StyledTableCell>{index + 1}</StyledTableCell>
                                                    <StyledTableCell> {row.doctorName}</StyledTableCell>
                                                    <StyledTableCell>{row.serviceName}</StyledTableCell>
                                                    <StyledTableCell>{row.favoriteOrder}</StyledTableCell>
                                                    <StyledTableCell>{row.isActive ? 'Active' : 'Inactive'}</StyledTableCell>

                                                </StyledTableRow>
                                            )))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <PaginationControls
                            pagination={pagination}
                            rowCount={rowCount}
                            setPagination={setPagination}
                        />
                    </Box>
                </Box>
            </Box>

            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>Add Doctor Service Favorite</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel>Select Doctor</InputLabel>
                            <Select
                                name="doctorId"
                                value={formData.doctorId}
                                onChange={handleChange}
                                label="Select Doctor"
                            >
                                {doctors.map((doctor) => (
                                    <MenuItem key={doctor.id} value={doctor.id}>
                                        {doctor.full_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel>Select Service</InputLabel>
                            <Select
                                name="serviceId"
                                value={formData.serviceId}
                                onChange={handleChange}
                                label="Select Service"
                            >
                                {services.map((service) => (
                                    <MenuItem key={service.id} value={service.id}>
                                        {service.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            fullWidth
                            label="Favorite Order"
                            name="favoriteOrder"
                            type="number"
                            value={formData.favoriteOrder}
                            onChange={handleChange}
                            InputProps={{ inputProps: { min: 1 } }}
                        />

                        <FormControl fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select
                                name="isActive"
                                value={formData.isActive}
                                onChange={handleChange}
                                label="Status"
                            >
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={loading}>
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleSubmit} 
                        variant="contained" 
                        disabled={loading || !formData.doctorId || !formData.serviceId || !formData.favoriteOrder}
                    >
                        {loading ? 'Saving...' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default DoctorServiceFav;
