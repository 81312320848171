import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Textarea from "@mui/joy/Textarea";
import dayjs from "dayjs";
import styles from "css/styles";
import { StyledTableCell, StyledTableRow } from "helper/employee/employeeHelpers";
import {
  SendGetRequest,
  SendPutRequest,
  SendPostRequest,
} from "api/config/ApiCall";
import { useSnackbar } from "notistack";
import { checkStatus } from "../../helper";
import { Link } from "react-router-dom";
import moment from 'moment';

const initialState = {
  status: "",
  reason: null,
  documentID: "",
  fitness_status: "",
};

export default function DrReports(props) {
  const [formData, setFormData] = useState(initialState);
  const { enqueueSnackbar } = useSnackbar();
  const { id, dob, fitnessStatus } = props.data;
  const [reportData, setReportData] = useState([]);
  const [isEditable, setIsEditable] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const fetchData = React.useCallback(async () => {

    try {
      const response = await SendGetRequest("getPackagesByDob", {
        date: dob,
        user_id: id,
      });
      if (!response.status) {
        enqueueSnackbar(response.data.message, { variant: "warning" });
        return;
      }

      if (!response?.data?.data?.packages) {
        enqueueSnackbar(response.data.message, { variant: "warning" });
      }
      const reports = response?.data?.data?.reports || [];
      setReportData(reports);
    } catch (error) {
      console.error("Error fetching job data:", error);
    }
  }, [dob, enqueueSnackbar, id]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleOpenDialog = (reportID, status, comment) => {
    setOpenDialog(true);
    setFormData({
      ...formData,
      documentID: reportID,
      status: status,
      reason: comment,
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setFormData(initialState);
  };

  const handleSubmit = async () => {
    try {
      const statusData =
        formData.documentID !== 0
          ? {
              status: formData.status,
              reason: formData.status === "2" ? formData.reason : null,
              documentID: formData.documentID,
            }
          : {
              status: formData.status,
              reason: formData.status === "2" ? formData.reason : null,
              user_id: id,
            };
      if (formData.status === "2" && !formData.reason) {
        enqueueSnackbar("Please enter reason for rejection", {
          variant: "warning",
        });
        return;
      }
      const response =
        formData.documentID !== 0
          ? await SendPutRequest("updateReportDocumentStatus", statusData, id)
          : await SendPostRequest("changeFitnessStatus", statusData);
      if (response.status) {
        enqueueSnackbar(response.message, { variant: "success" });
        fetchData();
        setFormData(initialState);
        handleCloseDialog();
      } else {
        enqueueSnackbar(response.message, { variant: "warning" });
        handleCloseDialog();
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  if (reportData === undefined) {
    return <div>Loading...</div>;
  }
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const PopupHeading = formData.documentID !== 0 ? "Document" : "Fitness";
  return (
    <>
      <Container maxWidth="xl">
        {isEditable ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={10} />
              <Grid item xs={12} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={fitnessStatus === "1" ? true : false}
                  onClick={() => handleOpenDialog(0, fitnessStatus, "")}
                >
                  Change Fitness Status
                </Button>
              </Grid>
              <Grid item xs={12} md={6} />
            </Grid>
            <TableContainer component={Paper} sx={{ mb: 2 }}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow sx={styles.TableRow}>
                    <StyledTableCell>
                      <strong>Report Name</strong>
                    </StyledTableCell>
                    <StyledTableCell>
                      <strong>Created On</strong>
                    </StyledTableCell>
                    <StyledTableCell>
                      <strong>Uploaded Images</strong>
                    </StyledTableCell>
                    <StyledTableCell>
                      <strong>Document Status</strong>
                    </StyledTableCell>
                    <StyledTableCell>
                      <strong>Doctor Reject Comment</strong>
                    </StyledTableCell>
                    <StyledTableCell>
                      <strong>Actions</strong>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData.length === 0 ? (
                    <StyledTableRow>
                      <StyledTableCell colSpan={8} align="center">
                        No records found
                      </StyledTableCell>
                    </StyledTableRow>
                  ) : (
                    reportData?.map((report, index) => (
                      <StyledTableRow
                        key={index}
                        sx={{
                          // ...styles.TableRow,
                          backgroundColor: report.is_updated === 1 && "#daebf5",
                        }}
                      >
                        <StyledTableCell>
                          {report?.name || `Report ${index + 1}`}
                        </StyledTableCell>
                        <StyledTableCell>
                          {dayjs(report?.up).format("YYYY-MM-DD HH:mm:ss")}
                        </StyledTableCell>
                        <StyledTableCell>
                          {report?.image_path ? (
                            <Link
                              to={`${baseUrl}uploads/${report?.image_path}`}
                              target="_blank"
                              download
                            >
                              <Button variant="contained" color="primary">
                                View Image
                              </Button>
                            </Link>
                          ) : (
                            "No Image Uploaded"
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {checkStatus(report?.status)}
                        </StyledTableCell>
                        <StyledTableCell>{report?.comment}</StyledTableCell>
                        <StyledTableCell>
                          <Grid
                            container
                            spacing={1}
                            justifyContent="flex-start"
                            alignItems="start"
                            display={"flex"}
                            flexDirection={"column"}
                          >
                            <Grid item>
                              <Button
                                variant="contained"
                                color="success"
                                onClick={() =>
                                  handleOpenDialog(
                                    report?.prd_id,
                                    report?.status,
                                    report?.comment
                                  )
                                }
                                disabled={
                                  report?.image_path && report.status !== "1"
                                    ? false
                                    : true
                                }
                              >
                                Edit Status
                              </Button>
                            </Grid>
                          </Grid>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  )}
                  <StyledTableRow />
                </TableBody>
              </Table>
            </TableContainer>
            {/* </Box> */}
            <Box></Box>
          </>
        ) : (
          <>
            <Typography variant="h6" component="p">
              Data not available
            </Typography>
          </>
        )}
      </Container>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="document-status-dialog"
        fullWidth
      >
        <DialogTitle id="document-status-dialog">
          Change {PopupHeading} Status
        </DialogTitle>
        <DialogContent>
          <Grid sx={{ marginTop: 2 }}>
            <FormControl fullWidth size="small">
              <InputLabel>{PopupHeading} Status</InputLabel>
              <Select
                sx={{ marginTop: 2 }}
                labelId="document-status-label"
                name="documentStatus"
                placeholder="Select document status"
                value={formData.status}
                onChange={(e) =>
                  setFormData((pre) => ({
                    ...pre,
                    status: e.target.value,
                  }))
                }
              >
                <MenuItem value="1">
                  {formData.documentID !== 0 ? "Accept" : "Complete"}
                </MenuItem>
                <MenuItem value="2">Reject</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {formData.status === "2" && (
            <Textarea
              minRows={4}
              placeholder="Reason for rejection *"
              sx={{ mt: 2 }}
              onChange={(e) =>
                setFormData((pre) => ({
                  ...pre,
                  reason: e.target.value,
                }))
              }
              value={formData.reason || ""}
              required
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
          <Button onClick={handleCloseDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
