import { useState, } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function ResponsiveDialog(props) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePlease = () => {
    setOpen(false);
    props.onPress(true, props.id, props.index)
  };

  return (
    <>
      <Button onClick={handleClickOpen} disabled={props.disabled}>
        {props.head}
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
      >
        <DialogTitle id="responsive-dialog-title">
          Confirm {props.action}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.action && props.name ? ` Are you sure you want to ${props.action} ${props.name}` : "Are you sure"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            No, Thanks
          </Button>
          <Button onClick={handlePlease} autoFocus>
            Please
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}