import React from 'react';
import { Route } from 'react-router-dom';

import Cashless from "../component/Navbar/MyDetails-SubMenus/Employee-Center/cashless";
import MyHealth from "../component/Navbar/MyDetails-SubMenus/Employee-Center/myHealth";
import Affiliated from "../component/Navbar/MyDetails-SubMenus/Employee-Center/affiliatedService";
import HealthContent from "../component/Navbar/MyDetails-SubMenus/Employee-Center/healthContent";
import MisExamination from "../component/Navbar/MyDetails-SubMenus/Employee-Center/misExamination";
import PrivilegeHealthService from "../component/Navbar/MyDetails-SubMenus/Employee-Center/privilegeHealthService";

// Export routes directly as an array
const MyDetailsRoutes = [
    <Route path="my-details/employee-center/cashless" element={<Cashless />} />,
    <Route path="my-details/employee-center/my-health" element={<MyHealth />} />,
    <Route path="my-details/employee-center/affiliated-service" element={<Affiliated />} />,
    <Route path="my-details/employee-center/health-content" element={<HealthContent />} />,
    <Route path="my-details/employee-center/mis-Examination" element={<MisExamination />} />,
    <Route path="my-details/employee-center/privilege-Health-Service" element={<PrivilegeHealthService />} />,
];

export default MyDetailsRoutes;
