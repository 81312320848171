const EmailTemplate = ({ link }) => {
  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        maxWidth: "600px",
        margin: "0 auto",
        padding: "20px",
        border: "1px solid #ddd",
        borderRadius: "5px",
        backgroundColor: "#f9f9f9",
      }}
    >
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <h1>Pre-Employment Exam Link</h1>
      </div>
      <div style={{ marginBottom: "20px" }}>
        <p>Please click on the link below to complete the pre-employment exam:</p>
        <a
          href={link}
          style={{
            display: "inline-block",
            padding: "10px 20px",
            margin: "20px 0",
            fontSize: "16px",
            color: "#fff",
            backgroundColor: "#007bff",
            textDecoration: "none",
            borderRadius: "5px",
          }}
        >
          Go to Exam
        </a>
      </div>
      <div
        style={{
          textAlign: "center",
          fontSize: "12px",
          color: "#777",
        }}
      >
        <p>If you have any questions, please contact us.</p>
      </div>
    </div>
  );
};

export default EmailTemplate;  