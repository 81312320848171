import React from 'react';
import styles from '../../../../css/styles';
import { StyledTableCell, StyledTableRow } from '../../../../helper/employee/employeeHelpers';
import { CommanAppBar } from '../../../Comman/commanAppBar';
import sharedImports from '../../../Comman/sharedImports';
import EditOutlinedIcon from '@mui/icons-material/EditLocationTwoTone';
import Dropdown  from '../../../Comman/Dropdown';
import { SendGetRequest } from '../../../../api/config/ApiCall';
const { Box, Paper, Grid, Table, Button, DemoItem, TableRow, TableHead, DatePicker, TextField, Typography, FormControl, SearchIcon, CssBaseline, SearchOutlinedIcon, TableContainer, CancelOutlinedIcon, LocalizationProvider, AdapterDayjs, TableBody, } = sharedImports;

const reportListRows = [
    { examination: 'OPD', date: '15/11/2024', round: 'OPD', employeeCode: '30001800', employeeName: 'Viraj Vala', relation: 'SELF', status: 'In Progress' },
    { examination: 'OPD', date: '23/08/2024', round: 'OPD', employeeCode: '9', employeeName: 'Rushi', relation: 'Other', status: 'In Progress' },
    { examination: 'OPD', date: '02/08/2024', round: 'OPD', employeeCode: '9', employeeName: 'Pranay, EMP001', relation: 'Other', status: 'In Progress' },
];

export default function TestListingOpd(props) {
    const [state, setState] = React.useState({
        examinationType: '',
        round: '',
        department: '',
        doctor: '',
        participantType: '',
        testStatus: '',
        test: '',
        fromDate: null,
        toDate: null
    });

    const [dropdownOptions, setDropdownOptions] = React.useState({
        examinationTypes: [],
        rounds: [],
        employeeDepartment: [],
        doctors: [],
        participantTypes: [],
        testStatuses: [],
        tests: []
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState((prev) => ({ ...prev, [name]: value }));
    };

    // Function to fetch dropdown data dynamically
    const fetchDropdownData = async (inputType) => {
        try {
            const response = await SendGetRequest("getAllInputJobDetailData", { type: inputType });
            if (inputType === "employeeDepartment") {
                setDropdownOptions((prev) => ({
                    ...prev,
                    employeeDepartment: response.data.employeeDepartment,
                }));
            }
        } catch (error) {
            console.error(`Error fetching data for ${inputType}:`, error);
        }
    };
    

    React.useEffect(() => {
        // Simulate API call
        const fetchDropdownData = async () => {
            const fetchedData = {
                examinationTypes: [
                    { id: '1', name: 'Periodic' },
                    { id: '2', name: 'Laboratory' },
                    { id: '3', name: 'Medicine' },
                    { id: '4', name: 'SETRID-COLD' },
                ],
                rounds: [
                    { id: '1', name: 'Consumable' },
                    { id: '2', name: 'Laboratory' },
                    { id: '3', name: 'Medicine' },
                    { id: '4', name: 'SETRID-COLD' },
                ],
                employeeDepartment: [
                    { id: '1', name: 'Consumable' },
                    { id: '2', name: 'Laboratory' },
                    { id: '3', name: 'Medicine' },
                    { id: '4', name: 'SETRID-COLD' },
                ],
                doctors: [
                    { id: '1', name: 'Sukumar Das' },
                    { id: '2', name: 'Siddharth Darji' },
                    { id: '3', name: 'Pranij Vora' },
                    { id: '4', name: 'Vijay Dave' },
                    { id: '5', name: 'Jay Sheladia' },
                    { id: '6', name: 'Urmik Chauhan' },
                ],
                testStatuses: [
                    { id: '1', name: 'Active' },
                    { id: '2', name: 'Inactive' },
                ],
                tests: [
                    { "id": "1", "name": "ANGIOGRAPHY" },
                    { "id": "2", "name": "BLOOD GROUP" },
                    { "id": "3", "name": "Cardiac Profile" },
                    { "id": "4", "name": "CBC" },
                    { "id": "5", "name": "COLLAGEN PROFILE" },
                    { "id": "6", "name": "Dengue Test" },
                    { "id": "7", "name": "DIABETIC PROFILE" },
                    { "id": "8", "name": "ECG" },
                    { "id": "9", "name": "ECHO" },
                    { "id": "10", "name": "HEPATIC PROFILE" },
                    { "id": "11", "name": "HIV" },
                    { "id": "12", "name": "Hormonal Profile" },
                    { "id": "13", "name": "Iron Profile" },
                    { "id": "14", "name": "LIPID PROFILE" },
                    { "id": "15", "name": "Malaria Test" },
                    { "id": "16", "name": "MAMMOGRAPHY" },
                    { "id": "17", "name": "MANTOUX" },
                    { "id": "18", "name": "Other" },
                    { "id": "19", "name": "Pancreatic Profile" }
                ],
                participantTypes: [
                    { id: '1', name: 'Consumable' },
                    { id: '2', name: 'Laboratory' },
                    { id: '3', name: 'Medicine' },
                    { id: '4', name: 'SETRID-COLD' },
                ],
            };
            setDropdownOptions(fetchedData);
        };
    
        fetchDropdownData();
    }, []);    

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <CommanAppBar appBarTitle="Test Listing OPD" showButtons={false} />
                    <Box sx={{ mt: 8, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={1} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3} lg={3}>
                                    <Dropdown label="Examination Type" name="examinationType" value={state.examinationType} options={dropdownOptions.examinationTypes} handleChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>
                                    <Dropdown label="Round" name="round" value={state.round} options={dropdownOptions.rounds} handleChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>
                                    <Dropdown label="Department" name="department" value={state.department} options={dropdownOptions.employeeDepartment} handleChange={handleChange} onOpen={() => fetchDropdownData("employeeDepartment")} />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}></Grid>
                            </Grid>
                            <Grid container spacing={1} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3} lg={3}>
                                    <Dropdown label="Doctor" name="doctor" value={state.doctor} options={dropdownOptions.doctors} handleChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>
                                    <Dropdown label="Test Status" name="testStatus" value={state.testStatus} options={dropdownOptions.testStatuses} handleChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}>
                                    <Dropdown label="Test" name="test" value={state.test} options={dropdownOptions.tests} handleChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={3} lg={3}></Grid>
                            </Grid>
                            <Grid container spacing={1} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>From Date</Box>
                                    <Grid item xs={12} md={12}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>To Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>
                            </Grid>

                            <Grid container spacing={1} sx={{ mb: 3 }}>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Dropdown label="Participant Type" name="participantType" value={state.participantType} options={dropdownOptions.participantTypes} handleChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Employee Code</Box>
                                    </Typography>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                                placeholder='Employee Code'
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                            <SearchIcon />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Employee Name</Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Employee Name'
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                <Grid item>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<SearchOutlinedIcon />}>Search</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ fontWeight: 'bold' }}>
                                    Total Records:3
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' sx={{ mr: 2, textTransform: 'none' }} disabled>Prev</Button>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} disabled>Next</Button>
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Examination</StyledTableCell>
                                            <StyledTableCell>Date</StyledTableCell>
                                            <StyledTableCell>Round</StyledTableCell>
                                            <StyledTableCell>Employee Code</StyledTableCell>
                                            <StyledTableCell>Employee Name</StyledTableCell>
                                            <StyledTableCell>Relation</StyledTableCell>
                                            <StyledTableCell>Status</StyledTableCell>
                                            <StyledTableCell>Edit</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.examination} sx={styles.TableRow}>
                                                <StyledTableCell> {row.examination} </StyledTableCell>
                                                <StyledTableCell>{row.date}</StyledTableCell>
                                                <StyledTableCell>{row.round}</StyledTableCell>
                                                <StyledTableCell>{row.employeeCode}</StyledTableCell>
                                                <StyledTableCell>{row.employeeName}</StyledTableCell>
                                                <StyledTableCell>{row.relation}</StyledTableCell>
                                                <StyledTableCell>{row.status}</StyledTableCell>
                                                <StyledTableCell><EditOutlinedIcon /></StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}