import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { SendGetRequest, SendPostRequest, SendPutRequest } from '../../../../api/config/ApiCall';
import {
    Box,
    Grid,
    Link,
    TextField,
    AppBar,
    Button,
    Toolbar,
    Typography,
    CssBaseline,
    FormControl,
    Autocomplete,
    Chip,
} from '@mui/material';
import {
    SaveOutlined as SaveOutlinedIcon,
    FormatListBulletedOutlined as FormatListBulletedOutlinedIcon,
} from '@mui/icons-material';
import styles from 'css/styles';

const drawerWidth = 300;

export default function PackageForm(props) {
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        min_age: "",
        max_age: "",
        report_names: [],
    });

    const [testReportNames, setTestReportNames] = useState([]);

    const [isEditing, setIsEditing] = useState(false);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleReportsChange = (event, value) => {
        setFormData((prevData) => ({
            ...prevData,
            report_names: value.map(option => option.employee_test_reports_id),
        }));
    };

    const insertUpdateData = async (formData) => {
        try {
            let response;
            if (isEditing) {
                response = await SendPutRequest("listAllPackagesUrl", formData, props.packageId);
            } else {
                response = await SendPostRequest("listAllPackagesUrl", formData);
            }

            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                navigate('/dashboard/pre-joinee-management/packages');
            } else {                
                enqueueSnackbar(response.message, { variant: "warning" });
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        insertUpdateData(formData);
    };

    const fetchTestReportNames = async () => {
        try {
            const response = await SendGetRequest("employeeActiveTestReports", {});
            setTestReportNames(response.data.data);
        } catch (error) {
            console.error('Error fetching report names:', error);
        }
    };

    useEffect(() => {
        if (props.packageId && props.packageData) {
            setIsEditing(true);
            setFormData({ ...props.packageData });
            fetchTestReportNames();
        }
    }, [props.packageId, props.packageData]);

    return (
        <>
            <React.Fragment>
                <Box sx={{ mt: 8}}>
                    <CssBaseline />
                    <form onSubmit={handleSubmit}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099,
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            {isEditing ? 'Edit Package' : 'Create Package'}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            type='submit'
                                            variant='contained'
                                            startIcon={<SaveOutlinedIcon />}
                                            sx={{ m: 1, textTransform: 'none' }}
                                        >
                                            {isEditing ? 'Update' : 'Save'}
                                        </Button>
                                        <Button
                                            variant='contained'
                                            onClick={() => navigate(`/dashboard/pre-joinee-management/packages`)}
                                            startIcon={<FormatListBulletedOutlinedIcon />}
                                            sx={{ m: 1, textTransform: 'none' }}
                                        >
                                            List
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                        <Box sx={{ mt: 6, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ fontWeight: 'bold', mb: 1 }}>Package Name <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="name"
                                            placeholder="Enter package name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Box sx={{ fontWeight: 'bold', mb: 1 }}>Reports <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            multiple
                                            id="report_names"
                                            options={testReportNames}
                                            value={testReportNames.filter(option => formData.report_names.includes(option.employee_test_reports_id))}
                                            onChange={handleReportsChange}
                                            onOpen={fetchTestReportNames}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.name}
                                            getOptionSelected={(option, value) => option.employee_test_reports_id === value.employee_test_reports_id}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip key={option.employee_test_reports_id} label={option.name} {...getTagProps({ index })} />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Select Reports"
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ fontWeight: 'bold', mb: 1 }}>Minimum Age <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <FormControl fullWidth>
                                        <TextField
                                        name="min_age"
                                        placeholder="Enter minimum age"
                                        value={formData.min_age}
                                        onChange={handleInputChange}
                                        type="number"
                                        inputProps={{ min: 18 }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Box sx={{ fontWeight: 'bold', mb: 1 }}>Maximum Age <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <FormControl fullWidth>
                                        <TextField
                                        name="max_age"
                                        placeholder="Enter maximum age"
                                        value={formData.max_age}
                                        onChange={handleInputChange}
                                        type="number"
                                        inputProps={{ min: Number(formData.min_age) + 1 }}
                                        />
                                    </FormControl>
                                </Grid>
                                
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ fontWeight: 'bold', mb: 1 }}>Description</Box>
                                    <FormControl fullWidth>
                                        <TextField
                                            multiline
                                            rows={4}
                                            name="description"
                                            placeholder="Enter description"
                                            value={formData.description}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Box>
            </React.Fragment>
        </>
    );
}