import React from 'react';
import { Route } from 'react-router-dom';

// Employee Management Components
import EmployeeTransferIn from '../component/Navbar/HR-SubMenus/Employee-Management/employee-transfer-in/listing';
import CreateEmployeeTransferIn from '../component/Navbar/HR-SubMenus/Employee-Management/employee-transfer-in/create';
import UpdateEmployeeTransferIn from '../component/Navbar/HR-SubMenus/Employee-Management/employee-transfer-in/update';
import EmployeeTransferOut from '../component/Navbar/HR-SubMenus/Employee-Management/employee-transfer-out/listing';
import CreateEmployeeTransferOut from '../component/Navbar/HR-SubMenus/Employee-Management/employee-transfer-out/create';
import UpdateEmployeeTransferOut from '../component/Navbar/HR-SubMenus/Employee-Management/employee-transfer-out/update';
import FirstAidTrainingLog from '../component/Navbar/HR-SubMenus/Employee-Management/firstAidTrainingLog';
import SearchAllEmployee from '../component/Navbar/HR-SubMenus/Employee-Management/searchAllEmployee';
import EmployeeAllDetail from '../component/Navbar/HR-SubMenus/Employee-Management/employeeAllDetail';
import UpdateUser from '../component/Navbar/HR-SubMenus/Employee-Management/updateUser';
import EmployeeDataUpdate from '../component/Navbar/HR-SubMenus/Employee-Management/employeeDataUpdate';
import UploadEmployeePhoto from '../component/Navbar/HR-SubMenus/Employee-Management/uploadEmployeePhoto';
import LocationDepartmentGroup from '../component/Navbar/HR-SubMenus/Employee-Management/locationDepartmentGroup';
import LocationDepartmentHead from '../component/Navbar/HR-SubMenus/Employee-Management/locationDepartmentHead';
import EmployeeDocumentSearch from '../component/Navbar/HR-SubMenus/Employee-Management/employeeDocumentSearch';
import FirstAidDashboard from '../component/Navbar/HR-SubMenus/Employee-Management/firstAidDashboard';
import EmployeeSearch from '../component/Navbar/HR-SubMenus/Employee-Management/employeeSearch';

// Employee Master Setup Components
import EmployeeMasterSetup from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employeeMasterSetup';
import EmployeeDepartmentMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-department/employeeDepartmentMaster';
import EmployeeTypeGroupMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-type-group/employeeTypeGroupMaster';
import EmployeeTypeMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-type/employeeTypeMaster';
import EmployeeSubTypeMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-sub-type/employeeSubTypeMaster';
import EmployeeGradeMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-grade/employeeGradeMaster';
import EmployeeCompanyMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-company/employeeCompanyMaster';
import EmployeeContractorSubBusinessMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-contractor-sub-business/employeeContractorSubBusinessMaster';
import EmployeeTargetGroupMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-target-group/employeeTargetGroupMaster';
import EmployeeProcessTypeMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-process-type/employeeProcessTypeMaster';
import EmployeeTestReportsMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-test-reports/employeeTestReportsMaster';

// HR Reports
import AllEmployeeList from '../component/Navbar/HR-SubMenus/Report/allEmployeeList';
// import HRListing from '../component/Navbar/pre-joinee-managements/hrListing';
import PreEmploymentExam from '../component/Navbar/pre-joinee-managements/PreEmploymentExam';
import ImportOtherMaster from '../component/Navbar/HR-SubMenus/Other-Master/importOtherMaster';


//
import EmployeeSkillMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-skill/employeeSkillMaster';
import EmployeeGroupMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-group/employeeGroupMaster';
import EmployeeLegalEntityMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-legal-entity/employeeLegalEntityMaster';
import EmployeeQualificationMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-qualification/employeeQualificationMaster';
import EmployeeDesignatedPositionMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-designated-position/employeeDesignatedPositionMaster';
import EmployeeWorkLocationMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-work-location/employeeWorkLocationMaster';
import EmployeeJobTitleMaster from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-job-title/employeeJobTitleMaster';
import EmployeeMedicalCoverage from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/employee-medical-coverage/employeeMedicalCoverage';
import DesignationList from '../component/Navbar/HR-SubMenus/Employee-Management/master-setup/designation-list';
import AssignPermanentCode from '../component/Navbar/pre-joinee-managements/assignPermanentCode';
import ImportPreJoinee from '../component/Navbar/AdminTool-SubMenus/Utilities/importPreJoinee';

const HRRoutes = [
    // Employee Management Routes
    <Route key="employee-transfer-in" path="hr/employee-management/employee-transfer-in" element={<EmployeeTransferIn />} />,
    <Route key="create-employee-transfer-in" path="hr/employee-management/create-employee-transfer-in" element={<CreateEmployeeTransferIn />} />,
    <Route key="update-employee-transfer-in" path="hr/employee-management/update-employee-transfer-in/:id" element={<UpdateEmployeeTransferIn />} />,
    <Route key="employee-transfer-out" path="hr/employee-management/employee-transfer-out" element={<EmployeeTransferOut />} />,
    <Route key="create-employee-transfer-out" path="hr/employee-management/create-employee-transfer-out" element={<CreateEmployeeTransferOut />} />,
    <Route key="update-employee-transfer-out" path="hr/employee-management/update-employee-transfer-out/:id" element={<UpdateEmployeeTransferOut />} />,
    <Route key="first-aid-training-log" path="hr/employee-management/first-aid-training-log" element={<FirstAidTrainingLog />} />,
    <Route key="search-all-employee" path="hr/employee-management/search-all-employee" element={<SearchAllEmployee />} />,
    <Route key="employee-all-detail" path="hr/employee-management/employee-all-detail" element={<EmployeeAllDetail />} />,
    <Route key="update-user" path="hr/employee-management/search-all-employee/update/:id" element={<UpdateUser />} />,
    <Route key="employee-data-update" path="hr/employee-management/employee-data-update" element={<EmployeeDataUpdate />} />,
    <Route key="upload-employee-photo" path="hr/employee-management/upload-employee-photo" element={<UploadEmployeePhoto />} />,
    <Route key="location-department-group" path="hr/employee-management/location-department-group" element={<LocationDepartmentGroup />} />,
    <Route key="location-department-head" path="hr/employee-management/location-department-head" element={<LocationDepartmentHead />} />,
    <Route key="employee-document-search" path="hr/employee-management/employee-document-search" element={<EmployeeDocumentSearch />} />,
    <Route key="first-aid-dashboard" path="hr/employee-management/first-aid-dashboard" element={<FirstAidDashboard />} />,
    <Route key="employee-search" path="hr/employee-management/employee-search" element={<EmployeeSearch />} />,

    // Employee Master Setup Routes
    <Route key="employee-master-setup" path="hr/employee-management/employee-master-setup" element={<EmployeeMasterSetup />} />,
    <Route key="employee-department-master" path="hr/employee-management/employee-master-setup/employee-department-master" element={<EmployeeDepartmentMaster />} />,
    <Route key="employee-type-group-master" path="hr/employee-management/employee-master-setup/employee-type-group-master" element={<EmployeeTypeGroupMaster />} />,
    <Route key="employee-type-master" path="hr/employee-management/employee-master-setup/employee-type-master" element={<EmployeeTypeMaster />} />,
    <Route key="employee-sub-type-master" path="hr/employee-management/employee-master-setup/employee-sub-type-master" element={<EmployeeSubTypeMaster />} />,
    <Route key="employee-grade-master" path="hr/employee-management/employee-master-setup/employee-grade-master" element={<EmployeeGradeMaster />} />,
    <Route key="employee-company-master" path="hr/employee-management/employee-master-setup/employee-company-master" element={<EmployeeCompanyMaster />} />,
    <Route key="employee-contractor-sub-business-master" path="hr/employee-management/employee-master-setup/employee-contractor-sub-business-master" element={<EmployeeContractorSubBusinessMaster />} />,
    <Route key="employee-target-group-master" path="hr/employee-management/employee-master-setup/employee-target-group-master" element={<EmployeeTargetGroupMaster />} />,
    <Route key="employee-process-type-master" path="hr/employee-management/employee-master-setup/employee-process-type-master" element={<EmployeeProcessTypeMaster />} />,
    <Route key="employee-test-reports-master" path="hr/employee-management/employee-master-setup/test-reports-list" element={<EmployeeTestReportsMaster />} />,

    // HR Reports Routes
    <Route key="all-employee-list" path="hr/report/all-employee-list" element={<AllEmployeeList />} />,
    // <Route key="hr-listing" path="hr/pre-joinee-management/hr-listing" element={<HRListing />} />,
    <Route key="pre-employment-exam" path="hr/pre-joinee-management/pre-employment-exam" element={<PreEmploymentExam />} />,
    <Route key="import-other-master" path="hr/other-master/import-other-master" element={<ImportOtherMaster />} />,


      <Route path="hr/employee-management/employee-master-setup/employee-skill-master" element={<EmployeeSkillMaster />} />,
      <Route path="hr/employee-management/employee-master-setup/employee-group-master" element={<EmployeeGroupMaster />} />,
      <Route path="hr/employee-management/employee-master-setup/employee-legal-entity-master" element={<EmployeeLegalEntityMaster />} />,
      <Route path="hr/employee-management/employee-master-setup/employee-qualification-master" element={<EmployeeQualificationMaster />} />,
      <Route path="hr/employee-management/employee-master-setup/employee-designated-position" element={<EmployeeDesignatedPositionMaster />} />,
      <Route path="hr/employee-management/employee-master-setup/employee-work-location" element={<EmployeeWorkLocationMaster />} />,
      <Route path="hr/employee-management/employee-master-setup/employee-job-title" element={<EmployeeJobTitleMaster />} />,
      <Route path="hr/employee-management/employee-master-setup/employee-medical-coverage" element={<EmployeeMedicalCoverage />} />,
      <Route path="hr/employee-management/employee-master-setup/designation-list" element={<DesignationList />} />,
      <Route path="hr/pre-joinee-management/assign-permanent-code" element={<AssignPermanentCode />} />,
      <Route path="hr/pre-joinee-management/import-pre-joinee" element={<ImportPreJoinee />} />
    
];

export default HRRoutes;
