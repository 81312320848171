import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import styles from 'css/styles';
import { SendGetRequest, SendPostRequest, SendPutRequest } from '../../../../api/config/ApiCall';
import LocationSearch from '../../../Comman/SearchLocation/LocationSearch';
import {
    Box,
    Grid,
    TextField,
    AppBar,
    Button,
    Toolbar,
    Typography,
    CssBaseline,
    FormControl,
    Autocomplete,
    Chip,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import {
    SaveOutlined as SaveOutlinedIcon,
    FormatListBulletedOutlined as FormatListBulletedOutlinedIcon,
} from '@mui/icons-material';

const drawerWidth = 300;

const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export default function LabForm(props) {
    const [formData, setFormData] = useState({
        name: "",
        location: "",
        mobileNumber: "",
        description: "",
        latitude: "",
        longitude: "",
        email: "",
        report_names: [],
        timing: {
            monday: { openingTime: '', lunchBreakStart: '', lunchBreakEnd: '', closingTime: '',holiday:false },
            tuesday: { openingTime: '', lunchBreakStart: '', lunchBreakEnd: '', closingTime: '',holiday:false },
            wednesday: { openingTime: '', lunchBreakStart: '', lunchBreakEnd: '', closingTime: '',holiday:false },
            thursday: { openingTime: '', lunchBreakStart: '', lunchBreakEnd: '', closingTime: '',holiday:false },
            friday: { openingTime: '', lunchBreakStart: '', lunchBreakEnd: '', closingTime: '',holiday:false },
            saturday: { openingTime: '', lunchBreakStart: '', lunchBreakEnd: '', closingTime: '',holiday:false },
            sunday: { openingTime: '', lunchBreakStart: '', lunchBreakEnd: '', closingTime: '',holiday:false },
        }
     });

    const [testReportNames, setTestReportNames] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const [day, field] = name.split('.');
        
        if (field) {
            setFormData((prevData) => ({
                ...prevData,
                timing: {
                    ...prevData.timing,
                    [day]: {
                        ...prevData.timing[day],
                        [field]: type === 'checkbox' ? checked : value,
                    }
                }
            }));
        }
         else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const insertUpdateData = async (formData) => {
        try {
            let response;
            if (isEditing) {
                response = await SendPutRequest("listAllLabsUrl", formData, props.labId);
            } else {
                const newData = { ...formData };
                response = await SendPostRequest("listAllLabsUrl", newData);
            }

            console.log("===response", response);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                navigate('/dashboard/pre-joinee-management/labs');
            } else {
                if (response.response && response.response.data && response.response.data.message) {
                    enqueueSnackbar(response.response.data.message, { variant: 'warning' });
                } else {
                    enqueueSnackbar(response.message, { variant: "warning" });
                }
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        insertUpdateData(formData);
    };

    const handleLocationChange = React.useCallback(({ address, lat, lng }) => {
        setFormData((prevData) => ({
            ...prevData,
            location: address,
            latitude: lat,
            longitude: lng,
        }));
    }, []);

    const handleReportsChange = (event, value) => {
        setFormData((prevData) => ({
            ...prevData,
            report_names: value?.map(option => option.employee_test_reports_id),
        }));
    };

    const fetchTestReportNames = async () => {
        try {
            const response = await SendGetRequest("employeeActiveTestReports", {});
            setTestReportNames(response.data.data);
        } catch (error) {
            console.error('Error fetching report names:', error);
        }
    };

    
    useEffect(() => {
        if (props.labId && props.labData) {
            setIsEditing(true);
            setFormData({ ...props.labData });
            fetchTestReportNames();
        }
    }, [props.labId, props.labData]);    

    return (
        <>
            <React.Fragment>
                <Box sx={{ mt: 8, mb: 8 }}>
                    <CssBaseline />
                    <form onSubmit={handleSubmit}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099,
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            {isEditing ? 'Edit Lab' : 'Create Lab'}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant='contained'
                                            startIcon={<SaveOutlinedIcon />}
                                            sx={{ m: 1, textTransform: 'none' }}
                                            type="submit"
                                        >
                                            {isEditing ? 'Update' : 'Save'}
                                        </Button>
                                        <Button
                                            variant='contained'
                                            startIcon={<FormatListBulletedOutlinedIcon />}
                                            onClick={() => navigate(`/dashboard/pre-joinee-management/labs`)}
                                            sx={{ m: 1, textTransform: 'none' }}
                                        >
                                            List
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                        <Box sx={{ mt: 6, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ fontWeight: 'bold' }}>Lab Name <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="name"
                                            placeholder="Enter lab name"
                                            value={formData?.name}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Box sx={{ fontWeight: 'bold' }}>Mobile Number <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="mobileNumber"
                                            placeholder="Enter mobile number"
                                            value={formData?.mobileNumber}
                                            onChange={handleInputChange}
                                            type="number"
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Box sx={{ fontWeight: 'bold' }}>Email <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="email"
                                            placeholder="Enter email"
                                            value={formData?.email}
                                            onChange={handleInputChange}
                                            type="email"
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Box sx={{ fontWeight: 'bold'}}>Reports <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            multiple
                                            id="report_names"
                                            options={testReportNames}
                                            value={testReportNames?.filter(option => formData?.report_names?.includes(option.employee_test_reports_id))}
                                            onChange={handleReportsChange}
                                            onOpen={fetchTestReportNames}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.name}
                                            getOptionSelected={(option, value) => option.employee_test_reports_id === value.employee_test_reports_id}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip key={option.employee_test_reports_id} label={option.name} {...getTagProps({ index })} />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Select Reports"
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <LocationSearch onLocationChange={handleLocationChange} />
                                </Grid>

                                <Grid item xs={12}>
                                    <Box sx={{ fontWeight: 'bold' }}>Description</Box>
                                    <FormControl fullWidth>
                                        <TextField
                                            multiline
                                            rows={4}
                                            name="description"
                                            placeholder="Enter description"
                                            value={formData?.description}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <Box sx={{ fontWeight: 'bold', mb: 0 }}>Holidays and Timings</Box>
                                </Grid>
                                <Grid container spacing={2} sx={{ pl: 2, pt: 2 }}>
                                    <Grid item xs={12} md={2}>
                                        <Box sx={{ fontWeight: 'bold' }}>Day</Box>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Box sx={{ fontWeight: 'bold' }}>Opening Time</Box>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Box sx={{ fontWeight: 'bold' }}>Lunch Break Start</Box>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Box sx={{ fontWeight: 'bold' }}>Lunch Break End</Box>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Box sx={{ fontWeight: 'bold' }}>Closing Time</Box>
                                    </Grid>
                                </Grid>
                                {daysOfWeek.map((day) => (
                                    <Grid container spacing={2} key={day} sx={{ pl: 2 }}>
                                        <Grid item xs={12} md={2}>
                                            <FormControl fullWidth>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={formData.timing[day]?.holiday}
                                                            onChange={handleInputChange}
                                                            name={`${day}.holiday`}
                                                        />
                                                    }
                                                    label={`${day.charAt(0).toUpperCase() + day.slice(1)}`}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name={`${day}.openingTime`}
                                                    placeholder="Opening Time"
                                                    value={formData.timing[day]?.openingTime || ''}
                                                    onChange={handleInputChange}
                                                    type="time"
                                                    disabled={!formData.timing[day]?.holiday}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name={`${day}.lunchBreakStart`}
                                                    placeholder="Lunch Break Start"
                                                    value={formData.timing[day]?.lunchBreakStart || ''}
                                                    onChange={handleInputChange}
                                                    type="time"
                                                    disabled={!formData.timing[day]?.holiday}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name={`${day}.lunchBreakEnd`}
                                                    placeholder="Lunch Break End"
                                                    value={formData.timing[day]?.lunchBreakEnd || ''}
                                                    onChange={handleInputChange}
                                                    type="time"
                                                    disabled={!formData.timing[day]?.holiday}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    name={`${day}.closingTime`}
                                                    placeholder="Closing Time"
                                                    value={formData.timing[day]?.closingTime || ''}
                                                    onChange={handleInputChange}
                                                    type="time"
                                                    disabled={!formData.timing[day]?.holiday}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </form>
                </Box>
            </React.Fragment>
        </>
    );
}