import React from 'react';
import { Route } from 'react-router-dom';

// Clinical Components
// Doctor Components
import ImportDoctor from '../component/Navbar/Clinical-SubMenus/Doctor/importDoctor';
import DoctorMasterSetup from '../component/Navbar/Clinical-SubMenus/Doctor/doctorMasterSetup';

// Medical Review Components
import DocumentSearch from '../component/Navbar/Clinical-SubMenus/MedicalReview/documentSearch';
import MedicalHistory from '../component/Navbar/Clinical-SubMenus/MedicalReview/medicalHistory';
import MedicalReview from '../component/Navbar/Clinical-SubMenus/MedicalReview/medicalReview';

// Examination Report Components
import ClinicalExaminationReport from "../component/Navbar/Examination-SubMenus/Examination-Report/clinicalExaminationReport";
import ExportClinicalReport from "../component/Navbar/Examination-SubMenus/Examination-Report/exportClinicalReport";
import DoctorActivityTypeMaster from 'component/Navbar/Clinical-SubMenus/Doctor/DoctorActivityTypeMaster';
import DoctorMaster from 'component/Navbar/Clinical-SubMenus/Doctor/DoctorMaster';
import DoctorScheduleReport from 'component/Navbar/Clinical-SubMenus/Doctor/DoctorScheduleReport';
import DoctorServiceFav from 'component/Navbar/Clinical-SubMenus/Doctor/DoctorServiceFav';

// Export routes directly as an array
const ClinicalRoutes = [
    // Doctor => Sub-Menus
    <Route key="import-doctor" path="clinical/doctor/import-doctor" element={<ImportDoctor />} />,
    <Route key="doctor-master-setup" path="clinical/doctor/doctor-master-setup" element={<DoctorMasterSetup />} />,
    <Route key="doctor-activity-type-master" path="clinical/doctor/doctor-master-setup/doctor-activity-type-master" element={<DoctorActivityTypeMaster />} />,
    <Route key="doctor-master" path="clinical/doctor/doctor-master-setup/doctor-master" element={<DoctorMaster />} />,
    <Route key="doctor-schedule-report" path="clinical/doctor/doctor-master-setup/doctor-schedule-report" element={<DoctorScheduleReport />} />,
    <Route key="doctor-service-fav" path="clinical/doctor/doctor-master-setup/doctor-service-fav" element={<DoctorServiceFav />} />,
    
    // Medical Review => Sub-Menus
    <Route key="document-search" path="clinical/medical-review/document-search" element={<DocumentSearch />} />,
    <Route key="medical-history" path="clinical/medical-review/medical-history" element={<MedicalHistory />} />,
    <Route key="medical-review" path="clinical/medical-review" element={<MedicalReview />} />,

    // Examination Report Routes
    <Route key="clinical-examination-report" path="clinical/examination/clinical-examination-report" element={<ClinicalExaminationReport />} />,
    <Route key="export-clinical-report" path="clinical/examination/export-clinical-report" element={<ExportClinicalReport />} />
];

export default ClinicalRoutes;
