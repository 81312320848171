import React, { useState } from "react";
import {
    Box,
    Button,
    Grid,
    Typography,
    AppBar,
    Toolbar,
    FormControl,
    Select,
    MenuItem,
    TextField,
    Paper,
} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SendGetRequest } from 'api/config/ApiCall';
import { enqueueSnackbar } from "notistack";
import styles from "css/styles";
import dayjs from 'dayjs';
import * as Papa from 'papaparse';
import { saveAs } from 'file-saver';
const drawerWidth = 300;

const DoctorScheduleReport = () => {
    const [reportType, setReportType] = useState('0');
    const [viewMode, setViewMode] = useState('PDF');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectedDoctor, setSelectedDoctor] = useState('0');
    const [loading, setLoading] = useState(false);
    const [drListing, setDrListing] = useState([]);
    const [reportListing,setReportListing] = useState([]);
    const fetchDrListing = async () => {
        try {
            const response = await SendGetRequest("doctorList", {});
            if (response.status) {
                const { drUsers } = response?.data;
                setDrListing(drUsers);
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        } catch (error) {
            console.error("Error fetching doctor listing:", error);
            enqueueSnackbar("Error fetching doctor listing", { variant: "error" });
        }
    };
    const fetchReportListing = async () => {
        try{
            const response = await SendGetRequest("employeeTestReportsList", {});
            if (response.status) {
                const { data } = response?.data;
                setReportListing(data);
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        } catch (err) {
            enqueueSnackbar("Somthing went wrong", { variant: "error" });
        }
    }
    React.useEffect(() => {
        fetchDrListing();
        fetchReportListing();
    }, []);
    const handleViewReport = async () => {
        if (!reportType || !fromDate || !toDate) {
            enqueueSnackbar("Please fill all required fields", { variant: "error" });
            return;
        }

        try {
            setLoading(true);
            const params = {
                reportType,
                viewMode,
                fromDate: dayjs(fromDate).format('YYYY-MM-DD'),
                toDate: dayjs(toDate).format('YYYY-MM-DD'),
                doctor: selectedDoctor
            };
            // now we download dummy report not through api

            const dummyReport = [
                {
                    "name": "Dr. John Doe",
                    "date": "2023-08-30",
                    "time": "10:00 AM"
                },
                {
                    "name": "Dr. Jane Smith",
                    "date": "2023-08-30",
                    "time": "11:00 AM"
                }
            ];
            if (viewMode === "PDF") {
                const blob = new Blob([JSON.stringify(dummyReport)], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'report.pdf';
                link.click();
            }
            else {
                const csv = Papa.unparse(dummyReport);
                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                saveAs(blob, 'report.csv');
            }
            return
            const response = await SendGetRequest("doctorScheduleReport", params);
            if (response.status) {
                // Handle report response based on viewMode
                if (viewMode === 'PDF') {
                    // Open PDF in new window
                    window.open(response.data.url, '_blank');
                } else {
                    // Handle Excel download
                    const link = document.createElement('a');
                    link.href = response.data.url;
                    link.download = `DoctorScheduleReport_${new Date().getTime()}.xlsx`;
                    link.click();
                }
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        } catch (error) {
            console.error("Error generating report:", error);
            enqueueSnackbar("Error generating report", { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    const handleClear = () => {
        setReportType('');
        setViewMode('PDF');
        setFromDate(null);
        setToDate(null);
        setSelectedDoctor('ALL');
    };

    return (
        <Box>
            <AppBar
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    mt: { xs: '53px', sm: '64px' },
                    zIndex: 1099
                }}
                elevation={1}
            >
                <Toolbar sx={[styles.h, styles.bgWhite]}>
                    <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                        Doctor Schedule Report
                    </Typography>
                </Toolbar>
            </AppBar>

            <Box sx={{ mt: 8, p: 3 }}>
                <Paper sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                        {/* Report Type */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth required>
                                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                    Report *
                                </Typography>
                                <Select
                                    value={reportType}
                                    onChange={(e) => setReportType(e.target.value)}
                                    size="small"
                                >
                                    <MenuItem value="0">Select Report Type</MenuItem>
                                    {reportListing?.map((report) => (
                                        <MenuItem key={report?.employee_test_reports_id} value={report?.employee_test_reports_id}>
                                            {report?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* View Mode */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                    View Mode
                                </Typography>
                                <Select
                                    value={viewMode}
                                    onChange={(e) => setViewMode(e.target.value)}
                                    size="small"
                                >
                                    <MenuItem value="PDF">PDF</MenuItem>
                                    <MenuItem value="EXCEL">Excel</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* From Date */}
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                From Date
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={fromDate}
                                    onChange={(newValue) => setFromDate(newValue)}
                                    slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                />
                            </LocalizationProvider>
                        </Grid>

                        {/* To Date */}
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                To Date
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={toDate}
                                    onChange={(newValue) => setToDate(newValue)}
                                    slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                />
                            </LocalizationProvider>
                        </Grid>

                        {/* Doctor Selection */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                    Doctor
                                </Typography>
                                <Select
                                    value={selectedDoctor}
                                    onChange={(e) => setSelectedDoctor(e.target.value)}
                                    size="small"
                                >
                                    <MenuItem value="0">ALL</MenuItem>
                                    {drListing?.map((doctor) => (
                                        <MenuItem key={doctor?.id} value={doctor?.id}>
                                            {doctor?.full_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Buttons */}
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                                <Button
                                    variant="contained"
                                    onClick={handleViewReport}
                                    disabled={loading || !reportType || !fromDate || !toDate}
                                >
                                    View Report
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={handleClear}
                                >
                                    Clear
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Box>
    );
};

export default DoctorScheduleReport;
