import React from "react";
import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import { CommanAppBar } from "component/Comman/commanAppBar";
import styles from "css/styles";

const DoctorMasterSetup = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const cards = [
    {
      title: "Doctor Activity Type Master",
      path: "doctor-activity-type-master",
    },
    {
      title: "Doctor Master",
      path: "doctor-master",
    },
  ];

  return (
    <>
      <Box sx={{ bgcolor: "#fff" }}>
        <CommanAppBar appBarTitle={"Doctor Master Setup"} />
        <Box
          sx={{ mt: 8, mb: 2, mx: 2, bgcolor: "white", p: 3, }}
        >
          <Grid container spacing={3}>
            {cards.map((card, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card
                  sx={{
                    cursor: "pointer",
                    transition: "transform 0.2s",
                    "&:hover": {
                      transform: "translateY(-4px)",
                      boxShadow: 3,
                    },
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => navigate(`${location.pathname}/${card.path}`)}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      p: 2,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 500,
                        color: "primary.main",
                        flex: 1,
                      }}
                    >
                      {card.title}
                    </Typography>
                    <LaunchIcon
                      sx={{
                        ml: 2,
                        color: "primary.main",
                        fontSize: "1.2rem",
                      }}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default DoctorMasterSetup;
