import React from 'react';
import { Route } from 'react-router-dom';

// Pre-Joinee Components
import LabsListing from '../component/Navbar/pre-joinee-managements/lab/labsListing';
import LabCreate from '../component/Navbar/pre-joinee-managements/lab/LabCreate';
import LabUpdate from '../component/Navbar/pre-joinee-managements/lab/LabUpdate';
import PackagesListing from '../component/Navbar/pre-joinee-managements/package/PackagesListing';
import PackageCreate from '../component/Navbar/pre-joinee-managements/package/PackageCreate';
import PackageUpdate from '../component/Navbar/pre-joinee-managements/package/PackageUpdate';
// import HRListing from '../component/Navbar/pre-joinee-managements/hrListing';
import PreEmploymentExam from '../component/Navbar/pre-joinee-managements/PreEmploymentExam';
import PreEmploymentExamView from '../component/Navbar/pre-joinee-managements/PreEmploymentExamView';
import AssignPermanentCode from '../component/Navbar/pre-joinee-managements/assignPermanentCode';
import ImportPreJoinee from '../component/Navbar/AdminTool-SubMenus/Utilities/importPreJoinee';

// Export routes directly as an array
const PreJoineeRoutes = [
    // Lab Management Routes
    <Route key="labs-listing" path="pre-joinee-management/labs" element={<LabsListing />} />,
    <Route key="lab-create" path="pre-joinee-management/labs/create" element={<LabCreate />} />,
    <Route key="lab-update" path="pre-joinee-management/labs/update/:id" element={<LabUpdate />} />,

    // Package Management Routes
    <Route key="packages-listing" path="pre-joinee-management/packages" element={<PackagesListing />} />,
    <Route key="package-create" path="pre-joinee-management/packages/create" element={<PackageCreate />} />,
    <Route key="package-update" path="pre-joinee-management/packages/update/:id" element={<PackageUpdate />} />,

    // HR and Pre-Employment Routes
    // <Route key="hr-listing" path="hr/pre-joinee-management/hr-listing" element={<HRListing />} />,
    <Route key="pre-employment-exam" path="hr/pre-joinee-management/pre-employment-exam" element={<PreEmploymentExam />} />,
    <Route key="pre-employment-exam-view" path="hr/pre-joinee-management/pre-employment-exam-view/:id" element={<PreEmploymentExamView />} />,
    <Route key="assign-permanent-code" path="hr/pre-joinee-management/assign-permanent-code" element={<AssignPermanentCode />} />,
    <Route key="import-pre-joinee" path="hr/pre-joinee-management/import-pre-joinee" element={<ImportPreJoinee />} />
];

export default PreJoineeRoutes;
