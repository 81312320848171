import React from 'react';
import { Route } from 'react-router-dom';

import OPD from "../component/Navbar/OPD-SubMenus/opd";
import OpdList from "../component/Navbar/OPD-SubMenus/opdList";
import OPDDashboard from "../component/Navbar/OPD-SubMenus/opdDashboard";
import ConsolidatedCost from "../component/Navbar/OPD-SubMenus/consolidatedCost";
import CaseReport from "../component/Navbar/OPD-SubMenus/caseReport";
import CaseSummaryReport from "../component/Navbar/OPD-SubMenus/caseSummaryReport";
import RaferralList from "../component/Navbar/OPD-SubMenus/raferralList";

// Export routes directly as an array
const OPDRoutes = [
    <Route path="opd" element={<OPD />} />,
    <Route path="opd/opd-list" element={<OpdList />} />,
    <Route path="opd/opd-dashboard" element={<OPDDashboard />} />,
    <Route path="opd/consolidated-cost" element={<ConsolidatedCost />} />,
    <Route path="opd/case-report" element={<CaseReport />} />,
    <Route path="opd/case-summary-report" element={<CaseSummaryReport />} />,
    <Route path="opd/referral-list" element={<RaferralList />} />,
];

export default OPDRoutes;
