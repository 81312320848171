import React from 'react';
import { Route } from 'react-router-dom';

import HrLanguage from 'component/Navbar/Change-language/hrLanguage';
import DiagnosticLanguage from 'component/Navbar/Change-language/diagnosticLanguage';
import ClinicalLanguage from 'component/Navbar/Change-language/clinicalLanguage';
import SafetyLanguage from 'component/Navbar/Change-language/safetyLanguage';

// Export routes directly as an array
const ChangeLngRoutes = [
    <Route path="change-language/diagnostic" element={<DiagnosticLanguage />} />,
    <Route path="change-language/clinical" element={<ClinicalLanguage />} />,
    <Route path="change-language/safety" element={<SafetyLanguage />} />,
    <Route path="change-language/hr" element={<HrLanguage />} />,
];

export default ChangeLngRoutes;
