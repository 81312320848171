import React from 'react';
import { Route } from 'react-router-dom';


import UserPermission  from "../component/Navbar/UserRole-SubMenus/UserPermission/userPermission"
import UserRole from '../component/Navbar/UserRole-SubMenus/UserPermission/userRole';


import ChangeLocation from '../component/Navbar/AdminTool-SubMenus/Security/changeLocation';
import ChangePassword from '../component/Navbar/AdminTool-SubMenus/Security/changePassword';
import MyDashboard from '../component/Navbar/AdminTool-SubMenus/Security/myDashboard';
import UserPreference from '../component/Navbar/AdminTool-SubMenus/Security/userPreference';
import ViewReport from '../component/Navbar/AdminTool-SubMenus/Security/viewReport';
import AdminPanelSettings from 'component/Navbar/AdminTool-SubMenus/Security/adminPanelSettings';
import ImportAsset from '../component/Navbar/AdminTool-SubMenus/Utilities/importAsset';
import ImportContractEmployee from '../component/Navbar/AdminTool-SubMenus/Utilities/importContractEmployee';
import ImportEmployee from '../component/Navbar/AdminTool-SubMenus/Utilities/importEmployee';
import ImportDependent from '../component/Navbar/AdminTool-SubMenus/Utilities/importDependent';
import ImportStockItem from '../component/Navbar/AdminTool-SubMenus/Utilities/importStockItem';
import ImportExaminationAll from '../component/Navbar/AdminTool-SubMenus/Utilities/importExaminationAll';
import ImportLocation from '../component/Navbar/AdminTool-SubMenus/Utilities/importLocation';
import ImportUsers from '../component/Navbar/AdminTool-SubMenus/Utilities/importUsers';
import ImportDocument from '../component/Navbar/AdminTool-SubMenus/Utilities/importDocument';
import UpdateEmployeeData from '../component/Navbar/AdminTool-SubMenus/Utilities/updateEmployeeData';
import UpdateStockItemData from '../component/Navbar/AdminTool-SubMenus/Utilities/updateStockItemData';

// Export routes directly as an array
const AdminRoutes = [
    <Route path="admin-tool/security/user-permission" element={<UserPermission />} />,
    <Route path="admin-tool/security/user-role" element={<UserRole />} />,
    <Route path="admin-tool/security/change-location" element={<ChangeLocation />} />,
    <Route path="admin-tool/security/change-password" element={<ChangePassword />} />,
    <Route path="admin-tool/security/my-dashboard" element={<MyDashboard />} />,
    <Route path="admin-tool/security/user-preference" element={<UserPreference />} />,
    <Route path="admin-tool/security/view-report" element={<ViewReport />} />,
    <Route path="admin-tool/security/admin-setting" element={<AdminPanelSettings />} />,
    <Route path="admin-tool/utilities/import-asset" element={<ImportAsset />} />,
    <Route path="admin-tool/utilities/import-contract-employee" element={<ImportContractEmployee />} />,
    <Route path="admin-tool/utilities/import-employee" element={<ImportEmployee />} />,
    <Route path="admin-tool/utilities/import-dependent" element={<ImportDependent />} />,
    <Route path="admin-tool/utilities/import-stock-item" element={<ImportStockItem />} />,
    <Route path="admin-tool/utilities/import-examination-all" element={<ImportExaminationAll />} />,
    <Route path="admin-tool/utilities/import-location" element={<ImportLocation />} />,
    <Route path="admin-tool/utilities/import-users" element={<ImportUsers />} />,
    <Route path="admin-tool/utilities/import-document" element={<ImportDocument />} />,
    <Route path="admin-tool/utilities/update-employee-data" element={<UpdateEmployeeData />} />,
    <Route path="admin-tool/utilities/update-stock-item-data" element={<UpdateStockItemData />} />
];

export default AdminRoutes;
