import PackageForm from "./Form"

const PackageCreate = () => {
    return (
    <>
      <PackageForm />
    </>  
  )
}

export default PackageCreate
