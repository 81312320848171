import React from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { SendGetRequest } from 'api/config/ApiCall';
import { styled } from '@mui/material/styles';
import { Box, AppBar, Container, CardContent, Toolbar, Typography, Grid, Card, Chip, CardMedia, IconButton } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PhoneIcon from '@mui/icons-material/Phone';
import CheckIcon from '@mui/icons-material/Check';
const drawerWidth = 300;
const StyledCard = styled(Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.spacing(2),
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    '&:hover': {
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
        transform: 'translateY(-2px)',
        transition: 'all 0.3s ease'
    }
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: theme.spacing(1),
    fontWeight: 'bold',
    '&.approved': {
        backgroundColor: '#4caf50',
        color: 'white'
    },
    '&.pending': {
        backgroundColor: '#ff9800',
        color: 'white'
    },
    '&.rejected': {
        backgroundColor: '#f44336',
        color: 'white'
    }
}));

const DetailRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    margin: theme.spacing(1, 0),
    '& .label': {
        fontWeight: 'bold',
        width: '150px',
        color: theme.palette.text.secondary
    },
    '& .value': {
        flex: 1,
        color: theme.palette.text.primary
    }
}));

const StyledLocationButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },
    marginLeft: theme.spacing(1),
}));

const PreEmploymentExamView = (props) => {
    const { id } = useParams();
    const [rows, setRows] = React.useState([]);

    const fetchHRListing = React.useCallback(async () => {
        try {
            const response = await SendGetRequest("prejoineeDetial", { id });
            if (response.status) {
                setRows(response.data);
            }
        } catch (err) {
            console.log(err);
        }
    }, [id]);

    React.useEffect(() => {
        fetchHRListing();
    }, [fetchHRListing]);

    const getStatusChip = (status) => (
        <StyledChip
            label={status === '1' ? 'Approved' : status === '2' ? 'Rejected' : 'Pending'}
            className={status === '1' ? 'approved' : status === '2' ? 'rejected' : 'pending'}
        />
    );

    const handleGetDirections = (latitude, longitude) => {
        if (latitude && longitude) {
            window.open(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`, '_blank');
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
         <AppBar
                        sx={{
                            width: { sm: `calc(100% - ${drawerWidth}px)` },
                            ml: { sm: `${drawerWidth}px` },
                            mt: { xs: '53px', sm: '64px' },
                            zIndex: 1099,
                            backgroundColor: 'white',
                            boxShadow:'2'
                        }}
                        elevation={1}
                    >
                <Toolbar>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <Typography variant="h6" component="div" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                                Pre-Employment Medical Examination Details
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
                <Grid container spacing={3}>
                  {/* Progress Steps */}
                  <Grid item xs={12}>
                      <StyledCard>
                          <CardContent>
                              <div className="stepper-wrapper">
                                  <div className={`step-wrapper ${rows?.isSendLink ? 'completed' : 'active'}`}>
                                      <div className="step-number">
                                          {rows?.isSendLink ? <CheckIcon className="step-icon" /> : 1}
                                      </div>
                                      <div className="step-description">Link Sent</div>
                                  </div>
                                  
                                  <div className={`step-wrapper ${ rows?.isSendLink && rows?.appointmentStatus === '1' ? 'completed' :  rows?.appointmentStatus!==null || rows?.isSendLink ? 'active':''}`}>
                                      <div className="step-number">
                                          {rows?.appointmentStatus === '1' ? <CheckIcon className="step-icon" /> : 2}
                                      </div>
                                      <div className="step-description">Lab Approval</div>
                                  </div>
                                  
                                  <div className={`step-wrapper ${rows?.reportStatus === '1' ? 'completed' : 
                                      rows?.appointmentStatus === '1' && rows?.reportStatus !== '2' ? 'active' : ''}`}>
                                      <div className="step-number">
                                          {rows?.reportStatus === '1' ? <CheckIcon className="step-icon" /> : 3}
                                      </div>
                                      <div className="step-description">Reports Done</div>
                                  </div>
                                  
                                  <div className={`step-wrapper ${rows?.fitnessStatus === '1' ? 'completed' : 
                                      rows?.reportStatus === '1' && rows?.fitnessStatus !== '2' ? 'active' : ''}`}>
                                      <div className="step-number">
                                          {rows?.fitnessStatus === '1' ? <CheckIcon className="step-icon" /> : 4}
                                      </div>
                                      <div className="step-description">Fitness Approved</div>
                                  </div>
                              </div>
                          </CardContent>
                      </StyledCard>
                  </Grid>

                    {/* Personal Information */}
                    <Grid item xs={12}>
                        <StyledCard>
                            <CardContent>
                                <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold', mb: 3 }}>
                                    Personal Information
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <DetailRow>
                                            <span className="label">Name:</span>
                                            <span className="value">{rows?.name}</span>
                                        </DetailRow>
                                        <DetailRow>
                                            <span className="label">Email:</span>
                                            <span className="value">{rows?.email}</span>
                                        </DetailRow>
                                        <DetailRow>
                                            <span className="label">Gender:</span>
                                            <span className="value">{rows?.gender}</span>
                                        </DetailRow>
                                        <DetailRow>
                                            <span className="label">Date of Birth:</span>
                                            <span className="value">
                                                {rows?.dateOfBirth && moment(rows?.dateOfBirth).format('DD/MM/YYYY')}
                                            </span>
                                        </DetailRow>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DetailRow>
                                            <span className="label">Phone:</span>
                                            <span className="value">{rows?.phoneNumber}</span>
                                        </DetailRow>
                                        <DetailRow>
                                            <span className="label">Blood Group:</span>
                                            <span className="value">{rows?.bloodGroup}</span>
                                        </DetailRow>
                                        <DetailRow>
                                            <span className="label">Temporary Code:</span>
                                            <span className="value">{rows?.temporaryCode}</span>
                                        </DetailRow>
                                        {rows?.code && (
                                            <DetailRow>
                                                <span className="label">Permanent Code:</span>
                                                <span className="value">{rows?.code}</span>
                                            </DetailRow>
                                        )}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </StyledCard>
                    </Grid>

                    {/* Lab Details */}
                    {rows?.labDetails && (
                        <Grid item xs={12}>
                            <StyledCard>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold', mb: 3 }}>
                                        Laboratory Details
                                    </Typography>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <DetailRow>
                                                <span className="label">Lab Name:</span>
                                                <span className="value">{rows.labDetails.name}</span>
                                            </DetailRow>
                                            <DetailRow>
                                                <span className="label">Email:</span>
                                                <span className="value">{rows.labDetails.email}</span>
                                            </DetailRow>
                                            <DetailRow>
                                                <span className="label">Phone:</span>
                                                <span className="value">
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <PhoneIcon sx={{ mr: 1, color: 'primary.main' }} />
                                                        {rows.labDetails.mobileNumber}
                                                    </Box>
                                                </span>
                                            </DetailRow>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <DetailRow>
                                                <span className="label">Appointment:</span>
                                                <span className="value">
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <CalendarTodayIcon sx={{ mr: 1, color: 'primary.main' }} />
                                                        {moment(rows.appointmentDate).format('DD/MM/YYYY')}
                                                        <AccessTimeIcon sx={{ ml: 2, mr: 1, color: 'primary.main' }} />
                                                        {moment(rows.appointmentDate).format('hh:mm A')}
                                                    </Box>
                                                </span>
                                            </DetailRow>
                                            <DetailRow>
                                                <span className="label">Address:</span>
                                                <span className="value">
                                                    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                                        <LocationOnIcon sx={{ mr: 1, color: 'primary.main', mt: 0.5 }} />
                                                        <Typography variant="body2">
                                                            {rows.labDetails.location}
                                                            <StyledLocationButton
                                                                size="small"
                                                                onClick={() => handleGetDirections(rows.labDetails.latitude, rows.labDetails.longitude)}
                                                                title="Get Directions"
                                                            >
                                                                <LocationOnIcon fontSize="small" />
                                                            </StyledLocationButton>
                                                        </Typography>
                                                    </Box>
                                                </span>
                                            </DetailRow>
                                            <DetailRow>
                                                <span className="label">Status:</span>
                                                <span className="value">
                                                    {getStatusChip(rows.appointmentStatus)}
                                                </span>
                                            </DetailRow>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </StyledCard>
                        </Grid>
                    )}

                    {/* Medical Information */}
                    <Grid item xs={12}>
                        <StyledCard>
                            <CardContent>
                                <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold', mb: 3 }}>
                                    Medical Information
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <DetailRow>
                                            <span className="label">Medical Date:</span>
                                            <span className="value">
                                                {rows?.medicalDate && moment(rows?.medicalDate).format('DD/MM/YYYY')}
                                            </span>
                                        </DetailRow>
                                        <DetailRow>
                                            <span className="label">Assigned Doctor:</span>
                                            <span className="value">{rows?.doctorName}</span>
                                        </DetailRow>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DetailRow>
                                            <span className="label">Report Status:</span>
                                            <span className="value">{getStatusChip(rows?.reportStatus)}</span>
                                        </DetailRow>
                                        <DetailRow>
                                            <span className="label">Fitness Status:</span>
                                            <span className="value">{getStatusChip(rows?.fitnessStatus)}</span>
                                        </DetailRow>
                                    </Grid>
                                </Grid>

                                {rows?.fitnessRejectComment && (
                                    <Box sx={{ mt: 2, p: 2, bgcolor: '#fff3e0', borderRadius: 1 }}>
                                        <Typography variant="subtitle1" color="error" fontWeight="bold">
                                            Fitness Reject Reason: {rows?.fitnessRejectComment}
                                        </Typography>
                                    </Box>
                                )}
                            </CardContent>
                        </StyledCard>
                    </Grid>

                    {/* Medical Reports */}
                    {rows?.reports && rows.reports.length > 0 && (
                        <Grid item xs={12}>
                            <StyledCard>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold', mb: 3 }}>
                                        Medical Reports
                                    </Typography>
                                    <Grid container spacing={3}>
                                        {rows.reports.map((report, index) => (
                                            <Grid item xs={12} sm={6} md={4} key={report.reportId}>
                                                <Card sx={{ height: '100%' }}>
                                                    <CardMedia
                                                        component="img"
                                                        height="200"
                                                        image={`${process.env.REACT_APP_API_BASE_URL}uploads/${report.imagePath}`}
                                                        alt={`Report ${report.reportName}`}
                                                        sx={{ objectFit: 'contain', p: 1 }}
                                                    />
                                                    <CardContent>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            Report Name : {report.reportName}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            Status: {getStatusChip(report.reportStatus)}
                                                        </Typography>
                                                        {report.documentRejectComment && (
                                                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                                                Reject Comment: {report.documentRejectComment}
                                                            </Typography>
                                                        )}
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </CardContent>
                            </StyledCard>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </Box>
    );
};

export default PreEmploymentExamView;