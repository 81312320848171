import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { TextField } from '@mui/material';

const containerStyle = {
    width: '100%',
    height: '400px',
};

const defaultCenter = {
    lat: 37.7749,
    lng: -122.4194,
};
const libraries = ['places']
const LocationSearch = ({ onLocationChange }) => {
    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    const { isLoaded } = useLoadScript({
        id: 'google-map-script',
        googleMapsApiKey,
        libraries,
    });

    const [address, setAddress] = useState('');
    const [selected, setSelected] = useState(null);
    const [mapCenter, setMapCenter] = useState(defaultCenter);
    const [geocoder, setGeocoder] = useState(null);

    useEffect(() => {
        if (isLoaded) {
            setGeocoder(new window.google.maps.Geocoder());
        }
    }, [isLoaded]);

    const getAddressUsingLatLong = useCallback((lat, lng) => {
        if (!geocoder) return;
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === 'OK' && results[0]) {
                const address = results[0].formatted_address;
                setAddress(address);
                onLocationChange({address, lat, lng});
            } else {
                onLocationChange({address:'', lat, lng});
            }
        });
    }, [geocoder, onLocationChange]);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setMapCenter({ lat: latitude, lng: longitude });
                    setSelected({ lat: latitude, lng: longitude });
                    getAddressUsingLatLong(latitude, longitude);
                },
                () => {
                    console.error('Error getting user location');
                }
            );
        }
    }, [onLocationChange, getAddressUsingLatLong]);

    const handleSelect = async (value) => {
        setAddress(value);
        try {
            const results = await geocodeByAddress(value);
            const { lat, lng } = await getLatLng(results[0]);
            setMapCenter({ lat, lng });
            setSelected({ lat, lng });
            onLocationChange({address:value, lat, lng});
        } catch (error) {
            console.error('Error selecting location:', error);
        }
    };

    const handleMapClick = (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setMapCenter({ lat, lng });
        setSelected({ lat, lng });
        if (geocoder) {
            getAddressUsingLatLong(lat, lng);
        }
    };

    const handleMarkerDragEnd = (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setMapCenter({ lat, lng });
        setSelected({ lat, lng });
        if (geocoder) {
            getAddressUsingLatLong(lat, lng);
        }
    };

    return isLoaded ? (
        <>
            <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        <TextField
                            fullWidth
                            {...getInputProps({
                                label: 'Search for location...',
                                className: 'location-search-input',
                            })}
                            style={{ marginBottom: '10px' }}
                        />
                        <div style={{ position: 'absolute', zIndex: 1000 }}>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                                const style = {
                                    backgroundColor: suggestion.active ? '#e3e3e3' : '#fff',
                                    cursor: 'pointer',
                                    padding: '10px',
                                };
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, { style })}
                                        key={suggestion.placeId}
                                    >
                                        {suggestion.description}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>

            <GoogleMap
                mapContainerStyle={containerStyle}
                center={mapCenter}
                zoom={14}
                onClick={handleMapClick}
            >
                {selected && (
                    <Marker
                        position={selected}
                        draggable={true}
                        onDragEnd={handleMarkerDragEnd}
                    />
                )}
            </GoogleMap>
        </>
    ) : (
        <div>Loading Map...</div>
    );
};

export default LocationSearch;