import React, { useState } from 'react';
import sharedImports from '../../../Comman/sharedImports';
import styles from '../../../../css/styles';
import { SendGetRequest, SendPostRequest, SendPostRequestWithFile } from 'api/config/ApiCall';
import { useSnackbar } from 'notistack';
import { saveAs } from 'file-saver';
import {
    Box,
    Button,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    Alert,
    IconButton,
    Stack,
    Divider
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';

const ImportDoctor = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [selectedFile, setSelectedFile] = useState(null);
    const [validationErrors, setValidationErrors] = useState([]);
    const [isImportEnabled, setIsImportEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const resetInputValue = () => {
        const fileInput = document.querySelector('input[type="file"]');
        if (fileInput) {
            fileInput.value = '';
        }
    };
    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                enqueueSnackbar('Please upload only Excel files (.xlsx)', { variant: 'error' });
                event.target.value = ''; // Reset input
                return;
            }
            setSelectedFile(file);
            setValidationErrors([]);
            setIsImportEnabled(false);
        }
    };

    const handleDownloadTemplate = async () => {
        try {
            setIsLoading(true);
            const response = await SendGetRequest('downloadDoctorTemplate', {});
            if (response.status) {
                const bufferData = new Uint8Array(response.data.data);
                const blob = new Blob([bufferData], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                saveAs(blob, 'doctor-template.xlsx');
            } else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        } catch (error) {
            console.error('Error downloading template:', error);
            enqueueSnackbar('Error downloading template', { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleValidate = async () => {
        if (!selectedFile) {
            enqueueSnackbar('Please select a file first', { variant: 'warning' });
            return;
        }

        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await SendPostRequestWithFile('validateDoctorImport', formData);
            if (response.status) {
                enqueueSnackbar('File validation successful', { variant: 'success' });
                setIsImportEnabled(true);
                setValidationErrors([]);
            } else {
                setIsImportEnabled(false);
                if (response.errors && Array.isArray(response.errors)) {
                    setValidationErrors(response.errors);
                }
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        } catch (error) {
            setIsImportEnabled(false);
            enqueueSnackbar('Error validating file', { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleImport = async () => {
        if (!selectedFile || !isImportEnabled) return;

        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await SendPostRequestWithFile('importDoctors', formData);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: 'success' });
                setSelectedFile(null);
                setValidationErrors([]);
                setIsImportEnabled(false);
                resetInputValue();
            } else {
                resetInputValue();
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        } catch (error) {
            resetInputValue();
            enqueueSnackbar('Error importing doctors', { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleClearFile = () => {
        setSelectedFile(null);
        setValidationErrors([]);
        setIsImportEnabled(false);
        // Reset the file input
        resetInputValue();
    };

    return (
        <Box sx={{ p: 3, margin: '0 auto' }}>
            <Paper elevation={3} sx={{ p: 3 }}>
                <Typography variant="h5" gutterBottom>
                    Import Doctors
                </Typography>

                <Divider sx={{ my: 2 }} />
                <Alert severity="info" sx={{ color: 'red', fontWeight: 'bold', fontSize: '16px', bgcolor: 'lightgray' }}>
                    Mandatory Fields: Name, Email, EmployeeCode, RegistrationNo & PhoneNo
                </Alert>
                <Divider sx={{ my: 2 }} />

                <Stack spacing={3}>
                    {/* Download Template Section */}
                    <Box>
                        <Button
                            variant="outlined"
                            startIcon={<DownloadIcon />}
                            onClick={handleDownloadTemplate}
                            disabled={isLoading}
                        >
                            Download Template
                        </Button>
                    </Box>

                    {/* File Upload Section */}
                    <Box>
                        <input
                            type="file"
                            accept=".xlsx"
                            onChange={handleFileSelect}
                            style={{ display: 'none' }}
                            id="file-upload"
                        />
                        <Stack direction="row" spacing={2} alignItems="center">
                            <label htmlFor="file-upload">
                                <Button
                                    variant="contained"
                                    component="span"
                                    startIcon={<CloudUploadIcon />}
                                    disabled={isLoading}
                                >
                                    Upload File
                                </Button>
                            </label>
                            {selectedFile && (
                                <>
                                    <Typography>{selectedFile.name}</Typography>
                                    <IconButton onClick={handleClearFile} disabled={isLoading}>
                                        <DeleteIcon />
                                    </IconButton>
                                </>
                            )}
                        </Stack>
                    </Box>

                    {/* Action Buttons */}
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            onClick={handleValidate}
                            disabled={!selectedFile || isLoading}
                        >
                            Validate
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleImport}
                            disabled={!isImportEnabled || isLoading}
                        >
                            Import
                        </Button>
                    </Stack>

                    {/* Validation Errors */}
                    {validationErrors.length > 0 && (
                        <Box sx={{ mt: 2 }}>
                            <Alert severity="error" sx={{ mb: 2 }}>
                                Validation Failed - Please correct the following errors:
                            </Alert>
                            <Paper variant="outlined" sx={{ maxHeight: 300, overflow: 'auto' }}>
                                <List dense>
                                    {validationErrors.map((error, index) => (
                                        <ListItem key={index}>
                                            <ListItemText primary={error} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        </Box>
                    )}
                </Stack>
            </Paper>
        </Box>
    );
};

export default ImportDoctor;