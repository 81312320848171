import React from 'react';
import sharedImports from '../../../Comman/sharedImports';
import { StyledTableCell, StyledTableRow } from 'helper/employee/employeeHelpers';
import styles from 'css/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteButton from 'component/Comman/DeleteButton/DeleteButton';
import PaginationControls from 'component/Comman/paginationControls';
const {
    Box,
    Grid,
    Button,
    Dialog,
    TextField,
    Typography,
    DialogTitle,
    DialogContent,
    DialogActions,
    UnfoldMoreOutlinedIcon,
    TableBody,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
} = sharedImports;

const EmploymentDetailsDialog = ({
    employmentData,
    openEmploymentModel,
    handleCloseEmployment,
    handleChangeEmployment,
    handleSubmitEmploymentForm,
    employmentListData,
    handleEditEmployment,
    handleDeleteEmployment,
    handleSortEmployment,
    employmentPagination,
    setEmploymentPagination,
    employmentGlobalFilter,
    employmentRowCount,
    setEmploymentGlobalFilter,
}) => {
    return (
        <>
            <Dialog open={openEmploymentModel} onClose={handleCloseEmployment} fullWidth maxWidth="md">
                <DialogTitle>Add Details</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Box sx={[styles.box]}> Company Name <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                            </Box>
                            <TextField
                                required
                                id="company_name"
                                fullWidth
                                size='small'
                                name="company_name"
                                value={employmentData.company_name}
                                onChange={handleChangeEmployment}
                                placeholder="Company Name"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={[styles.box]}> Duration <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                            </Box>
                            <TextField
                                required
                                id="duration"
                                fullWidth
                                size='small'
                                name="duration"
                                value={employmentData.duration}
                                onChange={handleChangeEmployment}
                                placeholder="Duration"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={[styles.box]}> Nature of work <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                            </Box>
                            <TextField
                                required
                                id="nature_of_work"
                                fullWidth
                                size='small'
                                name="nature_of_work"
                                value={employmentData.nature_of_work}
                                onChange={handleChangeEmployment}
                                placeholder='Nature of work'
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={[styles.box]}> Exposure History <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                            </Box>
                            <TextField
                                required
                                id="exposure_history"
                                fullWidth
                                size='small'
                                name="exposure_history"
                                value={employmentData.exposure_history}
                                onChange={handleChangeEmployment}
                                placeholder='Exposure History'
                            />
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Grid item xs={12} md={4} mt={4}>
                            <Button onClick={handleCloseEmployment}>Cancel</Button>
                            <Button variant="contained" onClick={handleSubmitEmploymentForm}>Submit</Button>
                        </Grid>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Box sx={{ mt: 5, mb: 5, mx: 2, bgcolor: 'white', borderRadius: 1 }}>
                <Box component="div" xs={12}>
                    <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                                <Box component='span' sx={{ fontWeight: 'bold' }}>Show </Box>
                                <select
                                    id="outlined-number"
                                    sx={{ width: '9ch' }}
                                    value={employmentPagination.pageSize}
                                    onChange={e => setEmploymentPagination(prev => ({ ...prev, pageSize: Number(e.target.value), pageIndex: 0 }))}
                                >
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                </select>
                            </Grid>
                        </Grid>
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                            <TextField
                                id="filled-search"
                                type="search"
                                size='small'
                                value={employmentGlobalFilter}
                                onChange={(e) => setEmploymentGlobalFilter(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper} sx={{ mb: 2 }}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow sx={styles.TableRow}>
                                    <StyledTableCell onClick={() => handleSortEmployment('company_name')}>
                                        Company Name
                                        <UnfoldMoreOutlinedIcon />
                                    </StyledTableCell>
                                    <StyledTableCell onClick={() => handleSortEmployment('duration')}>
                                        Duration
                                        <UnfoldMoreOutlinedIcon />
                                    </StyledTableCell>
                                    <StyledTableCell onClick={() => handleSortEmployment('nature_of_work')}>
                                        Nature of work
                                        <UnfoldMoreOutlinedIcon />
                                    </StyledTableCell>
                                    <StyledTableCell onClick={() => handleSortEmployment('exposure_history')}>
                                        Exposure History
                                        <UnfoldMoreOutlinedIcon />
                                    </StyledTableCell>
                                    <StyledTableCell>Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {employmentListData.length === 0 ? (
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={5} align="center">
                                            No records found
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ) : (
                                    employmentListData.map((row, index) => (
                                        <StyledTableRow key={row?.assetTag} sx={styles.TableRow}>
                                            <StyledTableCell>{row?.company_name}</StyledTableCell>
                                            <StyledTableCell>{row?.duration}</StyledTableCell>
                                            <StyledTableCell>{row?.nature_of_work}</StyledTableCell>
                                            <StyledTableCell>{row?.exposure_history}</StyledTableCell>
                                            <StyledTableCell>
                                                <EditOutlinedIcon onClick={() => handleEditEmployment(row.id)} />
                                                <DeleteButton
                                                    id={row?.id}
                                                    fieldName={row?.company_name}
                                                    index={row?.index}
                                                    handleDeleteRow={() => handleDeleteEmployment(row?.id)}
                                                    titleName="Employee Family"
                                                />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <PaginationControls
                        pagination={employmentPagination}
                        rowCount={employmentRowCount}
                        setPagination={setEmploymentPagination}
                    />
                </Box>
            </Box>
        </>
    );
};

export default EmploymentDetailsDialog;
