import React, { useState, useEffect } from 'react';
import {
    AppBar, Toolbar, Typography, Container, TextField, Button, Box,
    FormControl, FormControlLabel, RadioGroup, Radio, FormLabel, Grid, CircularProgress,
    Alert, Card, CardContent, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import {
    SendPostRequest, SendGetRequest, SendPutRequest
} from '../../api/config/ApiCall';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import './style.css';
import Reports from './reports';
import LocationSearch from 'component/Comman/SearchLocation/LocationSearch';

const StyledCard = styled(Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.spacing(2),
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    '&:hover': {
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
        transform: 'translateY(-2px)',
        transition: 'all 0.3s ease'
    }
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 1000
}));

const PublicFormPage = () => {
    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        lab: '',
        lab_name: '',
        appointment_date_time: '',
        phone_number: '',
        appointment_time: '',
        reportType: '',
        dob: '',
        reportStatus: null,
        report: null,
        doctorComments: '',
        blood_group: '',
        location: {
            address: null,
            lat: '',
            lng: ''
        }
    });

    const [states, setStates] = useState({
        isNeadByHited: false,
        nearbyLabs: [],
        isValidUser: true,
        appointment_status: '',
        isFormVisible: false,
        appoinment_reject_comment: '',
    });
    const [loading, setLoading] = useState(false);
    const [loadingLabs, setLoadingLabs] = useState(false);
    const [selectedLab, setSelectedLab] = useState('');
    const [userLocation, setUserLocation] = useState(null);
    const [locationError, setLocationError] = useState('');
    const [permissionDialogOpen, setPermissionDialogOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const userInfo = await SendGetRequest('publicPreJoineeDetail', { id });
                if (userInfo?.status) {
                    const data = userInfo.data[0];
                    setFormData(data);
                    setStates(prev => ({ ...prev, isValidUser: true, appointment_status: data?.appointment_status, isFormVisible: data?.isFormVisible, appoinment_reject_comment: data?.appoinment_reject_comment }));
                } else {
                    setStates(prev => ({ ...prev, isValidUser: false }));
                    enqueueSnackbar(userInfo.message, { variant: 'error' });
                }
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        fetchUserInfo();
    }, [enqueueSnackbar, id]);

    useEffect(() => {
        checkLocationPermission();
    }, []);

    const checkLocationPermission = async () => {
        try {
            const result = await navigator.permissions.query({ name: 'geolocation' });
            if (result.state === 'denied') {
                setLocationError('Location permission is denied. Please enable location services to continue.');
                setPermissionDialogOpen(true);
            } else if (result.state === 'prompt') {
                setPermissionDialogOpen(true);
            } else {
                getUserLocation();
            }
        } catch (error) {
            console.error('Error checking location permission:', error);
            setLocationError('Unable to check location permission');
        }
    };

    const getUserLocation = () => {
        setLoading(true);
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setUserLocation({ latitude, longitude });
                fetchNearbyLabs(latitude, longitude);
                setLocationError('');
            },
            (error) => {
                console.error('Error getting location:', error);
                setLocationError('Unable to get your location. Please enable location services.');
                setLoading(false);
            }
        );
    };

    const fetchNearbyLabs = async (latitude, longitude) => {
        try {
            setLoadingLabs(true);
            const response = await SendGetRequest("getNearByLabs", {
                latitude,
                longitude
            });
            
            if (response.status) {
                setStates(prev => ({ ...prev, nearbyLabs: response.data }));
            } else {
                enqueueSnackbar(response.message || 'Failed to fetch nearby labs', { variant: 'error' });
            }
        } catch (error) {
            console.error('Error fetching labs:', error);
            enqueueSnackbar('Error fetching nearby labs', { variant: 'error' });
        } finally {
            setLoadingLabs(false);
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const data = {
                ...formData,
                location: formData?.location?.address ?? null,
                latitude: formData?.location?.lat,
                longitude: formData?.location?.lng,
                id
            };
            const response = !states.appointment_status ? await SendPostRequest("addTemporaryPrejoinee", data) : await SendPutRequest("addTemporaryPrejoinee", data, id);
            if (response.status) {
                setStates(prev => ({ ...prev, isFormVisible: false }));
                enqueueSnackbar(response.message, { variant: "success" });
            } else {
                enqueueSnackbar(response.message, { variant: "warning" });
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleLabChange = (event) => {
        setSelectedLab(event.target.value);
    };

    const handleLocationChange = React.useCallback(async ({ address, lat, lng }) => {
        await fetchNearbyLabs(lat, lng);
        setFormData(prev => ({ ...prev, location: { address, lat, lng } }));
    }, []);

    const handleRetryLocation = () => {
        setPermissionDialogOpen(false);
        getUserLocation();
    };
    const handleGetDirections = (latitude, longitude) => {
        if (latitude && longitude) {
            window.open(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`, '_blank');
        }
    };
    if (!states.isValidUser) {
        return (
            <Container maxWidth="sm">
                <Box mt={4} textAlign="left">
                    <Typography variant="h6" component="p">
                        Invalid User
                    </Typography>
                </Box>
            </Container>
        )
    }

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div">
                    Health Care
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container maxWidth="xl">
                <Box mt={4} textAlign="left">
                    <Typography variant="h4" component="h1" gutterBottom>
                        Basic
                    </Typography>
                    <form onSubmit={handleSubmit} style={{ textAlign: 'left', marginTop: '30px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    name="name"
                                    variant="outlined"
                                    value={formData?.full_name}
                                    onChange={handleChange}
                                    disabled
                                    size='small'
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    size='small'
                                    name="email"
                                    type="email"
                                    variant="outlined"
                                    value={formData?.email}
                                    onChange={handleChange}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Mobile No."
                                    size='small'
                                    name="phone_number"
                                    type="number"
                                    variant="outlined"
                                    value={Number(formData?.phone_number)}
                                    onChange={handleChange}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Lab Name"
                                    size='small'
                                    name="lab_name"
                                    type="text"
                                    variant="outlined"
                                    value={formData?.lab_name}
                                    onChange={handleChange}
                                    disabled
                                />
                            </Grid>
                            {states.isFormVisible ? (
                                <>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant="h4" component="h2" gutterBottom>
                                            Schedule Appointment
                                        </Typography>
                                    </Grid>
                                    {(states?.appointment_status === '2' && states?.appoinment_reject_comment) && (
                                        <Grid item xs={12} md={12}>
                                            <Box
                                                sx={{
                                                    backgroundColor: '#f8d7da',
                                                    padding: 2,
                                                    borderRadius: '8px',
                                                    border: '1px solid #f5c2c7'
                                                }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    component="p"
                                                    sx={{ color: '#842029', fontWeight: 'bold' }}
                                                >
                                                    Unfortunately, we cannot confirm your appointment on{' '}
                                                    <span style={{ color: '#0d6efd', fontWeight: 'bold' }}>
                                                        {String(formData?.appointment_date_time)}
                                                    </span>{' '}
                                                    due to{' '}
                                                    <span style={{ color: '#d63384', fontWeight: 'bold' }}>
                                                        {states.appoinment_reject_comment}
                                                    </span>.
                                                    We apologize for the inconvenience. Please reach out to reschedule.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} md={12}>
                                        <LocationSearch onLocationChange={handleLocationChange} />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        {locationError && (
                                            <Alert severity="error" sx={{ mb: 2 }}>
                                                {locationError}
                                                <Button
                                                    startIcon={<MyLocationIcon />}
                                                    onClick={handleRetryLocation}
                                                    sx={{ ml: 2 }}
                                                >
                                                    Retry Location
                                                </Button>
                                            </Alert>
                                        )}
                                        {userLocation && (
                                            <Box sx={{ mb: 3 }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Your Location:
                                                </Typography>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <LocationOnIcon color="primary" />
                                                    <Typography>
                                                        {userLocation.latitude.toFixed(6)}, {userLocation.longitude.toFixed(6)}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        )}
                                        {loadingLabs ? (
                                            <Typography>Loading labs...</Typography>
                                        ) : (
                                            <FormControl component="fieldset" style={{ width: '100%' }}>
                                                <FormLabel component="legend">Select Lab</FormLabel>
                                                <RadioGroup
                                                    name="lab"
                                                    value={formData?.lab}
                                                    onChange={handleChange}
                                                    className='lab-radio-group'
                                                    style={{ width: '100%', marginTop: '20px' }}
                                                >
                                                    <Grid container spacing={2}>
                                                        {states?.nearbyLabs?.length > 0 ? (
                                                            states.nearbyLabs.map((lab) => (
                                                                <Grid item xs={12} md={6} key={lab?.lab_id}>
                                                                    <StyledCard>
                                                                        <CardContent>
                                                                            <FormControlLabel
                                                                                value={lab?.lab_id}
                                                                                control={<Radio />}
                                                                                label={
                                                                                    <Box>
                                                                                        <Typography variant="h6" gutterBottom>{lab?.name}</Typography>
                                                                                        <Typography variant="body2" color="textSecondary">
                                                                                            <LocationOnIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
                                                                                            {lab?.location}
                                                                                        </Typography>
                                                                                        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                                                                            {lab?.description}
                                                                                        </Typography>
                                                                                        <Button onClick={() => handleGetDirections(lab?.latitude, lab?.longitude)} color="primary" sx={{ mt: 1 }}>
                                                                                           Direction
                                                                                        </Button>
                                                                                        <Typography variant="body2" sx={{ mt: 1 }}>
                                                                                            📞 {lab?.mobileNumber}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                }
                                                                                sx={{ margin: 0, width: '100%' }}
                                                                            />
                                                                        </CardContent>
                                                                    </StyledCard>
                                                                </Grid>
                                                            ))
                                                        ) : (
                                                            <Grid item xs={12}>
                                                                <Alert severity="info">
                                                                    No labs found in your area. Please try a different location.
                                                                </Alert>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </RadioGroup>
                                            </FormControl>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label="Appointment Date and Time"
                                            name="appointment_date_time"
                                            type="datetime-local"
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            value={String(formData?.appointment_date_time)}
                                            onChange={handleChange}
                                            inputProps={{ min: new Date().toISOString().slice(0, 16) }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Button 
                                            variant="contained" 
                                            color="primary" 
                                            type="submit" 
                                            sx={{ width: '100%', padding: '10px !important' }} 
                                            disabled={loading || !formData?.lab || !userLocation}
                                        >
                                            {loading ? <CircularProgress size={24} /> : 'Submit'}
                                        </Button>
                                    </Grid>
                                </>
                            ) : (
                                (states?.appointment_status === '1' ?
                                    <Grid item xs={12} md={12}>
                                        {(states?.appointment_status === '1' && formData?.reportStatus === null) && (<Box
                                            sx={{
                                                backgroundColor: '#d1e7dd',
                                                padding: 2,
                                                borderRadius: '8px',
                                                border: '1px solid #badbcc'
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                component="p"
                                                sx={{ color: '#0f5132', fontWeight: 'bold' }}
                                            >
                                                Your appointment on{' '}
                                                <span style={{ color: '#0d6efd', fontWeight: 'bold' }}>
                                                    {String(formData?.appointment_date_time)}
                                                </span>{' '}
                                                has been confirmed! We look forward to seeing you. If you have any questions or need to reschedule, please don't hesitate to contact us.
                                            </Typography>
                                        </Box>)}
                                        {formData?.lab && <Reports lab_id={formData?.lab} id={id} dob={formData?.dob} reportStatus={formData?.reportStatus} />}
                                    </Grid>
                                    :
                                    <Grid item xs={12} md={12}>
                                        <Typography variant="h6" component="p">
                                            Your submitted data is in progress. No further action is required.
                                        </Typography>
                                    </Grid>
                                )
                            )}
                        </Grid>
                    </form>
                </Box>
            </Container>

            {/* Location Permission Dialog */}
            <Dialog 
                open={permissionDialogOpen || (!userLocation && !locationError)} 
                onClose={() => {
                    if (userLocation) {
                        setPermissionDialogOpen(false);
                    }
                }}
            >
                <DialogTitle>Location Permission Required</DialogTitle>
                <DialogContent>
                    <Typography>
                        We need your location to find nearby labs. Please enable location services to continue.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => {
                            if (userLocation) {
                                setPermissionDialogOpen(false);
                            }
                        }}
                        disabled={!userLocation}
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleRetryLocation} variant="contained" color="primary">
                        Enable Location
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PublicFormPage;