import React from 'react';
import { Grid, Box } from '@mui/material';

const PageSizeSelector = ({ pageSize, setPagination }) => {
    return (
        <Grid item sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
            <Box component='span' sx={{ mr: 1 }}>Show </Box>
            <select
                id="outlined-number"
                style={{ width: '9ch' }}
                value={pageSize}
                onChange={e => setPagination(prev => ({ ...prev, pageSize: Number(e.target.value), pageIndex: 0 }))}
            >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
            </select>
        </Grid>
    );
}

export default PageSizeSelector;