import { Edit, Save, Cancel } from "@mui/icons-material";
import sharedImports from "../../Comman/sharedImports";
import PaginationControls from "component/Comman/paginationControls";
import moment from "moment";
import * as Papa from "papaparse";
import { saveAs } from "file-saver";
import {
  SendGetRequest,
  SendPostRequest,
} from "api/config/ApiCall";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";

const {
  React,
  PropTypes,
  Box,
  Grid,
  Slide,
  AppBar,
  Button,
  Toolbar,
  TextField,
  styled,
  Paper,
  TableRow,
  TableContainer,
  TableBody,
  TableHead,
  Table,
  Typography,
  CssBaseline,
  AdapterDayjs,
  DemoItem,
  DatePicker,
  useScrollTrigger,
  LocalizationProvider,
  CancelOutlinedIcon,
  SearchOutlinedIcon,
  TableCell,
  tableCellClasses,
  UnfoldMoreOutlinedIcon,
  FileCopyOutlinedIcon,
  Checkbox,
} = sharedImports;

const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};
const styles = {
  TableRow: {
    th: {
      width: "12.5%",
      svg: {
        float: "right",
        color: "#1976d2",
      },
    },
    td: {
      border: "1px solid lightgray",
    },
  },
  h: {
    minHeight: "40px !important",
  },
  bgWhite: {
    bgcolor: "white",
    color: "black",
  },
};

const initalState = {
  Code: "",
  firstName: "",
  company: "",
};

const AssignPermanentCode = () => {
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [isEditing, setIsEditing] = React.useState(false); // Edit mode flag
  const [rows, setRows] = React.useState([]);
  const [state, setState] = React.useState(initalState);
  const [tablestate, setTablestate] = React.useState({
    employee_code: [],
    joining_date: [],
  });
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isApiHit, setIsApiHit] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(0);
  const [isSearched, setIsSearched] = React.useState(false);

  const fetchHRListing = React.useCallback(async () => {
    try {
      const page = pagination.pageIndex + 1;
      const per_page = pagination.pageSize;
      const params = {
        page,
        per_page,
        fitnessStatus: 1,
        search: globalFilter,
      };
      const response = await SendGetRequest("preJoineeList", params);
      if (response.status) {
        const { users, total } = response.data;
        setRows(users);
        setRowCount(total);
      } else {
        console.log(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  }, [pagination, globalFilter]);

  React.useEffect(() => {
    fetchHRListing();
  }, [fetchHRListing, isApiHit, pagination]);

  /*  const handleChange = (event) => {
        const { name, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }; */

  const onSearch = React.useCallback(async () => {
    try {
      setIsSearched(true);
      const page = pagination.pageIndex + 1;
      const per_page = pagination.pageSize;
      const params = {
        page,
        per_page,
        fitnessStatus: 1,
        ...state,
      };
      const response = await SendGetRequest("preJoineeList", params);
      if (response.status) {
        setRows(response.data.users);
        setRowCount(response.data.total);
      } else {
        console.log(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  }, [pagination, state]);

  React.useEffect(() => {
    if (isSearched) {
      onSearch();
    }
  }, [globalFilter, isSearched, onSearch]);

  const ClearFilter = async () => {
    setState(initalState);
    setIsApiHit(!isApiHit);
    setIsSearched(false);
  };

  const handleExport = () => {
    const selectedData = rows.map((row) => ({
      Id: row?.id,
      Name: row?.name,
      Code: row?.employeeCode,
      Contractor: row?.jobDetails?.department || "",
      "Employee Type": row?.jobDetails?.employeeType || "",
      Location: row?.workLocation,
      Department: row?.jobDetails?.department || "",
      Company: row?.jobDetails?.company || "",
      Status: row?.status,
      dateOfJoining: row?.jobDetails?.dateOfJoining
        ? new Date(row?.jobDetails.dateOfJoining).toLocaleDateString()
        : "",
    }));

    // Convert the selected data to CSV
    const csv = Papa.unparse(selectedData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "preEmployment.csv");
  };

  const isSearchQueryDisabled = Object.values(state).every(
    (val) => val === "" || val === null
  );

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // Only select rows that don't have code and dateOfJoining
      const selectableRows = rows
        .filter((row) => !row.code && !row.dateOfJoining)
        .map((row) => row.id);
      setSelectedRows(selectableRows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (event, row) => {
    // Don't allow selection if row has code or dateOfJoining
    if (row.code || row.dateOfJoining) {
      return;
    }

    setSelectedRows((prevSelectedRows) => {
      if (event.target.checked) {
        setTablestate((prevState) => ({
          employee_code: [
            ...prevState?.employee_code,
            { id: row.id, value: row.code || "" },
          ],
          joining_date: [
            { id: row.id, value: row.dateOfJoining || "" },
          ],
        }));
        return [...prevSelectedRows, row?.id];
      } else {
        setTablestate((prevState) => ({
          employee_code: prevState.employee_code.filter(
            (item) => item.id !== row?.id
          ),
          joining_date: prevState.joining_date.filter(
            (item) => item.id !== row?.id
          ),
        }));
        return prevSelectedRows.filter((selectedId) => selectedId !== row?.id);
      }
    });
  };

  const handleTextChange = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // Handle Edit button click
  const handleEdit = () => {
    if (selectedRows.length === 0) {
      enqueueSnackbar("Please select at least one row", { variant: "warning" });
      return;
    }
    setIsEditing(true);
  };

  const handleSaveMultipleData = (field, id, value) => {
    setTablestate((prevState) => {
      // If field doesn't exist, initialize as an empty array
      const fieldData = prevState[field] || [];

      // Check if the item with the given id already exists in the fieldData
      const itemIndex = fieldData.findIndex((item) => item.id === id);

      let updatedFieldData;

      // If the item exists, update its value, else add a new entry
      if (itemIndex >= 0) {
        updatedFieldData = fieldData.map((item, index) =>
          index === itemIndex ? { ...item, value } : item
        );
      } else {
        updatedFieldData = [...fieldData, { id, value }];
      }

      return {
        ...prevState,
        [field]: updatedFieldData, // Update the field data
      };
    });
  };

  const savePermanentCodeListingData = React.useCallback(async () => {
    try {
      // Filter rows to only include selected ones
      const updatedData = rows
        .filter((row) => selectedRows.includes(row.id))
        .map((row) => {
          const employeeCode =
            tablestate.employee_code?.find((item) => item.id === row.id)
              ?.value || row.employeeCode;

          const joiningDate =
            tablestate.joining_date?.find((item) => item.id === row.id)
              ?.value || row.jobDetails?.dateOfJoining;

          return {
            id: row.id,
            employeeCode,
            joiningDate,
          };
        });

      if (updatedData.length === 0) {
        enqueueSnackbar("Please select at least one row to save", {
          variant: "warning",
        });
        return;
      }

      // Validate that all selected rows have both employee_code and joining_date
      const missingData = updatedData.filter(
        (row) => !row.employeeCode || !row.joiningDate
      );

      if (missingData.length > 0) {
        enqueueSnackbar(
          "Please fill both Employee Code and Joining Date for all selected rows",
          { variant: "error" }
        );
        return;
      }

      const params = {
        users: updatedData,
      };

      // Make the API request with the updated data
      const response = await SendPostRequest(
        "preJoineeAssignPermanentCode",
        params
      );

      if (response.status) {
        enqueueSnackbar("Data saved successfully", { variant: "success" });
        // Reset the table state
        setTablestate({
          employee_code: [],
          joining_date: [],
        });
        setIsApiHit(!isApiHit);
        setIsEditing(false); // Exit edit mode after successful save
        setSelectedRows([]); // Clear selections after save
        // Refresh the data
        fetchHRListing();
      } else {
        enqueueSnackbar(response.message || "Failed to save data", {
          variant: "error",
        });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("An error occurred while saving", { variant: "error" });
    }
  }, [
    rows,
    selectedRows,
    tablestate.employee_code,
    tablestate.joining_date,
    isApiHit,
    fetchHRListing,
  ]);

  React.useEffect(() => {
    // savePermanentCodeListingData();
  }, []);

  return (
    <>
      <React.Fragment>
        <Box>
          <CssBaseline />
          <AppBar
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
              mt: { xs: "53px", sm: "64px" },
              zIndex: 1099,
            }}
            elevation={1}
          >
            <Toolbar sx={[styles.h, styles.bgWhite]}>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid item xs={12} md={5}>
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ m: 1, fontWeight: "bold" }}
                  >
                    Assign Permanent Code Bulk
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                  {!isEditing ? (
                    <Button
                      variant="contained"
                      disabled={selectedRows.length === 0}
                      onClick={handleEdit}
                      sx={{ textTransform: "none", m: 1 }}
                      startIcon={<Edit />}
                    >
                      {" "}
                      Edit{" "}
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        sx={{ textTransform: "none", m: 1 }}
                        onClick={() => savePermanentCodeListingData()}
                        startIcon={<Save />}
                        disabled={selectedRows.length === 0}
                      >
                        {" "}
                        Save{" "}
                      </Button>

                      <Button
                        variant="contained"
                        sx={{ textTransform: "none", m: 1 }}
                        onClick={() => setIsEditing(false)}
                        startIcon={<Cancel />}
                      >
                        {" "}
                        Cancel{" "}
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Box
            sx={{
              mt: 8,
              mb: 2,
              mx: 2,
              bgcolor: "white",
              p: 3,
              borderRadius: 1,
            }}
          >
            <Box component="div" xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Box>Code</Box>
                  <TextField
                    required
                    id="filled-search"
                    fullWidth
                    placeholder="Code..."
                    name="Code"
                    size="small"
                    value={state.Code}
                    onChange={(e) => handleTextChange("Code", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box>Name</Box>
                  <TextField
                    required
                    id="filled-search"
                    fullWidth
                    size="small"
                    name="first_name"
                    placeholder="Name..."
                    value={state.name}
                    onChange={(e) => handleTextChange("name", e.target.value)}
                  />
                </Grid>
                {/* <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Company</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="company"
                                                value={state.company}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                {inputData.employeeCompany.map((data, index) => (
                                                    <MenuItem sx={{ ml: 3 }} key={index} value={data.employee_company_id}>
                                                        {data.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid> */}
                <Grid
                  item
                  xs={12}
                  md={12}
                  xl={3}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <Button
                    variant="contained"
                    startIcon={<SearchOutlinedIcon />}
                    sx={{ mr: 0.5, mb: 2, textTransform: "none" }}
                    disabled={isSearchQueryDisabled}
                    onClick={onSearch}
                  >
                    Search
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<CancelOutlinedIcon />}
                    sx={{ ml: 0.5, mb: 2, textTransform: "none" }}
                    disabled={!isSearched}
                    onClick={ClearFilter}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 2,
              mb: 7,
              mx: 2,
              bgcolor: "white",
              p: 3,
              borderRadius: 1,
            }}
          >
            <Box component="div" xs={12}>
              <Grid
                container
                spacing={2}
                direction="row"
                xm={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                justifyContent="space-between"
                sx={{ mb: 2 }}
              >
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <Grid
                    item
                    sx={{ mr: 2, display: "flex", alignItems: "center" }}
                  >
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                      Show{" "}
                    </Box>
                    <TextField
                      id="outlined-number"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue="10"
                      sx={{ width: "9ch" }}
                      size="small"
                    />
                  </Grid>
                  <Grid item>
                    <Box component="span" sx={{ fontWeight: "bold" }}>
                      entries{" "}
                    </Box>
                    <Button
                      onClick={handleExport}
                      variant="outlined"
                      sx={{ textTransform: "none" }}
                      startIcon={<FileCopyOutlinedIcon />}
                    >
                      Export
                    </Button>
                  </Grid>
                </Grid>
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <Box component="span" sx={{ fontWeight: "bold" }}>
                    Search:{" "}
                  </Box>
                  <TextField
                    id="filled-search"
                    type="search"
                    size="small"
                    onChange={(e) => setGlobalFilter(e.target.value)}
                  />
                </Grid>
              </Grid>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        <Checkbox
                          color="primary"
                          indeterminate={
                            selectedRows.length > 0 &&
                            selectedRows.length < rows.filter(row => !row.code && !row.dateOfJoining).length
                          }
                          checked={
                            rows.length > 0 &&
                            selectedRows.length === rows.filter(row => !row.code && !row.dateOfJoining).length
                          }
                          onChange={handleSelectAll}
                          inputProps={{
                            "aria-label": "select all desserts",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        Sr No.
                      </StyledTableCell>
                      <StyledTableCell>
                        Name
                      </StyledTableCell>
                      <StyledTableCell>
                        Code
                      </StyledTableCell>
                      <StyledTableCell>
                        Date of Joining
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => {
                      const isRowSelectable = !row.code && !row.dateOfJoining;
                      const isItemSelected = selectedRows.indexOf(row?.id) !== -1;

                      return (
                        <StyledTableRow key={row?.id}>
                          <StyledTableCell>
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              onChange={(event) => handleRowSelect(event, row)}
                              disabled={!isRowSelectable}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            {pagination.pageIndex * pagination.pageSize +
                              index +
                              1}
                          </StyledTableCell>
                          <StyledTableCell>{row?.name}</StyledTableCell>
                          <StyledTableCell>
                            {isEditing && isItemSelected ? (
                              <TextField
                                value={
                                  tablestate.employee_code?.find(
                                    (item) => item.id === row?.id
                                  )?.value || ""
                                }
                                onChange={(e) =>
                                  handleSaveMultipleData(
                                    "employee_code",
                                    row?.id,
                                    e.target.value
                                  )
                                }
                                size="small"
                              />
                            ) : (
                              row.code || row.employeeCode || ""
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {isEditing && isItemSelected ? (
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  value={
                                    tablestate.joining_date?.find(
                                      (item) => item.id === row?.id
                                    )?.value
                                      ? dayjs(
                                          tablestate.joining_date?.find(
                                            (item) => item.id === row?.id
                                          )?.value
                                        )
                                      : null
                                  }
                                  onChange={(newValue) =>
                                    handleSaveMultipleData(
                                      "joining_date",
                                      row?.id,
                                      newValue
                                    )
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} size="small" />
                                  )}
                                />
                              </LocalizationProvider>
                            ) : (
                              row.dateOfJoining
                                ? moment(row.dateOfJoining).format("DD-MM-YYYY")
                                : row?.jobDetails?.dateOfJoining
                                ? moment(row?.jobDetails?.dateOfJoining).format(
                                    "DD-MM-YYYY"
                                  )
                                : ""
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <PaginationControls
                pagination={pagination}
                rowCount={rowCount}
                setPagination={setPagination}
              />
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    </>
  );
};

export default AssignPermanentCode;
