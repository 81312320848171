import sharedImports from '../../Comman/sharedImports';
const {
    React,
    Box,
    Link,
    clsx,
    alpha,
    styled,
    useTheme,
    PersonIcon,
    Typography,
    useTreeItem,
    TreeItem2Icon,
    TreeItem2Root,
    ArrowRightIcon,
    SimpleTreeView,
    TreeItem2Content,
    ArrowDropDownIcon,
    TreeItem2Provider,
    SaveAsOutlinedIcon,
    TreeItem2IconContainer,
    TreeItem2GroupTransition,
    ArrowRightAltOutlinedIcon,
} = sharedImports;

const employeeManagement = [
    // { id: 3, label: 'Employee Custom Search', to: 'hr/employee-management/employee-custom-search' },
    // { id: 4, label: 'Employee Search', to: 'hr/employee-management/employee-search' },
    { id: 26, label: 'Employee Search', to: 'hr/employee-management/employee-search' },
    { id: 5, label: 'Employee Transfer In', to: 'hr/employee-management/employee-transfer-in' },
    { id: 6, label: 'Employee Transfer Out', to: 'hr/employee-management/employee-transfer-out' },
    { id: 7, label: 'First Aid Training Log', to: 'hr/employee-management/first-aid-training-log' },
    // { id: 8, label: 'Search All Employee', to: 'hr/employee-management/search-all-employee' },
    { id: 9, label: 'Employee Photo', to: 'hr/employee-management/upload-employee-photo' },
    { id: 10, label: 'Employee All Detail', to: 'hr/employee-management/employee-all-detail' },
    { id: 11, label: 'Employee Data Update', to: 'hr/employee-management/employee-data-update' },
    { id: 12, label: 'Location Department Group', to: 'hr/employee-management/location-department-group' },
    { id: 13, label: 'Location Department Head', to: 'hr/employee-management/location-department-head' },
    { id: 14, label: 'Employee Document Search', to: 'hr/employee-management/employee-document-search' },
    { id: 15, label: 'First Aid Dashboard', to: 'hr/employee-management/first-aid-dashboard' },
]

const report = [
    { id: 20, label: 'All Employee List', to: 'hr/report/all-employee-list' },
]


const CustomTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));
const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
    marginBottom: theme.spacing(0.3),
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.expanded': {
        fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '&.focused, &.selected, &.selected.focused': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        color: '#000000',
    },
}));
const CustomTreeItemIconContainer = styled(TreeItem2IconContainer)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));
const CustomTreeItemGroupTransition = styled(TreeItem2GroupTransition)(
    ({ theme }) => ({
        marginLeft: 0,
        [`& .content`]: {
            paddingLeft: theme.spacing(2),
        },
    }),
);
const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    const theme = useTheme();
    const {
        id,
        itemId,
        label,
        disabled,
        children,
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        colorForDarkMode,
        bgColorForDarkMode,
        ...other
    } = props;
    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getLabelProps,
        getGroupTransitionProps,
        status,
    } = useTreeItem({ id, itemId, children, label, disabled, rootRef: ref });
    const style = {
        '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
        '--tree-view-bg-color':
            theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
    };

    return (
        <TreeItem2Provider itemId={itemId}>
            <CustomTreeItemRoot {...getRootProps({ ...other, style })}>
                <CustomTreeItemContent
                    {...getContentProps({
                        className: clsx('content', {
                            expanded: status.expanded,
                            selected: status.selected,
                            focused: status.focused,
                        }),
                    })}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            alignItems: 'center',
                            p: 0,
                            pr: 0,
                        }}
                    >
                        <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                        <Typography
                            {...getLabelProps({
                                variant: 'body2',
                                sx: { display: 'flex', fontWeight: 'inherit', flexGrow: 1 },
                            })}
                        />
                        <Typography variant="caption" color="inherit">
                            {labelInfo}
                        </Typography>
                    </Box>
                    <CustomTreeItemIconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon status={status} />
                    </CustomTreeItemIconContainer>
                </CustomTreeItemContent>
                {children && (
                    <CustomTreeItemGroupTransition {...getGroupTransitionProps()} />
                )}
            </CustomTreeItemRoot>
        </TreeItem2Provider>
    );
});

function EndIcon() {
    return <div style={{ width: 24 }} />;
}

export default function HR() {
    return (
        <SimpleTreeView
            aria-label="HR"
            defaultExpandedItems={['3']}
            defaultSelectedItems="5"
            slots={{
                expandIcon: ArrowRightIcon,
                collapseIcon: ArrowDropDownIcon,
                endIcon: EndIcon,
            }}
            sx={{ flexGrow: 1, maxWidth: 400 }}
        >
            <CustomTreeItem itemId="1" label="HR" labelIcon={PersonIcon}>
                <CustomTreeItem itemId="2" label="Employee Management" labelIcon={SaveAsOutlinedIcon}>
                    {employeeManagement.map((item) => (
                        <Link to={item.to} key={item.id}>
                            <CustomTreeItem
                                itemId={item.id}
                                key={item.id}
                                label={item.label}
                                labelIcon={ArrowRightAltOutlinedIcon}
                                color="#000000"
                                bgColor="#e8f0fe"
                                colorForDarkMode="#B8E7FB"
                                bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                            />
                        </Link>
                    ))}
                </CustomTreeItem>
                <CustomTreeItem itemId="17" label="Master" labelIcon={SaveAsOutlinedIcon}>
                    <Link to='hr/employee-management/employee-master-setup'>
                        <CustomTreeItem
                            itemId="18"
                            label="HR Master Setup"
                            labelIcon={ArrowRightAltOutlinedIcon}
                            color="#000000"
                            bgColor="#e8f0fe"
                            colorForDarkMode="#B8E7FB"
                            bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                        />
                    </Link>
                </CustomTreeItem>
                <CustomTreeItem itemId="19" label="Report" labelIcon={SaveAsOutlinedIcon}>
                    {report.map((item) => (
                        <Link to={item.to} key={item.id}>
                            <CustomTreeItem
                                itemId={item.id}
                                key={item.id}
                                label={item.label}
                                labelIcon={ArrowRightAltOutlinedIcon}
                                color="#000000"
                                bgColor="#e8f0fe"
                                colorForDarkMode="#B8E7FB"
                                bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                            />
                        </Link>
                    ))}
                </CustomTreeItem>
                <CustomTreeItem itemId="24" label="Other Master" labelIcon={SaveAsOutlinedIcon}>
                    <Link to='hr/other-master/import-other-master'>
                        <CustomTreeItem
                            itemId="25"
                            label="Import Other Master"
                            labelIcon={ArrowRightAltOutlinedIcon}
                            color="#000000"
                            bgColor="#e8f0fe"
                            colorForDarkMode="#B8E7FB"
                            bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                        />
                    </Link>
                </CustomTreeItem>
            </CustomTreeItem>
        </SimpleTreeView>
    );
}