import React from "react";
import sharedImports from "../../../../Comman/sharedImports";
import { SendGetRequest } from "api/config/ApiCall";
import { enqueueSnackbar } from "notistack";
import PaginationControls from "component/Comman/paginationControls";
import TestDetailsView from './TestDetailsView';

const {
  AppBar,
  Box,
  Paper,
  Grid,
  Table,
  Select,
  styled,
  Button,
  Toolbar,
  TableRow,
  MenuItem,
  TableHead,
  SearchIcon,
  DemoItem,
  DatePicker,
  TableCell,
  TextField,
  Typography,
  UnfoldMoreOutlinedIcon,
  FormControl,
  AdapterDayjs,
  CssBaseline,
  SearchOutlinedIcon,
  TableContainer,
  tableCellClasses,
  CancelOutlinedIcon,
  TableBody,
  AddCircleOutlineOutlinedIcon,
  LocalizationProvider,
} = sharedImports;
const styles = {
  TableRow: {
    th: {
      width: "12.5%",
      svg: {
        float: "right",
        color: "#1976d2",
      },
    },
    td: {
      border: "1px solid lightgray",
    },
  },
  h: {
    minHeight: "40px !important",
  },
  bgWhite: {
    bgcolor: "white",
    color: "black",
  },
};
const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TestListing() {
  const [state, setState] = React.useState({
    examinationType: "",
    round: "",
    department: "",
    Doctor: "",
    TestStatus: "",
    Test: "",
  });

  const [rows, setRows] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = React.useState(0);
  const [isSearched, setIsSearched] = React.useState(false);
  const [selectedTestId, setSelectedTestId] = React.useState(null);
  const [isViewDialogOpen, setIsViewDialogOpen] = React.useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const fetchTests = React.useCallback(async () => {
    try {
      const params = {
        page: pagination.pageIndex + 1,
        per_page: pagination.pageSize,
        examination_type: state.examinationType,
        round: state.round,
        department: state.department,
        doctor_id: state.Doctor,
        test_status: state.TestStatus,
        test_name: state.Test,
      };
      const response = await SendGetRequest("diagnosticTestsList", params);
      if (response.status) {
        setRows(response.data.tests || []);
        setRowCount(response.data.total || 0);
      } else {
        enqueueSnackbar(response.message || "Error fetching tests", {
          variant: "error",
        });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Error fetching tests", { variant: "error" });
    }
  }, [pagination, state]);

  React.useEffect(() => {
    fetchTests();
  }, [fetchTests, pagination]);

  React.useEffect(() => {
    if (isSearched) {
      fetchTests();
    }
  }, [fetchTests, isSearched]);

  const onSearch = () => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0, // Reset to first page when searching
    }));
    fetchTests();
  };

  const ClearFilter = () => {
    setState({
      examinationType: "",
      round: "",
      department: "",
      Doctor: "",
      TestStatus: "",
      Test: "",
    });
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0, // Reset to first page when clearing filters
    }));
    fetchTests();
  };

  const handleViewClick = (testId) => {
    console.log(testId);
    setSelectedTestId(testId);
    setIsViewDialogOpen(true);
  };

  return (
    <>
      <Box>
        <CssBaseline />
        <AppBar
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            mt: { xs: "53px", sm: "64px" },
            zIndex: 1099,
          }}
          elevation={1}
        >
          <Toolbar sx={[styles.h, styles.bgWhite]}>
            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ m: 1, fontWeight: "bold" }}
                >
                  Test Listing
                </Typography>
              </Grid>
              <Grid item sx={{ m: { xs: "0px", sm: "0px" } }}>
                <Button
                  variant="contained"
                  sx={{ textTransform: "none", m: 1 }}
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                >
                  {" "}
                  Add{" "}
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Box
          sx={{ mt: 8, mb: 2, mx: 2, bgcolor: "white", p: 3, borderRadius: 1 }}
        >
          <Box component="div" xs={12}>
            <Grid container spacing={1} sx={{ mb: 1 }}>
              <Grid item xs={12} md={3}>
                <Box sx={{ fontWeight: "bold" }}>
                  Examination Type{" "}
                  <Box component="span" sx={{ color: "red" }}>
                    *
                  </Box>
                </Box>
                <Grid item xs={12} md={11}>
                  <FormControl fullWidth>
                    <Select
                      name="examinationType"
                      value={state.examinationType}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem sx={{ ml: 3 }} value="">
                        <>-Select-</>
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={1}>
                        Periodic
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={2}>
                        Laboratory
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={3}>
                        Medicine
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={4}>
                        SETRID-COLD
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={12} md={3}>
                <Box sx={{ fontWeight: "bold" }}>Round</Box>
                <Grid item xs={12} md={11}>
                  <FormControl fullWidth>
                    <Select
                      name="round"
                      value={state.round}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem sx={{ ml: 3 }} value="">
                        <>-Select-</>
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={1}>
                        Consumable
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={2}>
                        Laboratory
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={3}>
                        Medicine
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={4}>
                        SETRID-COLD
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={12} md={3}>
                <Box sx={{ fontWeight: "bold" }}>Department</Box>
                <Grid item xs={12} md={11}>
                  <FormControl fullWidth>
                    <Select
                      name="department"
                      value={state?.department}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">
                        <></>
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={1}>
                        Consumable
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={2}>
                        Laboratory
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={3}>
                        Medicine
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={4}>
                        SETRID-COLD
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}></Grid>
            </Grid>

            <Grid container spacing={1} sx={{ mb: 1 }}>
              <Grid item xs={12} md={3}>
                <Box sx={{ fontWeight: "bold" }}>Doctor</Box>
                <Grid item xs={12} md={11}>
                  <FormControl fullWidth>
                    <Select
                      name="Doctor"
                      value={state.Doctor}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem sx={{ ml: 3 }} value="">
                        <>-ALL-</>
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={1}>
                        Sukumar Das
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={2}>
                        Siddharth Darji
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={3}>
                        Pranij Vora
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={4}>
                        Vijay Dave
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={5}>
                        Jay Sheladia
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={6}>
                        Urmik Chauhan
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={12} md={3}>
                <Box sx={{ fontWeight: "bold" }}>Test Status</Box>
                <Grid item xs={12} md={11}>
                  <FormControl fullWidth>
                    <Select
                      name="TestStatus"
                      value={state.TestStatus}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem sx={{ ml: 3 }} value="">
                        <>-ALL-</>
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={1}>
                        Consumable
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={2}>
                        Laboratory
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={3}>
                        Medicine
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={4}>
                        SETRID-COLD
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={12} md={3}>
                <Box sx={{ fontWeight: "bold" }}>Test</Box>
                <Grid item xs={12} md={11}>
                  <FormControl fullWidth>
                    <Select
                      name="Test"
                      value={state.Test}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem sx={{ ml: 3 }} value="">
                        <>-ALL-</>
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={1}>
                        ANGIOGRAPHY
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={2}>
                        BLOOD GROUP
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={3}>
                        Cardiac Profile
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={4}>
                        CBC
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={5}>
                        COLLAGEN PROFILE
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={6}>
                        Dengue Test
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={7}>
                        DIABETIC PROFILE
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={8}>
                        ECG
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={9}>
                        ECHO
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={10}>
                        HEPATIC PROFILE
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={11}>
                        HIV
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={12}>
                        Hormonal Profile
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={13}>
                        Iron Profile
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={14}>
                        LIPID PROFILE
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={15}>
                        Malaria Test
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={16}>
                        MAMMOGRAPHY
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={17}>
                        MANTOUX
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={18}>
                        Other
                      </MenuItem>
                      <MenuItem sx={{ ml: 3 }} value={19}>
                        Pancreatic Profile
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}></Grid>
            </Grid>
            <Grid container spacing={1} sx={{ mb: 1 }}>
              <Grid item xs={12} md={3}>
                <Box sx={{ fontWeight: "bold" }}>From Date</Box>
                <Grid item xs={12} md={11}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoItem>
                        <DatePicker />
                      </DemoItem>
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box sx={{ fontWeight: "bold" }}>To Date</Box>
                <Grid item xs={12} md={11}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoItem>
                        <DatePicker />
                      </DemoItem>
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}></Grid>
              <Grid item xs={12} md={3}></Grid>
            </Grid>

            <Grid container spacing={1} sx={{ mb: 3 }}>
              <Grid item xs={12} md={3}>
                <Typography variant="body1">
                  <Box sx={{ fontWeight: "bold" }}>Employee Code</Box>
                </Typography>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={11}>
                    <TextField
                      required
                      id="filled-search"
                      fullWidth
                      size="small"
                      placeholder="Employee Code"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={1}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                    }}
                  >
                    <SearchIcon />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={3}>
                <Typography variant="body1">
                  <Box sx={{ fontWeight: "bold" }}>Employee Name</Box>
                </Typography>
                <Grid item xs={12} md={11}>
                  <TextField
                    required
                    id="filled-search"
                    fullWidth
                    size="small"
                    placeholder="Employee Name"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              direction="row"
              xm={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              justifyContent="center"
            >
              <Grid item>
                <Button
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  onClick={onSearch}
                  startIcon={<SearchOutlinedIcon />}
                >
                  Search
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                  onClick={ClearFilter}
                  startIcon={<CancelOutlinedIcon />}
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{ mt: 2, mb: 7, mx: 2, bgcolor: "white", p: 3, borderRadius: 1 }}
        >
          <Box component="div" xs={12}>
            <Grid
              container
              spacing={2}
              direction="row"
              xm={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <Grid item sx={{ fontWeight: "bold" }}>
                Total Records:{rowCount}
              </Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow sx={styles.TableRow}>
                    <StyledTableCell>
                      Examination
                      <UnfoldMoreOutlinedIcon />
                    </StyledTableCell>
                    <StyledTableCell>
                      Date
                      <UnfoldMoreOutlinedIcon />
                    </StyledTableCell>
                    <StyledTableCell>
                      Round
                      <UnfoldMoreOutlinedIcon />
                    </StyledTableCell>
                    <StyledTableCell>
                      Employee Code
                      <UnfoldMoreOutlinedIcon />
                    </StyledTableCell>
                    <StyledTableCell>
                      Employee Name
                      <UnfoldMoreOutlinedIcon />
                    </StyledTableCell>
                    <StyledTableCell>
                      Relation
                      <UnfoldMoreOutlinedIcon />
                    </StyledTableCell>
                    <StyledTableCell>
                      Status
                      <UnfoldMoreOutlinedIcon />
                    </StyledTableCell>
                    <StyledTableCell>
                      Action
                      <UnfoldMoreOutlinedIcon />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.id} sx={styles.TableRow}>
                      <StyledTableCell> {row.examination} </StyledTableCell>
                      <StyledTableCell>{row.date}</StyledTableCell>
                      <StyledTableCell>{row.round}</StyledTableCell>
                      <StyledTableCell>{row.employeeCode}</StyledTableCell>
                      <StyledTableCell>{row.employeeName}</StyledTableCell>
                      <StyledTableCell>{row.relation}</StyledTableCell>
                      <StyledTableCell>{row.status}</StyledTableCell>
                      <StyledTableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => handleViewClick(row.id)}
                        >
                          View
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <PaginationControls
              pagination={pagination}
              rowCount={rowCount}
              setPagination={setPagination}
            />
          </Box>
        </Box>
        <TestDetailsView
          open={isViewDialogOpen}
          onClose={() => setIsViewDialogOpen(false)}
          testId={selectedTestId}
        />
      </Box>
    </>
  );
}
