import React from 'react';
import { Route } from 'react-router-dom';

// Diagnostic Components
import CompareParameter from "../component/Navbar/MIS-SubMenus/MIS/compareParameter";
import ExaminationAllLocation from "../component/Navbar/MIS-SubMenus/MIS/examinationAllLocation";
import MISDashboard from "../component/Navbar/MIS-SubMenus/MIS/misDashboard";
import MISExaminationDashboard from "../component/Navbar/MIS-SubMenus/MIS/misExaminationDashboard";
import MISOPDDashboard from "../component/Navbar/MIS-SubMenus/MIS/misOPDDashboard";
import MISReport from "../component/Navbar/MIS-SubMenus/MIS/misReport";
import DiagnosisAnalysis from "../component/Navbar/MIS-SubMenus/Diagnosis-Tracker/diagnosisAnalysis";
import DiagnosisSearch from "../component/Navbar/MIS-SubMenus/Diagnosis-Tracker/diagnosisSearch";

// Export routes directly as an array
const MISRoutes = [
    <Route path="mis/compare-parameter" element={<CompareParameter />} />,
    <Route path="mis/examination-all-location" element={<ExaminationAllLocation />} />,
    <Route path="mis/mis-dashboard" element={<MISDashboard />} />,
    <Route path="mis/mis-examination-dashboard" element={<MISExaminationDashboard />} />,
    <Route path="mis/mis-opd-dashboard" element={<MISOPDDashboard />} />,
    <Route path="mis/mis-report" element={<MISReport />} />,
    <Route path="mis/diagnosis-tracker/diagnosis-analysis" element={<DiagnosisAnalysis />} />,
    <Route path="mis/diagnosis-tracker/diagnosis-search" element={<DiagnosisSearch />} />
];

export default MISRoutes;
