import { useEffect, useState } from 'react';
import sharedImports from 'component/Comman/sharedImports';
import { InsertLanguage, GetLanguage } from '../../../api/changeLanguage';
import styles from 'css/styles';
import { useSnackbar } from 'notistack';
import { CommanAppBarWithAddButton } from 'component/Comman/commanAppBar';
const {
    React,
    Box,
    Grid,
    Button,
    TextField
} = sharedImports;


export default function SafetyLanguage() {
    const [formData, setFormData] = useState({
        headingKey: 'hr_language',
        headingValue: {}
    });
    const { enqueueSnackbar } = useSnackbar();
    const fields = [
        { label: 'Accident Time', name: 'safety_accident_time', placeholder: 'Accident Time' },
        { label: 'Accident Date', name: 'safety_accident_date', placeholder: 'Accident Date' },
        { label: 'Shift', name: 'safety_shift', placeholder: 'Shift' },
        { label: 'Place of Incident', name: 'safety_place_of_incident', placeholder: 'Place of Incident' },
        { label: 'Cause Of Accident', name: 'safety_cause_of_accident', placeholder: 'Cause Of Accident' },
        { label: 'Nature And Extent Injury', name: 'safety_nature_and_extent_injury', placeholder: 'Nature And Extent Injury' },
        { label: 'Accident Type', name: 'safety_accident_type', placeholder: 'Accident Type' },
        { label: 'Total No Of Person Affected', name: 'safety_total_no_of_person_affected', placeholder: 'Total No Of Person Affected' },
        { label: 'Inside Killed', name: 'safety_inside_killed', placeholder: 'Inside Killed' },
        { label: 'Inside Injured', name: 'safety_inside_injured', placeholder: 'Inside Injured' },
        { label: 'Outside Killed', name: 'safety_outside_killed', placeholder: 'Outside Killed' },
        { label: 'Outside Injured', name: 'safety_outside_injured', placeholder: 'Outside Injured' },
        { label: 'Witness1 Name', name: 'safety_witness1_name', placeholder: 'Witness1 Name' },
        { label: 'Witness1 Address', name: 'safety_witness1_address', placeholder: 'Witness1 Address' },
        { label: 'Witness2 Name', name: 'safety_witness2_name', placeholder: 'Witness2 Name' },
        { label: 'Witness2 Address', name: 'safety_witness2_address', placeholder: 'Witness2 Address' },
        { label: 'Accident Remark', name: 'safety_accident_remark', placeholder: 'Accident Remark' },
        { label: 'Activity While Accident', name: 'safety_activity_while_accident', placeholder: 'Activity While Accident' },
        { label: 'IsExplosion', name: 'safety_is_explosion', placeholder: 'IsExplosion' },
        { label: 'IsFire', name: 'safety_is_fire', placeholder: 'IsFire' },
        { label: 'IsChemical', name: 'safety_is_chemical', placeholder: 'IsChemical' },
        { label: 'From Date', name: 'safety_from_date', placeholder: 'From Date' },
        { label: 'To Date', name: 'safety_to_date', placeholder: 'To Date' },
        { label: 'Shift', name: 'safety_shift', placeholder: 'Shift' },
        { label: 'Place of Accident', name: 'safety_place_of_accident', placeholder: 'Place of Accident' },
        { label: 'Participant Type', name: 'safety_participant_type', placeholder: 'Participant Type' },
        { label: 'Employee Code', name: 'safety_employee_code', placeholder: 'Employee Code' },
        { label: 'Incident Type', name: 'safety_incident_type', placeholder: 'Incident Type' },
        { label: 'From Date', name: 'safety_from_date', placeholder: 'From Date' },
        { label: 'To Date', name: 'safety_to_date', placeholder: 'To Date' },
        { label: 'Pending Fitness', name: 'safety_pending_fitness', placeholder: 'Pending Fitness' },
        { label: 'List', name: 'safety_list', placeholder: 'List' }
    ]

    const getData = async () => {
        try {
            const parmas = { headingKey: 'safety_language' }
            const response = await GetLanguage(parmas);
            if (response.status) {
                const { data } = response;
                //console.log(typeof data);
                const formData = { headingKey: 'safety_language', headingValue: {} };
                Object.keys(data).forEach((key) => {
                    formData.headingValue[key] = data[key];
                });
                console.log('formData == ', formData);

                setFormData(formData);

            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        }
        catch (err) {
            enqueueSnackbar("Somthing went wrong", { variant: "error" });
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            headingValue: { ...prevFormData.headingValue, [name]: value }
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        insertData(formData);
    };

    const insertData = async (formData) => {
        try {
            const newData = { ...formData };
            const response = await InsertLanguage(newData);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" })
            } else {
                enqueueSnackbar(`${response.message}`, { variant: "warning" });
            }
        } catch (error) {
            console.log('error', error);

            enqueueSnackbar(`Something went wrong`, { variant: "error" });
        }
    }
    return (
        <Box sx={styles.root}>
            <CommanAppBarWithAddButton appBarTitle="Safety Managment" />
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>

                    <Grid container spacing={2}>
                        <Grid item xs={4} md={4}>
                            <Box sx={{ fontWeight: 'bold' }}>Original Headings</Box>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Box sx={{ fontWeight: 'bold' }}>Change Headings</Box>
                        </Grid>
                    </Grid>

                    {fields.map((field, index) => (
                        <Grid container spacing={2}>
                            <React.Fragment key={index}>
                                <Grid item xs={4} md={4}>
                                    <Box sx={{ fontWeight: 'bold' }}>{field.label}</Box>
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField
                                        required
                                        id={`filled-${field.name}`}
                                        fullWidth
                                        size='small'
                                        name={field.name}
                                        placeholder={field.placeholder}
                                        type={field.type}
                                        value={formData.headingValue[field.name] || ''}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                            </React.Fragment>
                        </Grid>
                    ))}
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}