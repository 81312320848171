import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { DeleteForever } from '@mui/icons-material';
import ResponsiveDialog from '../Popup/Confirm';
import { checkStatusCondition } from 'helper';

function DeleteButton({ id, fieldName, index, handleDeleteRow, titleName, status }) {
  const isClickable = status ? checkStatusCondition(status) : true;
  
  const iconStyle = { color: isClickable ? 'red' : 'gray', cursor: isClickable ? 'pointer' : 'not-allowed' };

  return (
    <ResponsiveDialog
      name={fieldName}
      id={id}
      index={index}
      head={
        <Tooltip arrow placement="left" title={`Delete ${titleName}`}>
          <DeleteForever sx={iconStyle} />
        </Tooltip>
      }

      disabled={!isClickable}
      onPress={handleDeleteRow}
      action="delete"
    />
  );
}

export default DeleteButton;
