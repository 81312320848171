import React from "react";
import sharedImports from '../../Comman/sharedImports';
import PaginationControls from "component/Comman/paginationControls";
import { useNavigate } from 'react-router-dom';

import * as Papa from "papaparse";
import { saveAs } from "file-saver";
import { useSnackbar } from 'notistack';
import styles from "./styles";
import { SendGetRequest, SendPostRequest, SendPutRequest } from 'api/config/ApiCall';
import EmailPopup from "./emailPopup";
import { checkStatus, checkFitnessStatus } from 'helper';
import ReactDOMServer from "react-dom/server";
import EmailTemplate from "../../Comman/EmailTemplate";

const {
    Box,
    Grid,
    Select,
    AppBar,
    Button,
    Toolbar,
    MenuItem,
    TextField,
    styled,
    Paper,
    TableRow,
    TableContainer,
    TableBody,
    TableHead,
    Table,
    Typography,
    CssBaseline,
    FormControl,
    TableCell,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    tableCellClasses,
    FileCopyOutlinedIcon,
    LocalizationProvider,
    DemoItem,
    DatePicker,
    AdapterDayjs,
    Stack,
    SearchIcon,
    HighlightOffIcon,
    NoteAddIcon,
    Radio,
    RadioGroup,
    FormControlLabel,
    DialogContentText,
} = sharedImports;


const drawerWidth = 300;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const initalState = {
    Report: "",
    Filter: "",
    tempCode: "",
    fromDate: null,
    toDate: null,
    name: '',
    search: '',
};

const PreEmploymentExam = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [rows, setRows] = React.useState([]);
    const [state, setState] = React.useState(initalState);
    const [pagination, setPagination] = React.useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [open, setOpen] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedPreJoineeId, setSelectedPreJoineeId] = React.useState('');

    const [isApiHit, setIsApiHit] = React.useState(false);
    const [rowCount, setRowCount] = React.useState(0);
    const [isSearched, setIsSearched] = React.useState(false);
    const [emailPopupOpen, setEmailPopupOpen] = React.useState(false);
    const [selectedEmail, setSelectedEmail] = React.useState('');
    const [emailData, setEmailData] = React.useState({ subject: '', body: '' });
    const [formData, setFormData] = React.useState({});
    const [selectedPreJoinee, setSelectedPreJoinee] = React.useState(null);
    const [doctorsListing, setDoctorsListing] = React.useState([]);
    const fetchHRListing = React.useCallback(async () => {
        try {
            const page = pagination.pageIndex + 1;
            const per_page = pagination.pageSize;
            const params = {
                page,
                per_page,
                search: state.search,
            };
            const response = await SendGetRequest('preJoineeList', params);
            if (response.status) {
                setRows(response.data.users);
                setRowCount(response.data.total);
            }
        } catch (err) {
            console.log(err);
        }
    }, [pagination, state.search]);
    const fetchDoctorsListing = React.useCallback(async () => {
        try {
            const response = await SendGetRequest('doctorList', {});
            if (response.status) {
                setDoctorsListing(response.data?.drUsers);
            }
        } catch (err) {
            console.log(err);
        }
    }, []);
    React.useEffect(() => {
        fetchHRListing();
        fetchDoctorsListing();
    }, [fetchHRListing, fetchDoctorsListing, isApiHit, pagination]);
    const handleChange = (event) => {
        const { name, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleTextChange = (key, value) => {
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };
    const onSearch = async () => {
        try {
            setIsSearched(true);
            const page = pagination.pageIndex + 1;
            const per_page = pagination.pageSize;
            const params = {
                page,
                per_page,
                ...state,
            };
            const response = await SendGetRequest('preJoineeList', params);
            if (response.status) {
                setRows(response.data.users);
                setRowCount(response.data.total);
            } else {
                console.log(response.message);
            }
        } catch (err) {
            console.log(err);
        }
    };
    const ClearFilter = async () => {
        setState(initalState);
        setIsApiHit(!isApiHit);
        setIsSearched(false);
    };

    const handleExport = () => {
        const selectedData = rows.map(row => ({
            Id: row?.id,
            Name: row?.name,
            "Temporary Code": row?.temporaryCode,
            "Email": row?.email,
            "Phone Number": row?.phoneNumber,
            "Blood Group": row?.bloodGroup,
            "Report Status": row?.reportStatus,
            "Fitness Status": row?.fitnessStatus,
            "Date Of Birth": row?.dateOfBirth ? new Date(row?.dateOfBirth).toLocaleDateString() : '',
            dateOfJoining: row?.jobDetails?.dateOfJoining
                ? new Date(row?.jobDetails.dateOfJoining).toLocaleDateString()
                : '',
        }));

        // Convert the selected data to CSV
        const csv = Papa.unparse(selectedData);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, "preEmployment.csv");
    };

    const isSearchQueryDisabled = Object.values(state).every(
        (val) => val === "" || val === null
    );

    const handleSendEmail = (email) => {
        setSelectedEmail(email);
        setEmailPopupOpen(true);
    };

    const handleSendLink = async (email, preJoineeId) => {
        setSelectedEmail(email);
        setSelectedPreJoineeId(preJoineeId);
        setOpenDialog(true);
    };

    const handleConfirmSendLink = async () => {
        const link = `${window.location.origin}/public-form/${selectedPreJoineeId}`;
        
        try {
            const newData = { user_id: selectedPreJoineeId, public_link: link };
            const res = await SendPostRequest("sendLinkToPreJoinee", newData);
            if (res.status) {
                enqueueSnackbar(res.message, { variant: "success" });
            } else {
                enqueueSnackbar(res.message, { variant: "error" });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setOpenDialog(false);
        }
    };

    const handleClosePopup = () => {
        setEmailPopupOpen(false);
        setEmailData({ subject: '', body: '' });
    };

    const handleSendEmailFromPopup = async () => {
        const { subject, body } = emailData;
        if (!subject || !body) {
            enqueueSnackbar("Subject and body are required.", { variant: "warning" });
            return;
        }
        try {
            const newData = { subject, body, email: selectedEmail };
            const res = await SendPostRequest("sendEmailToHrPrejoinee", newData);
            if (res.status) {
                enqueueSnackbar(res.message, { variant: "success" });
                setEmailPopupOpen(false);
                setEmailData({ subject: '', body: '' });
            } else {
                enqueueSnackbar(res.message, { variant: "error" });
            }
        } catch (error) {
            console.log(error);
        }
    };


    const handleClickOpen = (preJoineeId) => {
        setOpen(true);
        const selectedPrjoine = rows.find((row) => row.id === preJoineeId);
        setFormData(selectedPrjoine);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        try {
            const dr = {
                doctor_id: formData.doctorId
            }
            const response = await SendPutRequest("preJoineeDoctorAssign", dr, formData.id);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                const updatedRows = rows.map((row) => {
                    if (row.id === formData.id) {
                        return { ...row, doctorId: formData.doctorId };
                    }
                    return row;
                }
                );
                setRows(updatedRows);
                setOpen(false);
            } else {
                enqueueSnackbar(`${response.message}`, { variant: "warning" });
            }

        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    };

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <AppBar
                        sx={{
                            width: { sm: `calc(100% - ${drawerWidth}px)` },
                            ml: { sm: `${drawerWidth}px` },
                            mt: { xs: '53px', sm: '64px' },
                            zIndex: 1099
                        }}
                        elevation={1}
                    >
                        <Toolbar sx={[styles.h, styles.bgWhite]}>
                            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Grid item xs={12} md={5}>
                                    <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                        Pre-Employment Exam Status
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <Box sx={{ mt: 8, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2}>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: "bold" }}>Temporary Code</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            id="filled-search"
                                            fullWidth
                                            name="tempCode"
                                            size="small"
                                            value={state.tempCode}
                                            onChange={(e) => handleTextChange("tempCode", e.target.value)}
                                            placeholder="Temporary Code"
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: "bold" }}>Name</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            value={state.name}
                                            placeholder="Name like"
                                            onChange={(e) => handleTextChange("name", e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: "bold" }}>From Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem>
                                                    <DatePicker
                                                        name="fromDate"
                                                        value={state.fromDate}
                                                        onChange={(date) => handleTextChange("fromDate", date)}
                                                    />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: "bold" }}>To Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem>
                                                    <DatePicker
                                                        name="toDate"
                                                        value={state.toDate}
                                                        onChange={(date) => handleTextChange("toDate", date)}
                                                    />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {/*<Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: "bold" }}>Location</Box>
                                    <Grid item xs={12} md={11}>
                                      <FormControl fullWidth>
                                            <Select
                                                name="location"
                                                value={state.location}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                {inputData.employeeWorkLocation.map((data, index) => (
                                                    <MenuItem sx={{ ml: 3 }} key={index} value={data.employee_work_location_id}>
                                                        {data.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid> */}
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: "bold" }}>Pre Employee</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="Filter"
                                                value={""}
                                                onChange={handleChange}
                                                displayEmpty
                                                disabled={true}
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={10}>
                                                    Ten
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={20}>
                                                    Twenty
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={30}>
                                                    Thirty
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={2} direction="row" justifyContent="center">
                                        <Button
                                            variant="contained"
                                            sx={{ textTransform: "none" }}
                                            startIcon={<SearchIcon />}
                                            disabled={isSearchQueryDisabled}
                                            onClick={onSearch}
                                        >
                                            Search
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            sx={{ textTransform: "none" }}
                                            startIcon={<HighlightOffIcon />}
                                            disabled={!isSearched}
                                            onClick={ClearFilter}
                                        >
                                            Clear
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            sx={{ textTransform: "none" }}
                                            startIcon={<NoteAddIcon />}
                                            onClick={handleExport}
                                            disabled={rowCount === 0}

                                        >
                                            Export
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>Show </Box>
                                        <TextField
                                            id="outlined-number"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            defaultValue='10'
                                            sx={{ width: '9ch' }}
                                            size='small'
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>entries </Box>
                                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />} onClick={handleExport}
                                            disabled={rowCount === 0}>Export</Button>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                        onChange={(e) => setState((prev) => ({ ...prev, search: e.target.value }))}
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Sr no.</StyledTableCell>
                                            <StyledTableCell>Temporary Code</StyledTableCell>
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell>Email</StyledTableCell>
                                            <StyledTableCell>Report Status</StyledTableCell>
                                            <StyledTableCell>Fitness Status</StyledTableCell>
                                            <StyledTableCell>Fitness Reject Reason</StyledTableCell>
                                            <StyledTableCell>Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {rowCount ? (
                                            rows?.map((row, index) => (
                                                <StyledTableRow
                                                    key={row.temporaryCode}
                                                    sx={styles.TableRow}
                                                >
                                                    <StyledTableCell> {index + 1} </StyledTableCell>
                                                    <StyledTableCell> {row?.temporaryCode} </StyledTableCell>
                                                    <StyledTableCell>{row?.name}</StyledTableCell>
                                                    <StyledTableCell>{row?.email}</StyledTableCell>
                                                    <StyledTableCell>{checkStatus(row?.reportStatus)}</StyledTableCell>
                                                    <StyledTableCell>{checkFitnessStatus(row?.fitnessStatus)}</StyledTableCell>
                                                    <StyledTableCell>{row?.fitnessRejectComment}</StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid container spacing={0.1} justifyContent="flex-start" alignItems="center">
                                                            <Grid item>
                                                                <Button variant="contained" sx={{ textTransform: "none", mb: 2 }} onClick={() => navigate(`/dashboard/hr/pre-joinee-management/pre-employment-exam-view/${row.id}`)}>View</Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <Button variant="contained" sx={{ textTransform: "none", mb: 2 }} onClick={() => handleClickOpen(row.id)} disabled={
                                                                    !row?.reportStatus || row?.fitnessStatus === '1'
                                                                }>Assign Doctor</Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <Button variant="contained" sx={{ textTransform: "none", mb: 2 }} onClick={() => handleSendEmail(row.email)}>Send Email</Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <Button variant="contained" sx={{ textTransform: "none" }} onClick={() => handleSendLink(row.email, row.id)}>Send Link</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        ) : (
                                            <StyledTableRow sx={styles.TableRow}>
                                                <StyledTableCell colSpan={8} align="center">
                                                    No Records Found
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <PaginationControls
                                pagination={pagination}
                                rowCount={rowCount}
                                setPagination={setPagination}
                            />

                            <EmailPopup
                                open={emailPopupOpen}
                                handleClose={handleClosePopup}
                                handleSendEmail={handleSendEmailFromPopup}
                                setEmailData={setEmailData}
                                emailData={emailData}
                            />
                            <Dialog open={open} fullWidth maxWidth='sm' onClose={handleClose}>
                                <DialogTitle> Add Doctor</DialogTitle>
                                <DialogContent>
                                    <Table size="large">
                                        <thead>
                                            <tr>
                                                <th align='left'>Name</th>
                                                <th align='left' style={{
                                                    position: 'relative',
                                                    left: '-90px',
                                                }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <RadioGroup
                                                name="doctor_id"
                                                value={formData?.doctorId}
                                                onChange={(e) =>
                                                    setFormData((prev) => ({
                                                        ...prev,
                                                        doctorId: e.target.value,
                                                    }))
                                                }
                                            >
                                                {doctorsListing?.map((row, index) => (
                                                    <>
                                                        <tr key={index}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                            }}
                                                        >
                                                            <td align='left'>{row?.full_name}</td>
                                                            <td align='right'>
                                                                <FormControlLabel
                                                                    value={row?.id}
                                                                    control={<Radio />}
                                                                    label=""
                                                                />
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))}
                                            </RadioGroup>
                                        </tbody>
                                    </Table>
                                </DialogContent>
                                <DialogActions>
                                    <Button sx={{ textTransform: "none" }} onClick={handleClose}>Cancel</Button>
                                    <Button sx={{ textTransform: "none" }} onClick={handleSubmit} type="save">Save</Button>
                                </DialogActions>
                            </Dialog>

                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Send Pre-Employment Exam Link"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to send the pre-employment exam link to {selectedEmail}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmSendLink} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default PreEmploymentExam;