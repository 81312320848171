import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import PaginationControls from 'component/Comman/paginationControls';
import sharedImports from 'component/Comman/sharedImports';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteButton from 'component/Comman/DeleteButton/DeleteButton';
import styles from 'css/styles';
import { StyledTableCell, StyledTableRow } from 'helper/employee/employeeHelpers';
import PageSizeSelector from 'component/Comman/PageSizeSelector';
import {CommanAppBarWithAddButton} from 'component/Comman/commanAppBar';
import { SendGetRequest, SendDeleteRequest } from '../../../../api/config/ApiCall';
import { useNavigate } from 'react-router-dom';

const {
    Box,
    Grid,
    TextField,
    UnfoldMoreOutlinedIcon,
    TableBody,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
} = sharedImports;

export default function PackagesListing() {
    const [data, setData] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    // Table state
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    // Fetch listing data
    const fetchInfo = async () => {
        try {
            const data = {
              page: pagination.pageIndex + 1,
              per_page: pagination.pageSize,
              globalFilter: globalFilter,
              sorting: JSON.stringify(sorting ?? []),
            };

            const response = await SendGetRequest("listAllPackagesUrl", data);
            
            if (response.status) {
                const { data, totalCount } = response?.data;                
                setData(data);
                setRowCount(totalCount);
            } else {
              enqueueSnackbar(response.message, { variant: "error" });
            }
        } catch (err) {
            console.error("Something went wrong:", err);
        }
    };

    // Handle sorting
    const handleSort = (columnId) => {
        setSorting((prevSorting) => {
            const existingSort = prevSorting.find(sort => sort.package_id === columnId);
            if (existingSort) {
                if (existingSort.desc === false) {
                    return [{ package_column: columnId, desc: true }];
                } else {
                    return [];
                }
            } else {
                return [{ package_column: columnId, desc: false }];
            }
        });
        fetchInfo();
    };

    // Delete row
    const handleDeleteRow = async (id) => {
        try {
            const response = await SendDeleteRequest("listAllPackagesUrl", id);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                fetchInfo();
            } else {
                enqueueSnackbar(response.message, { variant: "warning" });
            }
        } catch (error) {
            enqueueSnackbar('An error occurred while deleting the record', { variant: "error" });
        }
    };

    // Fetch data on changes
    useEffect(() => {
        fetchInfo();
    }, [globalFilter, pagination.pageIndex, pagination.pageSize, sorting]);

    return (
        <Box>
            <CommanAppBarWithAddButton appBarTitle="Packages Listing" handleUrl="/dashboard/pre-joinee-management/packages/create"/>
            <Box sx={{ mt: 7, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                <Grid container spacing={2} justifyContent='space-between' sx={{ mb: 2 }}>
                    <Grid item>
                        <PageSizeSelector pageSize={pagination.pageSize} setPagination={setPagination} />
                    </Grid>
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                        <TextField
                            id="filled-search"
                            type="search"
                            size='small'
                            value={globalFilter}
                            onChange={(e) => setGlobalFilter(e.target.value)}
                        />
                    </Grid>
                </Grid>

                <TableContainer component={Paper} sx={{ mb: 2 }}>
                    <Table aria-label="Package listing">
                        <TableHead>
                            <TableRow sx={styles.EmployeeMasterSetupTableRow}>
                                <StyledTableCell onClick={() => handleSort('name')}>
                                    Name
                                    <UnfoldMoreOutlinedIcon />
                                </StyledTableCell>
                                <StyledTableCell onClick={() => handleSort('min_age')}>
                                    Min Age
                                    <UnfoldMoreOutlinedIcon />
                                </StyledTableCell>
                                <StyledTableCell onClick={() => handleSort('max_age')}>
                                    Max Age
                                    <UnfoldMoreOutlinedIcon />
                                </StyledTableCell>
                                <StyledTableCell onClick={() => handleSort('description')}>
                                    Description
                                    <UnfoldMoreOutlinedIcon />
                                </StyledTableCell>
                                <StyledTableCell>Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rowCount === 0 ? (
                                <StyledTableRow>
                                    <StyledTableCell colSpan={5} align="center">
                                        No data found
                                    </StyledTableCell>
                                </StyledTableRow>
                            ) : (
                                data.map((row, index) => (
                                    <StyledTableRow key={index} sx={styles.EmployeeMasterSetupTableRow}>
                                        <StyledTableCell>{row.name}</StyledTableCell>
                                        <StyledTableCell>{row.min_age}</StyledTableCell>
                                        <StyledTableCell>{row.max_age}</StyledTableCell>
                                        <StyledTableCell>{row.description}</StyledTableCell>
                                        <StyledTableCell>
                                            <EditOutlinedIcon onClick={() => navigate(`update/${row.package_id}`)} />
                                            <DeleteButton
                                                id={row.package_id}
                                                fieldName={row.name}
                                                handleDeleteRow={() => handleDeleteRow(row.package_id)}
                                                titleName="Package"
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <PaginationControls
                    pagination={pagination}
                    rowCount={rowCount}
                    setPagination={setPagination}
                />
            </Box>
        </Box>
    );
}
