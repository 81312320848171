export const PermissionMenusArray = {
    "HR": {
      label: "HR",
      action: "HR",
      name: "HR",
      submenu: [
        {
          label: "Employee Management",
          action: "employee-management",
          name: "Employee Management",
          submenu: [
            { label: "Employee Custom Search", action: "hr/employee-management/employee-custom-search" },
            { label: "Employee Search", action: "hr/employee-management/employee-search" },
            { label: "Employee Transfer In", action: "hr/employee-management/employee-transfer-in" },
            { label: "Employee Transfer Out", action: "hr/employee-management/employee-transfer-out" },
            { label: "First Aid Training Log", action: "hr/employee-management/first-aid-training-log" },
            { label: "Search All Employee", action: "hr/employee-management/search-all-employee" },
            { label: "Upload Employee Photo", action: "hr/employee-management/upload-employee-photo" },
            { label: "Employee All Detail", action: "hr/employee-management/employee-all-detail" },
            { label: "Employee Data Update", action: "hr/employee-management/employee-data-update" },
            { label: "Location Department Group", action: "hr/employee-management/location-department-group" },
            { label: "Location Department Head", action: "hr/employee-management/location-department-head" },
            { label: "Employee Document Search", action: "hr/employee-management/employee-document-search" },
            { label: "First Aid Dashboard", action: "hr/employee-management/first-aid-dashboard" },
          ]
        },
        {
          label: "Master",
          action: "master",
          submenu: [
            { label: "Employee Master Setup", action: "hr/employee-management/employee-master-setup" },
            { label: "HR Master Setup", action: "diagnostic/report/cost-report" }
          ]
        },
        {
          label: "Report",
          action: "report",
          submenu: [
            { label: "All Employee List", action: "hr/report/all-employee-list" },
          ]
        },
        {
          label: "Pre-join Management",
          action: "pre-join-management",
          submenu: [
            { label: "Import Pre Joinee", action: "hr/pre-joinee-management/import-pre-joinee" },
          ]
        },
        {
          label: "Other Master",
          action: "other-master",
          submenu: [
            { label: "Import Other Master", action: "hr/other-master/import-other-master" }
          ]
        }
      ]
    },
    "Diagnostic": {
      label: "Diagnostic",
      action: "diagnostic",
      name: "Diagnostic",
      submenu: [
        {
          label: "Diagnostic",
          action: "diagnostic-sub",
          submenu: [
            { label: "Test Listing", action: "diagnostic/test-listing" },
            { label: "Test Analysis", action: "diagnostic/test-analysis" },
            { label: "Employee Analysis", action: "diagnostic/employee-analysis" }
          ]
        },
        {
          label: "Reports",
          action: "reports",
          submenu: [
            { label: "Service Cost Analysis", action: "diagnostic/report/service-cost-analysis" },
            { label: "Test Report", action: "diagnostic/report/test-report" },
            { label: "Cost Report", action: "diagnostic/report/cost-report" },
            { label: "Test List Report", action: "diagnostic/report/test-list-report" }
          ]
        },
        {
          label: "Master",
          action: "master",
          submenu: [
            { label: "Test Setup", action: "diagnostic/master/test-setup" },
            { label: "Test Parameter Update", action: "diagnostic/master/test-parameter-update" },
            { label: "Diagnostic Master Setup", action: "diagnostic/master/diagnostic-master-setup" },
          ]
        }
      ]
    },
    "Clinical": {
      label: "Clinical",
      action: "admin/user",
      name: "Clinical",
      submenu: [
        {
          label: "Doctor",
          action: "doctor",
          submenu: [
            { label: "Import Doctor", action: "clinical/doctor/import-doctor" },
            { label: "Doctor Master Setup", action: "clinical/doctor/doctor-master-setup" }
          ]
        },
        {
          label: "Medical Review",
          action: "medical-review",
          submenu: [
            { label: "Document Search", action: "clinical/medical-review/document-search" },
            { label: "Medical History", action: "clinical/medical-review/medical-history" },
            { label: "Medical Review", action: "clinical/medical-review" }
          ]
        }
      ]
    },
    "Safety": {
      label: "Safety",
      action: "safety",
      name: "Safety",
      submenu: [
        { label: "Form Accident With Injury", action: "safety/form-accident-with-injury" },
        { label: "Form Accident Without Injury", action: "safety/form-accident-without-injury" },
        { label: "Incident Listing", action: "safety/incident-listing" },
        { label: "Incident Log", action: "safety/incident-log" },
        { label: "Injuries Report", action: "safety/injuries-report" }
      ]
    },
    "Support": {
      label: "Support",
      action: "support",
      name: "Support",
      submenu: [
        {
          label: "Expense",
          action: "expense",
          submenu: [
            { label: "Expense", action: "support/expense" },
            { label: "Expense Report", action: "support/expense/expense-report" }
          ]
        },
        {
          label: "Ambulance",
          action: "ambulance",
          submenu: [
            { label: "Ambulance Log", action: "support/ambulance/ambulance-log" },
            { label: "Ambulance Check", action: "support/ambulance/ambulance-check" },
            { label: "Vehicle Maintenance", action: "support/ambulance/vehicle-maintenance" },
            { label: "Vehicle Report", action: "support/ambulance/vehicle-report" },
            { label: "Ambulance Master Setup", action: "support/ambulance/ambulance-master-setup" }
          ]
        },
        {
          label: "Asset Management",
          action: "asset-management",
          submenu: [
            { label: "Asset Maintenance", action: "support/asset-management/asset-maintenance" },
            { label: "Asset Audit", action: "support/asset-management/asset-audit" },
            { label: "Asset Adjustment", action: "support/asset-management/asset-adjustment" },
            { label: "Asset Maintenance Schedule", action: "support/asset-management/asset-maintenance-schedule" },
            { label: "Asset Operational Status", action: "support/asset-management/asset-operational-status" },
            { label: "Asset Check", action: "support/asset-management/asset-check" },
            { label: "Asset Movement", action: "support/asset-management/asset-movement" },
            { label: "Asset Restoration", action: "support/asset-management/asset-restoration" },
            { label: "Asset Check Log", action: "support/asset-management/asset-check-log" },
            { label: "Asset Incharge", action: "support/asset-management/asset-incharge" },
            { label: "Asset Issue Return", action: "support/asset-management/asset-issue-return" },
            { label: "Asset Calibration Log", action: "support/asset-management/asset-calibration-log" },
            { label: "Asset Setup", action: "support/asset-management/asset-setup" },
            { label: "Asset Management Report", action: "support/asset-management/asset-management-report" },
            { label: "Asset Management Master Setup", action: "support/asset-management/asset-management-master-setup" }
          ]
        },
        {
          label: "Bio Medical Waste",
          action: "bio-medical-waste",
          submenu: [
            { label: "Bio Medical Waste", action: "support/bio-medical-waste" },
            { label: "BioMedical Master Setup", action: "support/bio-medical-waste/bio-medical-master-setup" }
          ]
        },
        {
          label: "Claim",
          action: "claim",
          submenu: [
            { label: "Claim Analysis", action: "support/claim/claim-analysis" },
            { label: "Claim Master Setup", action: "support/claim/claim-master-setup" }
          ]
        },
        {
          label: "Health Connect",
          action: "health-connect",
          submenu: [
            { label: "Health Connect", action: "support/health-connect" },
            { label: "Health Connect Report", action: "support/health-connect/health-connect-report" }
          ]
        },
        {
          label: "Wellness Activity",
          action: "wellness-activity",
          submenu: [
            { label: "Wellness Activity Report", action: "support/wellness-activity/wellness-activity-report" }
          ]
        },
        {
          label: "Communication",
          action: "communication",
          submenu: [
            { label: "Ticket Master", action: "support/communication/ticket-master" }
          ]
        },
        {
          label: "Canteen",
          submenu: [
            { label: "Canteen Inspection Detail", action: "support/canteen/canteen-inspection-detail" },
            { label: "Canteen Employee Assign", action: "support/canteen/canteen-employee-assign" },
            { label: "Pantry Inspection Single Entry", action: "support/canteen/pantry-inspection-single-entry" },
            { label: "Pantry Inspection Bulk", action: "support/canteen/pantry-inspection-bulk" },
            { label: "Checking Parameter Item", action: "support/canteen/checking-parameter-item" },
            { label: "Employee Hygiene Parameter", action: "support/canteen/employee-hygiene-parameter" },
            { label: "Pantry Item Allocation", action: "support/canteen/pantry-item-allocation" },
            { label: "Inspection Report", action: "support/canteen/inspection-report" },
            { label: "Import Pantry", action: "support/canteen/import-pantry" },
            { label: "Import Canteen", action: "support/canteen/import-canteen" },
            { label: "Canteen Master Setup", action: "support/canteen/canteen-master-setup" }
          ]
        },
        {
          label: "Health Library",
          submenu: [
            { label: "Type Master", action: "support/health-library/type-master" },
            { label: "Tags Master", action: "support/health-library/tags-master" },
            { label: "Category Master", action: "support/health-library/category-master" },
            { label: "Sub Category Master", action: "support/health-library/sub-category-master" },
            { label: "Health Document Upload", action: "support/health-library/health-document-upload" }
          ]
        }
      ]
    },
    "MIS": {
      label: "MIS",
      action: "MIS",
      name: "MIS",
      submenu: [
        {
          label: "MIS",
          action: "MIS-sub",
          submenu: [
            { label: "Compare Parameter", action: "mis/compare-parameter" },
            { label: "Examination All Location", action: "mis/examination-all-location" },
            { label: "MIS Dashboard", action: "mis/mis-dashboard" },
            { label: "MIS Examination Dashboard", action: "mis/mis-examination-dashboard" },
            { label: "MIS Opd Dashboard", action: "mis/mis-opd-dashboard" },
            { label: "MIS Report", action: "mis/mis-report" },
          ]
        },
        {
          label: "Diagnosis Tracker",
          action: "diagnosis-tracker",
          submenu: [
            { label: "Diagnosis Analysis", action: "mis/diagnosis-tracker/diagnosis-analysis" },
            { label: "Diagnosis Search", action: "mis/diagnosis-tracker/diagnosis-search" }
          ]
        }
      ]
    },
    "Admin Tool": {
      label: "Admin Tool",
      action: "admin-tool",
      name: "Admin Tool",
      submenu: [
        {
          label: "Security",
          action: "security",
          submenu: [
            { label: "Change Location", action: "admin-tool/security/change-location" },
            { label: "Change Password", action: "admin-tool/security/change-password" },
            { label: "My Dashboard", action: "admin-tool/security/my-dashboard" },
            { label: "User Preference", action: "admin-tool/security/user-preference" },
            { label: "View Report", action: "admin-tool/security/view-report" }
          ]
        },
        {
          label: "Utilities",
          action: "utilities",
          submenu: [
            { label: "Import Asset", action: "admin-tool/utilities/import-asset" },
            { label: "Import Contract Employee", action: "admin-tool/utilities/import-contract-employee" },
            { label: "Import Employee", action: "admin-tool/utilities/import-employee" },
            { label: "Import Dependent", action: "admin-tool/utilities/import-dependent" },
            { label: "Import Stock Item", action: "admin-tool/utilities/import-stock-item" },
            { label: "Import Examination All", action: "admin-tool/utilities/import-examination-all" },
            { label: "Import Location", action: "admin-tool/utilities/import-location" },
            { label: "Import Users", action: "admin-tool/utilities/import-users" },
            { label: "Import Document", action: "admin-tool/utilities/import-document" },
            { label: "Update Employee Data", action: "admin-tool/utilities/update-employee-data" },
            { label: "Update Stock Item Data", action: "admin-tool/utilities/update-stock-item-data" }
          ]
        }
      ]
    },
    "Examination": {
      label: "Examination",
      action: "examination",
      name: "Examination",
      submenu: [
          {
          label: "Examination",
          action: "examination-sub",
          submenu: [
            { label: "Examination All", action: "examination/examination-all" },
            { label: "Letter Issue", action: "examination/letter-issue" },
            { label: "Unfit Listing", action: "examination/unfit-listing" },
            { label: "Fitness Certificate", action: "examination/fitness-certificate" },
            { label: "Periodic Dashboard", action: "examination/periodic-dashboard" },
            { label: "Examination Counselling", action: "examination/examination-counselling" }
          ]
        },
        {
          label: "Examination Report",
          action: "examination-report",
          submenu: [
            { label: "Personal Detail Report", action: "examination/examination-report/personal-detail-report" },
            { label: "Clinical Examination Report", action: "examination/examination-report/clinical-examination-report" },
            { label: "Compliance Report", action: "examination/examination-report/compliance-report" },
            { label: "Health Card Report", action: "examination/examination-report/health-card-report" },
            { label: "Pulmonary Report", action: "examination/examination-report/pulmonary-report" },
            { label: "Hearing Report", action: "examination/examination-report/hearing-report" },
            { label: "Vision Report", action: "examination/examination-report/vision-report" },
            { label: "Diagnosis Report", action: "examination/examination-report/diagnosis-report" },
            { label: "Examination Status Report", action: "examination/examination-report/examination-status-report" },
            { label: "Other Examination Report", action: "examination/examination-report/other-examination-report" },
            { label: "Export Test Report", action: "examination/examination-report/export-test-report" },
            { label: "Export Clinical Report", action: "examination/examination-report/export-clinical-report" },
            { label: "Export Vital Report", action: "examination/examination-report/export-vital-report" },
            { label: "Export Audiometry Report", action: "examination/examination-report/export-audiometry-report" },
            { label: "Export Personal Detail Report", action: "examination/examination-report/export-personal-detail-report" },
            { label: "Export Reports", action: "examination/examination-report/export-reports" },
            { label: "Examination Summary Report", action: "examination/examination-report/examination-summary-report" }
          ]
        },
        {
          label: "Freeze Periodic List",
          submenu: [
            { label: "Examination Planning", action: "examination/freeze-periodic-list/examination-planning" },
            { label: "Freeze Periodic All List", action: "examination/freeze-periodic-list/freeze-periodic-all-list" },
            { label: "Freeze Periodic List", action: "examination/freeze-periodic-list/freeze-periodic-list" },
            { label: "Test Due", action: "examination/freeze-periodic-list/test-due" },
            { label: "Criteria Group", action: "examination/freeze-periodic-list/criteria-group" },
            { label: "Search Criteria Group", action: "examination/freeze-periodic-list/search-criteria-group" },
            { label: "Employee Freeze Status", action: "examination/freeze-periodic-list/employee-freeze-status" }
          ]
        }
      ]
    },
    "OPD": {
      label: "OPD",
      action: "OPD",
      name: "OPD",
      submenu: [
        { label: "OPD List", action: "opd/opd-list" },
        { label: "OPD Dashboard", action: "opd/opd-dashboard" },
        { label: "Consolidated Cost", action: "opd/consolidated-cost" },
        { label: "Case Report", action: "opd/case-report" },
        { label: "Case Summary Report", action: "opd/case-summary-report" },
        { label: "Referral List", action: "opd/referral-list" }
      ]
    },
    "Appointment Module": {
      label: "Appointment Module",
      action: "appointment-module",
      name: "Appointment Module",
      submenu: [
        { label: "Patient Register", action: "appointment-module/patient-register" },
        { label: "Visiting Doctor Appointment", action: "appointment-module/visiting-doctor-appointment" },
        { label: "Patient Register Analysis", action: "appointment-module/patient-register-analysis" },
        { label: "Appointment Master Setup", action: "appointment-module/appointment-master-setup" }
      ]
    },
    "My Details": {
      label: "My Details",
      action: "my-details",
      name: "My Details",
      submenu: [
        {
          label: "Employee Center",
          submenu: [
            { label: "Cashless", action: "my-details/employee-center/cashless" },
            { label: "My Health", action: "my-details/employee-center/my-health" },
            { label: "Affiliated Service", action: "my-details/employee-center/affiliated-service" },
            { label: "Health Content", action: "my-details/employee-center/health-content" },
            { label: "MIS Examination", action: "my-details/employee-center/mis-examination" },
            { label: "Privilege Health Service", action: "my-details/employee-center/privilege-health-service" }
          ]
        }
      ]
    },
    "Stock": {
      label: "Stock",
      action: "stock",
      name: "Stock",
      submenu: [
        {
          label: "Stock",
          action: "stock-sub",
          submenu: [
            { label: "Stock Status", action: "stock/stock-status" },
            { label: "Stock Batch", action: "stock/stock-batch" },
            { label: "Stock Enquiry", action: "stock/stock-enquiry" },
            { label: "Stock Inward", action: "stock/stock-inward" },
            { label: "Stock Inward Request", action: "stock/stock-inward-request" },
            { label: "Stock Inward Return", action: "stock/stock-inward-return" },
            { label: "Stock Issue", action: "stock/stock-issue" },
            { label: "Stock Issue Request", action: "stock/stock-issue-request" },
            { label: "Stock Issue Return", action: "stock/stock-issue-return" },
            { label: "Stock Issue Bulk", action: "stock/stock-issue-bulk" },
            { label: "Stock Issue Count", action: "stock/stock-issue-count" },
            { label: "Other Stock Batch", action: "stock/other-stock-batch" },
            { label: "Other Stock Issue", action: "stock/other-stock-issue" },
            { label: "Stock Adjustment", action: "stock/stock-adjustment" },
            { label: "Stock Transfer", action: "stock/stock-transfer" },
            { label: "Stock Transfer Request", action: "stock/stock-transfer-request" },
            { label: "Stock Transaction", action: "stock/stock-transaction" },
            { label: "Report", action: "stock/report" },
            { label: "Import Stock Item Restrictive", action: "stock/import-stock-item-restrictive" },
            { label: "Stock Reset", action: "stock/stock-reset" },
            { label: "Stock Discard", action: "stock/stock-discard" }
          ]
        },
        {
          label: "First Aid",
          action: "first-aid",
          submenu: [
            { label: "First Aid Inspection", action: "stock/first-aid/first-aid-inspection" },
            { label: "Import First Aid", action: "stock/first-aid/import-first-aid" }
          ]
        },
        {
          label: "Pharmacy",
          submenu: [
            { label: "Nursing Listing", action: "stock/pharmacy/nursing-listing" },
            { label: "Pharmacy Queue Listing", action: "stock/pharmacy/pharmacy-queue-listing" },
            { label: "Pharmacy Issue Return", action: "stock/pharmacy/pharmacy-issue-return" }
          ]
        },
        {
          label: "Master",
          submenu: [
            { label: "Sub Group For In Whole Unit", action: "stock/master/sub-group-for-in-whole-unit" },
            { label: "Stock Item Planning", action: "stock/master/stock-item-planning" },
            { label: "Stock Item Parameter Update", action: "stock/master/stock-item-parameter-update" },
            { label: "Stock Item Detail Update", action: "stock/master/stock-item-detail-update" }
          ]
        }
      ]
    }
};  
  