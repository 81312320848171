import { useState, useEffect } from 'react';
import sharedImports from '../../../Comman/sharedImports';
import { useSnackbar } from 'notistack';
import styles from 'css/styles';
import { SendGetRequest, SendPostRequest } from 'api/config/ApiCall';
const {
    React,
    Box,
    Grid,
    Button,
    Dialog,
    Toolbar,
    TextField,
    Typography,
    DialogTitle,
    DialogContent,
    DialogActions,
    HighlightOffIcon,
    SaveOutlinedIcon,
    DialogContentText,
    FormatListBulletedIcon,
} = sharedImports;

const initialState = {
    host: '',
    port: '',
    email: '',
    password: '',
    map_api_key: ''
};
export default function AdminPanelSettings(props) {
    const [open, setOpen] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [formData, setFormData] = useState(initialState);
    const handleClickOpen = (newValue) => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        fetchAdminSettings();
    }, []);
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await SendPostRequest("configureAdminSettings", formData);
            console.log('response', response.data);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" })
                fetchAdminSettings();
            } else {
                enqueueSnackbar(response.message, { variant: "error" })
            }
        } catch (error) {
            enqueueSnackbar("An error occurred. Please try again.", { variant: "error" });
        }
    };
    const fetchAdminSettings = async () => {
        try {
            const response = await SendGetRequest("fetchAdminSettingData", {});
            if (response.status) {
                const newFormData = {};
                response.data.forEach(setting => {
                    newFormData[setting.key] = setting.value;
                });
                setFormData(newFormData);
            } else {
                enqueueSnackbar(response.message, { variant: "error" })
            }
        } catch (error) {
            enqueueSnackbar("An error occurred. Please try again.", { variant: "error" });
        }
    }
    const clearAllVal = () => {
        setFormData(initialState);
    }
    return (
        <Box sx={styles.root}>
            <Toolbar sx={[styles.h, styles.bgWhite]}>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item xs={12} md={5.5}>
                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                            Admin Panel Settings
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5.5}>
                        <Button variant='contained' startIcon={<SaveOutlinedIcon />} sx={{ mr: 2, m: 1, textTransform: 'none' }} onClick={handleSubmit}> Save </Button>
                        <Button variant='outlined' startIcon={<HighlightOffIcon />} onClick={clearAllVal} sx={{ textTransform: 'none' }}> Clear </Button>
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <React.Fragment>
                            <Button
                                variant="contained"
                                startIcon={<FormatListBulletedIcon />}
                                onClick={handleClickOpen}
                                item xs={12} md={1} sx={{ textTransform: 'none', m: 1 }}>
                                List
                            </Button>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    component: 'form',
                                    onSubmit: (event) => {
                                        event.preventDefault();
                                        const formData = new FormData(event.currentTarget);
                                        const formJson = Object.fromEntries(formData.entries());
                                        const email = formJson.email;
                                        console.log(email);
                                        handleClose();
                                    },
                                }}
                            >
                                <DialogTitle>Subscribe</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        To subscribe to this website, please enter your email address here. We
                                        will send updates occasionally.
                                    </DialogContentText>
                                    <TextField
                                        autoFocus
                                        required
                                        margin="dense"
                                        id="name"
                                        name="email"
                                        label="Email Address"
                                        type="email"
                                        fullWidth
                                        variant="standard"
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button sx={{ textTransform: 'none' }} onClick={handleClose}>Cancel</Button>
                                    <Button sx={{ textTransform: 'none' }} type="submit">Subscribe</Button>
                                </DialogActions>
                            </Dialog>
                        </React.Fragment>
                    </Grid>
                </Grid>
            </Toolbar>
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Typography variant="h6" noWrap component="div" sx={{ fontWeight: 'bold' }}>
                        Email Settings
                    </Typography>
                    <Grid container spacing={1} sx={{ mt: 2 }}>
                        <Grid item xs={12} md={3}>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    fullWidth
                                    size="small"
                                    name="host"
                                    value={formData.host}
                                    onChange={handleChange}
                                    placeholder="Enter host"
                                    type='text'
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    fullWidth
                                    size="small"
                                    name="port"
                                    type="number"
                                    value={formData.port}
                                    onChange={handleChange}
                                    placeholder="Enter port"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    fullWidth
                                    size="small"
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Enter email"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    fullWidth
                                    size="small"
                                    name="password"
                                    type="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    placeholder="Email password"
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Typography variant="h6" noWrap component="div" sx={{ fontWeight: 'bold', mt: 4 }}>
                        Google Map Api Settings
                    </Typography>
                    <Grid container spacing={1} sx={{ mt: 2 }}>
                        <Grid item xs={12} md={3}>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    fullWidth
                                    size="small"
                                    name="map_api_key"
                                    value={formData.map_api_key}
                                    onChange={handleChange}
                                    placeholder="Enter google map api key"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}