import sharedImports from '../../Comman/sharedImports';
const {
    React,
    Box,
    Link,
    clsx,
    alpha,
    styled,
    useTheme,
    Typography,
    useTreeItem,
    TreeItem2Icon,
    TreeItem2Root,
    ArrowRightIcon,
    SimpleTreeView,
    TreeItem2Content,
    ArrowDropDownIcon,
    TreeItem2Provider,
    SaveAsOutlinedIcon,
    TreeItem2IconContainer,
    TreeItem2GroupTransition,
    ArrowRightAltOutlinedIcon,
    MedicalServicesOutlinedIcon,
} = sharedImports;
const security = [
    { id: 21, label: 'User Role', to: 'admin-tool/security/user-role' },
    { id: 22, label: 'User Permission', to: 'admin-tool/security/user-permission' },
    { id: 3, label: 'Change Location', to: 'admin-tool/security/change-location' },
    { id: 4, label: 'Change Password', to: 'admin-tool/security/change-password' },
    { id: 5, label: 'My DashBoard', to: 'admin-tool/security/my-dashboard' },
    { id: 6, label: 'User Perference', to: 'admin-tool/security/user-preference' },
    { id: 7, label: 'View Report', to: 'admin-tool/security/view-report' },
    { id: 23, label: 'Settings', to: 'admin-tool/security/admin-setting' },
]
const utilities = [
    { id: 9, label: 'Import Asset', to: 'admin-tool/utilities/import-asset' },
    { id: 10, label: 'Import Contract Employee', to: 'admin-tool/utilities/import-contract-employee' },
    { id: 11, label: 'Import Employee', to: 'admin-tool/utilities/import-employee' },
    { id: 12, label: 'Import Dependent', to: 'admin-tool/utilities/import-dependent' },
    { id: 13, label: 'Import Stock Item', to: 'admin-tool/utilities/import-stock-item' },
    { id: 14, label: 'Import Examination All', to: 'admin-tool/utilities/import-examination-all' },
    { id: 15, label: 'Import Location', to: 'admin-tool/utilities/import-location' },
    { id: 16, label: 'Import Users', to: 'admin-tool/utilities/import-users' },
    { id: 17, label: 'Import Document', to: 'admin-tool/utilities/import-document' },
    { id: 18, label: 'Update Employee Data', to: 'admin-tool/utilities/update-employee-data' },
    { id: 19, label: 'Update Stock Item Data', to: 'admin-tool/utilities/update-stock-item-data' },
]
const CustomTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));
const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
    marginBottom: theme.spacing(0.3),
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.expanded': {
        fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '&.focused, &.selected, &.selected.focused': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        color: '#000000',
    },
}));
const CustomTreeItemIconContainer = styled(TreeItem2IconContainer)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));
const CustomTreeItemGroupTransition = styled(TreeItem2GroupTransition)(
    ({ theme }) => ({
        marginLeft: 0,
        [`& .content`]: {
            paddingLeft: theme.spacing(2),
        },
    }),
);
const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    const theme = useTheme();
    const {
        id,
        itemId,
        label,
        disabled,
        children,
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        colorForDarkMode,
        bgColorForDarkMode,
        ...other
    } = props;
    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getLabelProps,
        getGroupTransitionProps,
        status,
    } = useTreeItem({ id, itemId, children, label, disabled, rootRef: ref });
    const style = {
        '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
        '--tree-view-bg-color':
            theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
    };

    return (
        <TreeItem2Provider itemId={itemId}>
            <CustomTreeItemRoot {...getRootProps({ ...other, style })}>
                <CustomTreeItemContent
                    {...getContentProps({
                        className: clsx('content', {
                            expanded: status.expanded,
                            selected: status.selected,
                            focused: status.focused,
                        }),
                    })}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            alignItems: 'center',
                            p: 0,
                            pr: 0,
                        }}
                    >
                        <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                        <Typography
                            {...getLabelProps({
                                variant: 'body2',
                                sx: { display: 'flex', fontWeight: 'inherit', flexGrow: 1 },
                            })}
                        />
                        <Typography variant="caption" color="inherit">
                            {labelInfo}
                        </Typography>
                    </Box>
                    <CustomTreeItemIconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon status={status} />
                    </CustomTreeItemIconContainer>
                </CustomTreeItemContent>
                {children && (
                    <CustomTreeItemGroupTransition {...getGroupTransitionProps()} />
                )}
            </CustomTreeItemRoot>
        </TreeItem2Provider>
    );
});

function EndIcon() {
    return <div style={{ width: 24 }} />;
}

export default function AdminTool() {
    return (
        <SimpleTreeView
            aria-label="Admin Tool"
            defaultExpandedItems={['3']}
            defaultSelectedItems="5"
            slots={{
                expandIcon: ArrowRightIcon,
                collapseIcon: ArrowDropDownIcon,
                endIcon: EndIcon,
            }}
            sx={{ flexGrow: 1, maxWidth: 400 }}
        >
            <CustomTreeItem itemId="1" label="Admin Tool" labelIcon={MedicalServicesOutlinedIcon}>
                <CustomTreeItem itemId="2" label="Security" labelIcon={SaveAsOutlinedIcon}>
                    {security.map((item) => (
                        <Link to={item.to} key={item.id}>
                            <CustomTreeItem
                                itemId={item.id}
                                label={item.label}
                                labelIcon={ArrowRightAltOutlinedIcon}
                                color="#000000"
                                bgColor="#e8f0fe"
                                colorForDarkMode="#B8E7FB"
                                bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                            />
                        </Link>
                    ))}
                </CustomTreeItem>
                <CustomTreeItem itemId="8" label="Utilities" labelIcon={SaveAsOutlinedIcon}>
                    {utilities.map((item) => (
                        <Link to={item.to} key={item.id}>
                            <CustomTreeItem
                                itemId={item.id}
                                label={item.label}
                                labelIcon={ArrowRightAltOutlinedIcon}
                                color="#000000"
                                bgColor="#e8f0fe"
                                colorForDarkMode="#B8E7FB"
                                bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                            />
                        </Link>
                    ))}
                </CustomTreeItem>
            </CustomTreeItem>
        </SimpleTreeView>
    );
}