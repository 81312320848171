import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";

// Initialize pdfMake with fonts
pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : {};
const LabInvestigations = [
  {
    id: 1,
    test: "CBC :",
    parameter: "Hemoglobin\nRBC Count\nWBC Count\nPlatelets",
    result: "14.2\n5.2\n7500\n250000",
    resultMeasurementType: "g/dl\nmillion/μL\n/cmm\n/cmm",
    remarks: "",
    normalRange: "13-17\n4.5-5.5\n4000-11000\n150000-450000"
  },
  {
    id: 2,
    test: "DIABETIC PROFILE :",
    parameter: "Blood Sugar (F)\nBlood Sugar (PP)\nHBA1C",
    result: "95\n145\n5.8",
    resultMeasurementType: "mg/dl\nmg/dl\n%",
    remarks: "",
    normalRange: "70-100\n<140\n4.8-5.9"
  },
  {
    id: 3,
    test: "RENAL PROFILE :",
    parameter: "Blood Urea\nSerum Creatinine\nUric Acid",
    result: "28\n0.9\n5.2",
    resultMeasurementType: "mg/dl\nmg/dl\nmg/dl",
    remarks: "",
    normalRange: "15-40\n0.7-1.3\n3.5-7.2"
  },
  {
    id: 4,
    test: "LIPID PROFILE :",
    parameter: "Total Cholesterol\nTriglycerides\nHDL\nLDL",
    result: "180\n150\n45\n95",
    resultMeasurementType: "mg/dl\nmg/dl\nmg/dl\nmg/dl",
    remarks: "",
    normalRange: "<200\n<150\n>40\n<100"
  },
  {
    id: 5,
    test: "HEPATIC PROFILE :",
    parameter: "SGPT\nSGOT\nAlkaline Phosphatase",
    result: "30\n28\n85",
    resultMeasurementType: "U/L\nU/L\nU/L",
    remarks: "",
    normalRange: "10-40\n10-40\n44-147"
  },
  {
    id: 6,
    test: "COLLAGEN PROFILE :",
    parameter: "RA Factor\nASO Titer\nCRP",
    result: "<10\n<200\nNegative",
    resultMeasurementType: "IU/ml\nIU/ml\nmg/L",
    remarks: "",
    normalRange: "<14\n<200\n<6"
  },
  {
    id: 7,
    test: "Thyroid Profile :",
    parameter: "T3\nT4\nTSH",
    result: "1.2\n8.5\n2.5",
    resultMeasurementType: "ng/ml\nμg/dl\nμIU/ml",
    remarks: "",
    normalRange: "0.8-2.0\n5.1-14.1\n0.4-4.0"
  },
  {
    id: 8,
    test: "PSA :",
    parameter: "Total PSA",
    result: "2.1",
    resultMeasurementType: "ng/ml",
    remarks: "",
    normalRange: "<4.0"
  },
  {
    id: 9,
    test: "URINE TEST :",
    parameter: "Color\nSpecific Gravity\nProtein\nGlucose",
    result: "Yellow\n1.020\nNil\nNil",
    resultMeasurementType: "-\n-\n-\n-",
    remarks: "",
    normalRange: "Yellow\n1.010-1.025\nNil\nNil"
  },
  {
    id: 10,
    test: "X-RAY :",
    parameter: "Chest X-Ray",
    result: "Normal study",
    resultMeasurementType: "-",
    remarks: "",
    normalRange: "Normal chest findings"
  },
  {
    id: 11,
    test: "SONOGRAPHY :",
    parameter: "Abdomen Ultrasound",
    result: "Normal study",
    resultMeasurementType: "-",
    remarks: "FATTY LIVER GRADE I",
    normalRange: "Normal sonographic findings"
  },
  {
    id: 12,
    test: "ECG :",
    parameter: "12-Lead ECG",
    result: "Normal sinus rhythm",
    resultMeasurementType: "-",
    remarks: "",
    normalRange: "Normal ECG findings"
  },
  {
    id: 13,
    test: "ECHO :",
    parameter: "2D Echo\nEjection Fraction",
    result: "Normal study\n60%",
    resultMeasurementType: "-\n%",
    remarks: "LVEF:60%",
    normalRange: "Normal cardiac function\n55-70%"
  },
  {
    id: 14,
    test: "TMT :",
    parameter: "Treadmill Test",
    result: "Negative for inducible ischemia",
    resultMeasurementType: "-",
    remarks: "",
    normalRange: "Normal TMT response"
  },
  {
    id: 15,
    test: "Vitamin and Mineral :",
    parameter: "Vitamin D3\nVitamin B12\nFerritin",
    result: "30\n350\n100",
    resultMeasurementType: "ng/ml\npg/ml\nng/ml",
    remarks: "",
    normalRange: "20-50\n200-900\n30-400"
  },
  {
    id: 16,
    test: "Iron Profile :",
    parameter: "Serum Iron\nTIBC\nTransferrin Saturation",
    result: "95\n300\n32",
    resultMeasurementType: "μg/dl\nμg/dl\n%",
    remarks: "",
    normalRange: "60-170\n240-450\n20-50"
  },
  {
    id: 17,
    test: "Hormonal Profile :",
    parameter: "Testosterone\nCortisol\nProlactin",
    result: "500\n15\n10",
    resultMeasurementType: "ng/dl\nμg/dl\nng/ml",
    remarks: "",
    normalRange: "300-1000\n6-23\n2-18"
  }
];

const generalExamination = {
  general: "Normal",
  skin: "Normal",
  locomotorSystem: "Normal",
  centralNervousSystem: "Normal",
  endocrineSystem: "Normal",
  genitorUrinarySystem: "Normal",
  nose: "Normal",
  throat: "Normal",
  eye: "Abnormal",
  nearVision: "Corrected",
  distantVision: "Corrected",
  cardioVascularSystem: "Normal",
  giSystem: "Normal",
  respiratorySystem: "Normal",
  ear: "Normal",
  mouth: "Normal",
  nails: "Normal",
  hair: "Normal",
};

const createLabInvestigationsTable = (data) => {
  const tableData = data.map((item) => [
    {
      text: item.test,
      style: "tableLeftSideKey",
      decoration: "underline",
      border: [true, false, false, false],
    },
    {
      text: item.parameter,
      style: "smallText",
      border: [false, false, false, false],
    },
    {
      text: item.result,
      style: "smallText",
      alignment: "right",
      border: [false, false, false, false],
    },
    {
      text: item.resultMeasurementType,
      style: "smallText",
      border: [false, false, false, false],
    },
    {
      text: item.remarks,
      style: "smallText",
      border: [false, false, false, false],
    },
    {
      text: item.normalRange,
      style: "smallText",
      border: [false, false, true, false],
    },
  ]);

  return {
    table: {
      widths: ["15%", "25%", "10%", "10%", "20%", "20%"],
      headerRows: 2,
      body: [
        [
          {
            text: "Lab Investigations",
            style: "tableHeader",
            fillColor: "#f5f5f5",
            colSpan: 6,
            alignment: "center",
            border: [true, true, true, true],
          },
          {},
          {},
          {},
          {},
          {},
        ],
        [
          {
            text: "Test",
            style: "tableRowName",
            decoration: "underline",
            border: [true, false, false, false],
          },
          {
            text: "Parameter",
            style: "tableRowName",
            decoration: "underline",
            border: [false, false, false, false],
          },
          {
            text: "Result",
            style: "tableRowName",
            decoration: "underline",
            alignment: "right",
            border: [false, false, false, false],
          },
          {
            text: "",
            style: "tableRowName",
            border: [false, false, false, false],
          },
          {
            text: "",
            style: "tableRowName",
            border: [false, false, false, false],
          },
          {
            text: "Normal Range",
            style: "tableRowName",
            decoration: "underline",
            border: [false, false, true, false],
          },
        ],
        ...tableData,
        [
          {
            text: "",
            style: "tableRowName",
            colSpan: 6,
            alignment: "center",
            border: [true, false, true, true],
          },
        ],
      ],
    },
    margin: [0, 5],
  };
};

const createAudiometryTestTable = () => ({
  table: {
    widths: ["*"],
    body: [
      [
        {
          text: "Audiometry Test :",
          style: "tableHeader",
          alignment: "center",
          fillColor: "#f5f5f5",
        },
      ],
      [
        {
          columns: [
            {
              width: "50%",
              stack: [
                {
                  text: "",
                  style: "tableRowValue",
                  fillColor: "#f5f5f5",
                },
                {
                  text: "Right Ear DB:",
                  style: "tableLeftSideKey",
                  fillColor: "#f5f5f5",
                },
                {
                  text: "Left Ear DB:",
                  style: "tableLeftSideKey",
                  fillColor: "#f5f5f5",
                },
              ],
            },
            {
              width: "50%",
              stack: [
                {
                  text: "Interpretation:",
                  style: "tableLeftSideKey",
                  fillColor: "#f5f5f5",
                },
              ],
            },
          ],
        },
      ],
    ],
  },
  margin: [0, 5],
});

const createPulmonaryFunctionTable = () => ({
  table: {
    widths: ["20%", "20%", "60%"],
    headerRows: 1,
    body: [
      [
        {
          text: "Pulmonary Function Test",
          style: "tableHeader",
          fillColor: "#f5f5f5",
          alignment: "center",
          colSpan: 3,
        },
        {},
        {},
      ],
      [
        {
          text: "FVC %",
          style: "tableLeftSideKey",
          border: [true, false, false, false],
        },
        {
          text: "ghh",
          style: "smallText",
          border: [false, false, false, false],
        },
        { text: "", style: "smallText", border: [false, false, true, false] },
      ],
      [
        {
          text: "FEV 1 %",
          style: "tableLeftSideKey",
          border: [true, false, false, false],
        },
        {
          text: "21ee",
          style: "smallText",
          border: [false, false, false, false],
        },
        { text: "", style: "smallText", border: [false, false, true, false] },
      ],
      [
        {
          text: "MVV Observed",
          style: "tableLeftSideKey",
          border: [true, false, false, true],
        },
        {
          text: "124fb",
          style: "smallText",
          border: [false, false, false, true],
        },
        { text: "", style: "smallText", border: [false, false, true, true] },
      ],
    ],
  },
  margin: [0, 5],
});

const createGeneralExaminationTable = (data) => ({
  table: {
    widths: ["*"],
    headerRows: 1,
    body: [
      [
        {
          text: "General Examination :",
          style: "tableHeader",
          alignment: "center",
          fillColor: "#f5f5f5",
        },
      ],
      [
        {
          stack: [
            {
              columns: [
                { text: "General : ", style: "tableLeftSideKey" },
                { text: `${data.general || ""}`, style: "tableRowValue" },
              ],
            },
            {
              columns: [
                { text: "Skin : ", style: "tableLeftSideKey" },
                { text: `${data.skin || "Normal"}`, style: "tableRowValue" },
              ],
            },
            {
              columns: [
                { text: "Locomotor System : ", style: "tableLeftSideKey" },
                { text: `${data.locomotorSystem || "Normal"}`, style: "tableRowValue" },
              ],
            },
            {
              columns: [
                { text: "Central Nervous System : ", style: "tableLeftSideKey" },
                { text: `${data.centralNervousSystem || "Normal"}`, style: "tableRowValue" },
              ],
            },
            {
              columns: [
                { text: "Endocrine System : ", style: "tableLeftSideKey" },
                { text: `${data.endocrineSystem || "Normal"}`, style: "tableRowValue" },
              ],
            },
            {
              columns: [
                { text: "Genitor Urinary System : ", style: "tableLeftSideKey" },
                { text: `${data.genitorUrinarySystem || "Normal"}`, style: "tableRowValue" },
              ],
            },
            {
              columns: [
                { text: "Nose : ", style: "tableLeftSideKey" },
                { text: `${data.nose || "Normal"}`, style: "tableRowValue" },
              ],
            },
            {
              columns: [
                { text: "Throat : ", style: "tableLeftSideKey" },
                { text: `${data.throat || "Normal"}`, style: "tableRowValue" },
              ],
            },
            {
              columns: [
                { text: "Eye : ", style: "tableLeftSideKey" },
                { text: `${data.eye || "Abnormal"}`, style: "tableRowValue" },
              ],
            },
            {
              columns: [
                { text: "Near Vision : ", style: "tableLeftSideKey",  margin: [10, 5], },
                { text: `${data.nearVision || "Corrected"}`, style: "tableRowValue" },
              ],
            },
            {
              columns: [
                { text: "Distant Vision : ", style: "tableLeftSideKey",  margin: [10, 5], },
                { text: `${data.distantVision || "Corrected"}`, style: "tableRowValue" },
              ],
            },
            {
              columns: [
                { text: "CardioVascular System : ", style: "tableLeftSideKey", },
                { text: `${data.cardioVascularSystem || "Normal"}`, style: "tableRowValue" },
              ],
            },
            {
              columns: [
                { text: "GI System : ", style: "tableLeftSideKey" },
                { text: `${data.giSystem || "Normal"}`, style: "tableRowValue" },
              ],
            },
            {
              columns: [
                { text: "Respiratory System : ", style: "tableLeftSideKey" },
                { text: `${data.respiratorySystem || "Normal"}`, style: "tableRowValue" },
              ],
            },
            {
              columns: [
                { text: "Ear : ", style: "tableLeftSideKey" },
                { text: `${data.ear || "Normal"}`, style: "tableRowValue" },
              ],
            },
            {
              columns: [
                { text: "Mouth: ", style: "tableLeftSideKey" },
                { text: `${data.mouth || "Normal"}`, style: "tableRowValue" },
              ],
            },
            {
              columns: [
                { text: "Nails : ", style: "tableLeftSideKey" },
                { text: `${data.nails || "Normal"}`, style: "tableRowValue" },
              ],
            },
            {
              columns: [
                { text: "Hair : ", style: "tableLeftSideKey" },
                { text: `${data.hair || "Normal"}`, style: "tableRowValue" },
              ],
            },
          ],
          margin: [10, 5],
        },
      ],
    ],
  },
  margin: [0, 5],
});

export const generateHealthReport = async (data) => {
  try {
    const documentDefinition = {
      content: [
        { text: "Health Report", style: "header", alignment: "center" },
        { text: "Ahmedabad", alignment: "center", margin: [0, 0, 0, 10] },
        {
          text: "Annual Health Checkup 2023-24",
          style: "subheader",
          alignment: "center",
          margin: [0, 0, 0, 20],
        },

        {
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: "EMPLOYEE DETAILS",
                  style: "tableHeader",
                  fillColor: "#f5f5f5",
                  alignment: "center",
                },
              ],
              [
                {
                  columns: [
                    {
                      width: "50%",
                      stack: [
                        {
                          text: `Employee Name: ${data.patientName || "Rahul Sharma"}`,
                          style: "tableLeftSideKey",
                        },
                        {
                          text: `Employee Code: ${data.employeeCode || "XXXXXXX"}`,
                          style: "tableLeftSideKey",
                        },
                        {
                          text: `Gender: ${data.gender || "Male"}`,
                          style: "tableLeftSideKey",
                        },
                        {
                          text: `ExaminationType: ${
                            data.examinationType || "Periodic"
                          }`,
                          style: "tableLeftSideKey",
                        },
                        {
                          stack: [
                            {
                              text: `Height(cms): ${data.height || "174"}`,
                              style: "tableLeftSideKey",
                            },
                            {
                              text: `Weight(kgs): ${data.weight || "82.00"}`,
                              style: "tableLeftSideKey",
                            },
                          ],
                        },
                        {
                          text: `Designation: ${data.designation || "Assistant Manager - Health Services"}`,
                          style: "tableLeftSideKey",
                        },
                      ],
                    },
                    {
                      width: "50%",
                      stack: [
                        {
                          text: `Examination Date: ${new Date().toLocaleDateString()}`,
                          style: "tableLeftSideKey",
                        },
                        {
                          text: `Date of Birth: ${data.dateOfBirth || "09/02/1999"}`,
                          style: "tableLeftSideKey",
                        },
                        {
                          text: `Age: ${data.dateOfBirth ? Math.floor((new Date() - new Date(data.dateOfBirth)) / (1000 * 60 * 60 * 24 * 365.25)) : "25"}`,
                          style: "tableLeftSideKey",
                        },
                        { text: "", style: "tableLeftSideKey" },
                        {
                          text: `BMI: ${data.bmi || "270.8"}`,
                          style: "tableLeftSideKey",
                        },
                      ],
                    },
                  ],
                  margin: [0, 10],
                },
              ],
            ],
          },
          margin: [0, 0, 0, 20], // Add margin here to create a gap
        },
        {
          table: {
            widths: ["*"],
            body: [
              [
          {
            text: "CLINICAL RECORD",
            fillColor: "#f5f5f5",
            style: "tableHeader",
            alignment: "center",
          },
              ],
              [
          {
            stack: [
              {
                columns: [
            { text: "Existing Disease: ", style: "tableLeftSideKey" },
            { text: `${data.existingDisease || "N/A"}`, style: "tableRowValue" },
                ],
              },
              {
                columns: [
            { text: "Surgical Remark: ", style: "tableLeftSideKey" },
            { text: `${data.surgicalRemark || "N/A"}`, style: "tableRowValue" },
                ],
              },
              {
                columns: [
            { text: "BP: ", style: "tableLeftSideKey" },
            { text: `${data.bp || "134 / 84 mm of hg"}`, style: "tableRowValue" },
                ],
              },
            ],
          },
              ],
            ],
          },
        },
        createGeneralExaminationTable(generalExamination),
        // { text: "INVESTIGATION", style: "sectionHeader", fillColor: "#f5f5f5", margin: [0, 10, 0, 5] },
        createLabInvestigationsTable(LabInvestigations),
        createAudiometryTestTable(),
        createPulmonaryFunctionTable(),

       {
         stack: [
           {
             text: "Diagnosis Remark",
             style: "tableLeftSideKey",
             fillColor: "#f5f5f5",
             margin: [0, 10, 0, 5],
           },
           {
             text: [
               "Overweight\n",
               "Pre Diabetic\n",
               "Elevated SGPT\n",
               "Vitamin D3 deficiency"
             ],
             style: "tableRowValue",
             margin: [10, 5],
           },
           {
             text: "Recommandation",
             style: "tableLeftSideKey",
             fillColor: "#f5f5f5",
             margin: [0, 10, 0, 5],
           },
           {
             text: [
               "Consult doctor for vitamin D3 deficiency\n",
               "Reduce weight"
             ],
             style: "tableRowValue",
             margin: [10, 5],
           }
         ]
       }
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        sectionHeader: {
          fontSize: 12,
          bold: true,
          margin: [0, 5],
          alignment: "center",
        },
        subSectionHeader: {
          fontSize: 11,
          bold: true,
          margin: [0, 5],
        },
        tableHeader: {
          fontSize: 11,
          bold: true,
          margin: [0, 5],
        },
        tableRowName: {
          fontSize: 11,
          margin: [0, 5],
        },
        tableRowValue: {
          fontSize: 10,
          margin: [0, 5],
        },
        smallText: {
          fontSize: 9,
          margin: [0, 5],
        },
        tableLeftSideKey:{
          fontSize: 10,
          bold: true,
          margin: [0, 5],
        }
      },
      defaultStyle: {
        fontSize: 10,
      },
    };

    return pdfMake.createPdf(documentDefinition);
  } catch (error) {
    console.error("Error generating PDF:", error);
    throw error;
  }
};
