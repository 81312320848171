import React, { useState, useEffect } from 'react';
import PackageForm from './Form';
import { useParams } from 'react-router-dom';
import { SendGetRequest } from '../../../../api/config/ApiCall';

const PackageUpdate = () => {
  const { id } = useParams();  
  
  const [packageData, setPackageData] = useState();  

  const fetchData = async () => {
    try {
      const response = await SendGetRequest("singlePackageUrl", {id});
      setPackageData(response.data);
    } catch (error) {
      console.error('Error fetching job data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (packageData === undefined) {
    return <div>Loading...</div>;
  }
  
  return (
    <>
      <PackageForm packageId={id} packageData={packageData} />
    </>
  );
};

export default PackageUpdate;
