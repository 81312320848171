import { SendGetRequest, SendPostRequest } from 'api/config/ApiCall';
import React from 'react';
import { useSnackbar } from 'notistack';
import {
    TextField,
    Paper,
    TableRow,
    TableContainer,
    TableBody,
    TableHead,
    Table,
    FormControlLabel,
    Checkbox,
    Typography
} from '@mui/material';
import { StyledTableCell, StyledTableRow } from 'helper/employee/employeeHelpers';
import styles from 'css/styles';


export const testSetupFilterValues = () => [
    { value: "blood-group", label: "BLOOD GROUP" },
    { value: "angiography", label: "ANGIOGRAPHY" },
    { value: "cardia-profile", label: "Cardiac Profile" },
    { value: "cbc", label: "CBC" },
    { value: "collagen-profile", label: "COLLAGEN PROFILE" },
    { value: "dengue-test", label: "Dengue Test" },
    { value: "diabetic-profile", label: "DIABETIC PROFILE" },
    { value: "ecg", label: "ECG" },
    { value: "echo", label: "ECHO" },
    { value: "hepatic-profile", label: "HEPATIC PROFILE" },
    { value: "hiv", label: "HIV" },
    { value: "hormonal-profile", label: "Hormonal Profile" },
    { value: "iron-profile", label: "Iron Profile" },
    { value: "lipid-profile", label: "LIPID PROFILE" },
    { value: "melaria-test", label: "Malaria Test" },
    { value: "mammography", label: "MAMMOGRAPHY" },
    { value: "mantoux", label: "MANTOUX" },
    { value: "pancreatic-profile", label: "Pancreatic Profile" },
    { value: "peps-smear", label: "PEPS SMEAR" },
    { value: "pregnancy-test", label: "Pregnancy Test" },
    { value: "psa", label: "PSA" },
    { value: "renal-profile", label: "RENAL PROFILE" },
    { value: "sonography", label: "SONOGRAPHY" },
    { value: "sputum", label: "SPUTUM" },
    { value: "stool", label: "STOOL" },
    { value: "stress", label: "STRESS" },
    { value: "thyroid-profile", label: "THYROID PROFILE" },
    { value: "tmt", label: "TMT" },
    { value: "urine-test", label: "URINE TEST" },
    { value: "vitamin-and-mineral", label: "Vitamin and Mineral" },
    { value: "widal", label: "WIDAL" },
    { value: "x-ray", label: "X-RAY" },
    { value: "other", label: "OTHER " },
];

export const getDiagnosticCommonData = async (setupVal) => {
    try {
        const response = await SendGetRequest("fetchDiagnosticSetup", { setupVal: setupVal });
        if (response.status) {
            return response?.data?.data || [];
        } else {
            console.log(response.message);
            return []
        }
    } catch (error) {
        console.log(error);
        return [];
    }
}

export const saveDiagnosticData = async (formData) => {
    try {
        const response = await SendPostRequest("saveDiagnosticSetup", { formData });
        if (response.status) {
            return response.message;
        } else {
            console.log(response.message);
            return response.message
        }
    } catch (error) {
        console.log(error);
    }
}

export const componentMap = {
    'blood-group': React.lazy(() => import('./Master/Setup-Compoents/bloodGroup')),
    'angiography': React.lazy(() => import('./Master/Setup-Compoents/angiography')),
    'cardia-profile': React.lazy(() => import('./Master/Setup-Compoents/cardiacprofile')),
    'cbc': React.lazy(() => import('./Master/Setup-Compoents/cbc')),
    'collagen-profile': React.lazy(() => import('./Master/Setup-Compoents/collagenProfile')),
    'dengue-test': React.lazy(() => import('./Master/Setup-Compoents/dengueTest')),
    'diabetic-profile': React.lazy(() => import('./Master/Setup-Compoents/diabeticProfile')),
    'ecg': React.lazy(() => import('./Master/Setup-Compoents/ecg')),
    'echo': React.lazy(() => import('./Master/Setup-Compoents/echo')),
    'hepatic-profile': React.lazy(() => import('./Master/Setup-Compoents/hepaticProfile')),
    'hiv': React.lazy(() => import('./Master/Setup-Compoents/hiv')),
    'hormonal-profile': React.lazy(() => import('./Master/Setup-Compoents/hormonalProfile')),
    'iron-profile': React.lazy(() => import('./Master/Setup-Compoents/ironProfile')),
    'lipid-profile': React.lazy(() => import('./Master/Setup-Compoents/lipidProfile')),
    'melaria-test': React.lazy(() => import('./Master/Setup-Compoents/malariaTest')),
    'mammography': React.lazy(() => import('./Master/Setup-Compoents/mammography')),
    'mantoux': React.lazy(() => import('./Master/Setup-Compoents/mantoux')),
    'pancreatic-profile': React.lazy(() => import('./Master/Setup-Compoents/pancreaticProfile')),
    'peps-smear': React.lazy(() => import('./Master/Setup-Compoents/pepsSmear')),
    'other': React.lazy(() => import('./Master/Setup-Compoents/other')),
    'pregnancy-test': React.lazy(() => import('./Master/Setup-Compoents/pregnancyTest')),
    'psa': React.lazy(() => import('./Master/Setup-Compoents/psa')),
    'renal-profile': React.lazy(() => import('./Master/Setup-Compoents/renalProfile')),
    'sonography': React.lazy(() => import('./Master/Setup-Compoents/sonography')),
    'sputum': React.lazy(() => import('./Master/Setup-Compoents/sputum')),
    'stool': React.lazy(() => import('./Master/Setup-Compoents/stool')),
    'stress': React.lazy(() => import('./Master/Setup-Compoents/stressTest')),
    'thyroid-profile': React.lazy(() => import('./Master/Setup-Compoents/thyroidProfile')),
    'tmt': React.lazy(() => import('./Master/Setup-Compoents/tmt')),
    'urine-test': React.lazy(() => import('./Master/Setup-Compoents/urineTest')),
    'vitamin-and-mineral': React.lazy(() => import('./Master/Setup-Compoents/vitaminAndMineral')),
    'widal': React.lazy(() => import('./Master/Setup-Compoents/widal')),
    'x-ray': React.lazy(() => import('./Master/Setup-Compoents/xRay')),
};

export const useDiagnosticData = (setupVal) => {
    const [data, setData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        fetchData();
    }, [setupVal]);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const res = await getDiagnosticCommonData(setupVal);
            setIsLoading(false);
            setData(res);
        } catch (error) {
            console.log("Error fetching diagnostic data:", error);
            setData([]);
            setIsLoading(false);
        }
    };

    const handleChange = (event, index) => {
        const { name, value } = event.target;
        setData((prevData) =>
            prevData.map((row, i) =>
                i === index ? { ...row, [name]: value } : row
            )
        );
    };

    const handleSave = async () => {
        try {
            const newData = { ...data, setupVal };
            const res = await saveDiagnosticData(newData);
            enqueueSnackbar(res.message || res, { variant: "success" });
            fetchData();
        } catch (error) {
            console.log("Error during the save process:", error);
            enqueueSnackbar("An error occurred during the save process.", { variant: "error" });
        }
    };

    return { data, handleChange, handleSave, isLoading };
};

export const DiagnosticNumericTable = ({ data, handleChange }) => {
    return (
        <>
            <Typography variant="h6" noWrap component="div" sx={{ mb: 1, fontWeight: 'bold' }}>
                Numeric Setup
            </Typography>
            <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow sx={styles.TableRow}>
                            <StyledTableCell>Parameter</StyledTableCell>
                            <StyledTableCell>Gender</StyledTableCell>
                            <StyledTableCell>Age From</StyledTableCell>
                            <StyledTableCell>Age To</StyledTableCell>
                            <StyledTableCell>Is Normal</StyledTableCell>
                            <StyledTableCell>Minimum</StyledTableCell>
                            <StyledTableCell>Maximum</StyledTableCell>
                            <StyledTableCell>Display Range</StyledTableCell>
                            <StyledTableCell>Diagnosis Remark</StyledTableCell>
                            <StyledTableCell>Recommendation Remark</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.length ? (
                            data.map((row, index) => (
                                <StyledTableRow key={index} sx={styles.TableRow}>
                                    <StyledTableCell>{row?.parameter}</StyledTableCell>
                                    <StyledTableCell>{row?.gender}</StyledTableCell>
                                    <StyledTableCell>
                                        <TextField
                                            required
                                            fullWidth
                                            size='small'
                                            name='age_from'
                                            placeholder="Age From"
                                            value={row?.age_from}
                                            onChange={(e) => handleChange(e, index)}
                                            type='number'
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TextField
                                            required
                                            fullWidth
                                            size='small'
                                            name='age_to'
                                            placeholder="Age To"
                                            value={row?.age_to}
                                            onChange={(e) => handleChange(e, index)}
                                            type='number'
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <FormControlLabel
                                            name="isNormal"
                                            checked={row?.is_normal}
                                            control={<Checkbox />}
                                            disabled
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TextField
                                            required
                                            fullWidth
                                            size='small'
                                            name='minimum'
                                            placeholder="minimum"
                                            value={row?.minimum}
                                            onChange={(e) => handleChange(e, index)}
                                            type='number'
                                        /></StyledTableCell>
                                    <StyledTableCell>
                                        <TextField
                                            required
                                            fullWidth
                                            size='small'
                                            name='maximum'
                                            placeholder="maximum"
                                            value={row?.maximum}
                                            onChange={(e) => handleChange(e, index)}
                                            type='number'
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TextField
                                            required
                                            fullWidth
                                            size='small'
                                            name='display_range'
                                            placeholder="Display range"
                                            value={row?.display_range}
                                            onChange={(e) => handleChange(e, index)}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            name='diagnosis_remark'
                                            placeholder="Enter Diagnosis Remark"
                                            value={row?.diagnosis_remark}
                                            onChange={(e) => handleChange(e, index)}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            name='recommendation_remark'
                                            placeholder="Enter Recommendation Remark"
                                            value={row?.recommendation_remark}
                                            onChange={(e) => handleChange(e, index)}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        ) : (
                            <StyledTableRow sx={styles.TableRow}>
                                <StyledTableCell colSpan={10} align="center">
                                    No Records Found
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export const DiagnosticListTable = ({ data, handleChange }) => {
    return (
        <>
            <Typography variant="h6" noWrap component="div" sx={{ mb: 1, fontWeight: 'bold' }}>
                List Setup
            </Typography>

            <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow sx={styles.TableRow}>
                            <StyledTableCell>List Parameter</StyledTableCell>
                            <StyledTableCell>List Value</StyledTableCell>
                            <StyledTableCell>Is Normal</StyledTableCell>
                            <StyledTableCell>Diagnosis Remark</StyledTableCell>
                            <StyledTableCell>Recommendation Remark</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.length ? (
                            data.map((row, index) => (
                                <StyledTableRow key={index} sx={styles.TableRow}>
                                    <StyledTableCell>{row?.list_parameter}</StyledTableCell>
                                    <StyledTableCell>{row?.list_value}</StyledTableCell>
                                    <StyledTableCell>
                                        <FormControlLabel
                                            name="isNormal"
                                            checked={row?.isnormal}
                                            control={<Checkbox />}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TextField
                                            required
                                            fullWidth
                                            size="small"
                                            name="diagnosis_remark"
                                            placeholder="Enter Diagnosis Remark"
                                            value={row?.diagnosis_remark}
                                            onChange={(e) => handleChange(e, index)}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <TextField
                                            required
                                            fullWidth
                                            size="small"
                                            name="recommendation_remark"
                                            placeholder="Enter Recommendation Remark"
                                            value={row?.recommendation_remark}
                                            onChange={(e) => handleChange(e, index)}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        ) : (
                            <StyledTableRow sx={styles.TableRow}>
                                <StyledTableCell colSpan={8} align="center">
                                    No Records Found
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};


