import React from 'react';
import sharedImports from '../../../Comman/sharedImports';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StyledTableCell, StyledTableRow } from 'helper/employee/employeeHelpers';
import styles from 'css/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteButton from 'component/Comman/DeleteButton/DeleteButton';
import PaginationControls from 'component/Comman/paginationControls';
const {
    Box,
    Grid,
    Select,
    Button,
    Dialog,
    Checkbox,
    MenuItem,
    TextField,
    Typography,
    FormControl,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControlLabel,
    LocalizationProvider,
    UnfoldMoreOutlinedIcon,
    TableBody,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    DatePicker
} = sharedImports;

const FamilyDetailsDialog = ({
    familyData,
    relations,
    bloodGroups,
    openFamilyModel,
    handleCloseFamily,
    handleChangeFamily,
    handleDateChangeFamily,
    handleSubmitFamilyForm,
    familyListData,
    handleEditFamily,
    handleDeleteFamily,
    setGlobalFilter,
    handleSortFamily,
    pagination,
    setPagination,
    globalFilter,
    rowCount

}) => {
    return (
        <>
            {/* Family Details Dialog */}
            <Dialog open={openFamilyModel} onClose={handleCloseFamily} fullWidth maxWidth="md">
                <DialogTitle>Add Details</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Box sx={[styles.box]}> Name <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                            </Box>
                            <TextField
                                required
                                id="f_name"
                                fullWidth
                                size="small"
                                name="f_name"
                                value={familyData.f_name}
                                onChange={handleChangeFamily}
                                placeholder="Name"
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box sx={[styles.box]}> Relation <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                            </Box>
                            <FormControl fullWidth>
                                <Select
                                    name="relation"
                                    value={familyData.relation || ""}
                                    onChange={handleChangeFamily}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem sx={{ ml: 3 }} value=""><em>-Select-</em></MenuItem>
                                    {relations.map((relation, index) => (
                                        <MenuItem sx={{ ml: 3 }} key={index} value={relation.value}>
                                            {relation.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box sx={{ fontWeight: 'bold' }}>Blood Group</Box>
                            <FormControl fullWidth>
                                <Select
                                    name="f_blood_group"
                                    value={familyData.f_blood_group || ""}
                                    onChange={handleChangeFamily}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem sx={{ ml: 3 }} value=""><em>-Select-</em></MenuItem>
                                    {bloodGroups.map((boolGroup, index) => (
                                        <MenuItem sx={{ ml: 3 }} key={index} value={boolGroup.value}>
                                            {boolGroup.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box sx={{ fontWeight: 'bold' }}>Date of Birth</Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={familyData.f_date_of_birth}
                                    onChange={(date) => handleDateChangeFamily(date, 'f_date_of_birth')}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box sx={{ fontWeight: 'bold' }}>Age</Box>
                            <TextField
                                required
                                id="f_age"
                                fullWidth
                                size="small"
                                name="f_age"
                                value={familyData.f_age}
                                onChange={handleChangeFamily}
                                type="number"
                                placeholder='Age'
                                disabled
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box sx={[styles.box]}> Gender <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                            </Box>
                            <FormControl fullWidth>
                                <Select
                                    name="f_gender"
                                    value={familyData.f_gender || ""}
                                    onChange={handleChangeFamily}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value=""><em>-Select-</em></MenuItem>
                                    <MenuItem value="male">Male</MenuItem>
                                    <MenuItem value="female">Female</MenuItem>
                                    <MenuItem value="other">Other</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={familyData.in_active}
                                        name="in_active"
                                        color="primary"
                                        onChange={handleChangeFamily}
                                    />
                                }
                                label="In Active"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={familyData.is_obsolete_medical_recode}
                                        name="is_obsolete_medical_recode"
                                        color="primary"
                                        onChange={handleChangeFamily}
                                    />
                                }
                                label="isObsoleteMedicalRecode"
                            />
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Grid item xs={12} md={4}>
                            <Button onClick={handleCloseFamily}>Cancel</Button>
                            <Button variant="contained" onClick={handleSubmitFamilyForm}>Submit</Button>
                        </Grid>
                    </DialogActions>
                </DialogContent>

            </Dialog>
            <Box sx={{ mt: 5, mb: 5, mx: 2, bgcolor: 'white', borderRadius: 1 }}>
                <Box component="div" xs={12}>
                    <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                                <Box component='span' sx={{ fontWeight: 'bold' }}>Show </Box>
                                <select
                                    id="outlined-number"
                                    sx={{ width: '9ch' }}
                                    value={pagination.pageSize}
                                    onChange={e => setPagination(prev => ({ ...prev, pageSize: Number(e.target.value), pageIndex: 0 }))}
                                >
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                </select>
                            </Grid>
                        </Grid>
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                            <TextField
                                id="filled-search"
                                type="search"
                                size='small'
                                value={globalFilter}
                                onChange={(e) => setGlobalFilter(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper} sx={{ mb: 2 }}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow sx={styles.TableRow}>
                                    <StyledTableCell onClick={() => handleSortFamily('relation')}>
                                        Relation
                                        <UnfoldMoreOutlinedIcon />
                                    </StyledTableCell>
                                    <StyledTableCell onClick={() => handleSortFamily('f_name')}>
                                        Name
                                        <UnfoldMoreOutlinedIcon />
                                    </StyledTableCell>
                                    <StyledTableCell onClick={() => handleSortFamily('f_blood_group')}>
                                        Blood Group
                                        <UnfoldMoreOutlinedIcon />
                                    </StyledTableCell>
                                    <StyledTableCell onClick={() => handleSortFamily('f_date_of_birth')}>
                                        Date of birth
                                        <UnfoldMoreOutlinedIcon />
                                    </StyledTableCell>
                                    <StyledTableCell onClick={() => handleSortFamily('f_age')}>
                                        Age
                                        <UnfoldMoreOutlinedIcon />
                                    </StyledTableCell>
                                    <StyledTableCell onClick={() => handleSortFamily('f_gender')}>
                                        Gender
                                        <UnfoldMoreOutlinedIcon />
                                    </StyledTableCell>
                                    <StyledTableCell>Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {familyListData.length === 0 ? (
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={7} align="center">
                                            No records found
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ) : (
                                    familyListData.map((row, index) => (
                                        <StyledTableRow key={row?.assetTag} sx={styles.TableRow}>
                                            <StyledTableCell>{row?.relation}</StyledTableCell>
                                            <StyledTableCell>{row?.f_name}</StyledTableCell>
                                            <StyledTableCell>{row?.f_blood_group}</StyledTableCell>
                                            <StyledTableCell>{row?.f_date_of_birth}</StyledTableCell>
                                            <StyledTableCell>{row?.f_age}</StyledTableCell>
                                            <StyledTableCell>{row?.f_gender}</StyledTableCell>
                                            <StyledTableCell>
                                                <EditOutlinedIcon onClick={() => handleEditFamily(row.id)} />
                                                <DeleteButton id={row?.id} fieldName={row?.f_name} index={row?.index} handleDeleteRow={() => handleDeleteFamily(row?.id)} titleName="Employee Family" />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <PaginationControls
                        pagination={pagination}
                        rowCount={rowCount}
                        setPagination={setPagination}
                    />
                </Box>
            </Box>
        </>
    );
};

export default FamilyDetailsDialog;
