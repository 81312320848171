import React, { useState, useEffect } from 'react';
import { SendGetRequest, SendPutRequest } from 'api/config/ApiCall';
import { AppBar, Toolbar, Typography, Container, Box, TableContainer, Table, TableHead, TableRow, TableBody, Paper, Button, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import styles from 'css/styles';
import { StyledTableCell, StyledTableRow } from 'helper/employee/employeeHelpers';
import { useNavigate, useParams } from 'react-router-dom';
import Textarea from '@mui/joy/Textarea';
import { useSnackbar } from 'notistack';


const LabFormPage = () => {
    const [data, setData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [action, setAction] = useState({
        action: '',
        pre_joinee_id: '',
        reason: ''
    });
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const fetchUserInfo = React.useCallback(async () => {
        try {
            const res = await SendGetRequest("publicPreJoineeDetail", { labID: id });
            setData(res.data);
        } catch (error) {
            console.error('Error fetching job data:', error);
        }
    }, [id]);
    useEffect(() => {
        fetchUserInfo();
    }, [id, fetchUserInfo]);
    const handleOpenDialog = (row, actionType) => {
        setAction({ action: actionType, pre_joinee_id: row?.id });
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setAction({ action: '', pre_joinee_id: '', reason: '' });
    };
    const handleSubmit = async () => {
        try {
            const appointmentVal = action.action === 'confirm' ? '1' : '2';
            if (appointmentVal === 2 && !action.reason) {
                enqueueSnackbar('Please enter reason for rejection', { variant: 'warning' });
                return;
            }
            appointmentVal === 2 && !action.reason && enqueueSnackbar('Please enter reason for rejection', { variant: 'warning' });
            const statusData = {
                action: appointmentVal,
                pre_joinee_id: action.pre_joinee_id,
                reason: action.reason
            };
            const response = await SendPutRequest("updateAppoinmentStatus", statusData, action.pre_joinee_id);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                fetchUserInfo();
                handleCloseDialog();
            } else {
                enqueueSnackbar(response.message, { variant: "warning" });
                handleCloseDialog();
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    };
    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h5" component="div">
                        Health Care
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container maxWidth="xl">
                <Box mt={4} textAlign="left">
                    <Typography variant="h4" component="h1" gutterBottom>
                        Labs
                    </Typography>
                    <TableContainer component={Paper} sx={{ mb: 2 }}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow sx={styles.TableRow}>
                                    <StyledTableCell>Sr no.</StyledTableCell>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Email</StyledTableCell>
                                    <StyledTableCell>Phone No.</StyledTableCell>
                                    <StyledTableCell>Date of birth</StyledTableCell>
                                    <StyledTableCell>Appointment Schedule</StyledTableCell>
                                    <StyledTableCell>Blood group</StyledTableCell>
                                    <StyledTableCell>Appointment status</StyledTableCell>
                                    <StyledTableCell>Appoinment reject comment</StyledTableCell>
                                    <StyledTableCell>Appointment action</StyledTableCell>
                                    <StyledTableCell>Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.length === 0 || !data ? (
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={8} align="center">
                                            No records found
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ) : (
                                    data?.map((row, index) => (
                                        <StyledTableRow key={index} sx={styles.TableRow}>
                                            <StyledTableCell>
                                                {index + 1}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {row?.full_name}
                                            </StyledTableCell>
                                            <StyledTableCell>{row?.email || ''}</StyledTableCell>
                                            <StyledTableCell>{row?.phone_number || ''}</StyledTableCell>
                                            <StyledTableCell> {row?.dob} </StyledTableCell>
                                            <StyledTableCell>{row?.appointment_date_time}</StyledTableCell>
                                            <StyledTableCell>{row?.blood_group}</StyledTableCell>
                                            <StyledTableCell>{row?.appointment_status === '0' || row?.appointment_status == null ? 'Pending' : row?.appointment_status === '1' ? 'Accept' : 'Reject'}</StyledTableCell>
                                            <StyledTableCell>{row?.appoinment_reject_comment}</StyledTableCell>
                                            <StyledTableCell>
                                                <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
                                                    <Grid item>
                                                        <Button onClick={() => handleOpenDialog(row, 'confirm')} variant='contained' sx={{ backgroundColor: '#1976d2', color: '#fff', '&:hover': { backgroundColor: '#1976d2' } }} disabled={row?.appointment_status == 1 || row?.appointment_status == 2 ? true : false}>Confirm</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button onClick={() => handleOpenDialog(row, 'reject')} variant='contained' sx={{ backgroundColor: '#FF0000', color: '#fff', '&:hover': { backgroundColor: '#FF0000' } }}
                                                            disabled={row?.appointment_status === '1' || row?.appointment_status === '2' ? true : false}>Reject</Button>
                                                    </Grid>
                                                </Grid>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Grid item>
                                                    <Button onClick={() => navigate(`/pre-joine-detail/${row?.id}`)} variant='contained' sx={{ backgroundColor: '#1976d2', color: '#fff', '&:hover': { backgroundColor: '#1976d2' } }}>View</Button>
                                                </Grid>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Container>
            {/* Confirmation/Reject Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}
                aria-labelledby="responsive-dialog-title"
                fullWidth
            >
                <DialogTitle>{action.action === 'confirm' ? 'Confirm Appointment' : 'Reject Appointment'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {action.action === 'confirm' && (
                            ' Are you sure you want to this appointment?'
                        )}
                    </DialogContentText>
                    {action.action === 'reject' && (
                        <Textarea
                            minRows={4}
                            placeholder='Reason for rejection *'
                            onChange={(e) => setAction({ ...action, reason: e.target.value })}
                            value={action.reason || ''}
                            required
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} color="primary">
                        Yes
                    </Button>
                    <Button onClick={handleCloseDialog} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default LabFormPage;
