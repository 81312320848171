import React, { useState, useEffect, useRef } from 'react';
import {
    Container, Box, TableContainer, Table, TableHead, TableRow, TableBody, Paper, Button, Grid, 
    Typography,
    CircularProgress,
} from '@mui/material';
import dayjs from 'dayjs';
import styles from 'css/styles';
import { StyledTableCell, StyledTableRow } from 'helper/employee/employeeHelpers';
import { SendGetRequest, SendDeleteRequest } from 'api/config/ApiCall';
import { useSnackbar } from 'notistack';
import { uploadImage } from 'api/user';
import DeleteButton from 'component/Comman/DeleteButton/DeleteButton';
import { checkStatus, checkStatusCondition } from '../../helper';

export default function Reports(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [reportData, setReportData] = useState([]);
    const fileInputRefs = useRef([]);
    const [shownMsg, setShownMsg] = useState(props?.reportStatus === '2');
    // const [reportIdNeedToChange, setReportIdNeedToChange] = useState([]);
    const [lenghtOfRejectedReports, setLenghtOfRejectedReports] = useState(0);
    const [selectedImages, setSelectedImages] = useState({});
    const [imagePath, setImagePath] = useState({});
    const [packageInfo, setPackageInfo] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = React.useCallback(async () => {
        try {
            const response = await SendGetRequest("getPackagesByDob", { date: props.dob, user_id: props.id });
            if(!response.status) {
                enqueueSnackbar(response.data.message, { variant: "warning" });
                return;
            }
            const reports = response?.data?.data?.reports || [];
            const packages = response?.data?.data?.packages || [];
            if(packages.length === 0) {
                enqueueSnackbar(response?.message, { variant: "warning" });
            }
            setPackageInfo(packages);
            setReportData(reports);
            // filter rejected reports and set the length
            const rejectedReports = reports.filter((report) => checkStatusCondition(report?.status));
            setLenghtOfRejectedReports(rejectedReports.length);
            const imgPathWithReportId = reports.reduce((acc, report) => {
                acc[report.id] = report.image_path ?? null;
                return acc;
            }, {});
            setImagePath(imgPathWithReportId);
        } catch (error) {
            console.error('Error fetching job data:', error);
        }
    }, [props.dob, props.id]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleFileChange = (event, reportId) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImages((prev) => ({
                ...prev,
                [reportId]: file,
            }));
        }
    };

    const deleteUploadImg = async (reportId) => {
        if (selectedImages[reportId]) {
            setSelectedImages((prev) => {
                const newImages = { ...prev };
                delete newImages[reportId];
                return newImages;
            });
        } else {
            try {
                const response = await SendDeleteRequest("deleteReportDocument", reportId);
                if (response.status) {
                    enqueueSnackbar(response.message, { variant: "success" });
                    // setReportIdNeedToChange((prev) => prev.filter((item) => item !== reportId));
                    fetchData();
                }
            } catch (error) {
                enqueueSnackbar('Something went wrong', { variant: 'error' });
            }
        }
    };

    const handleButtonClick = (index) => {
        fileInputRefs.current[index].click();
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const formData = new FormData();
            Object.keys(selectedImages).forEach((reportId) => {
                formData.append('files', selectedImages[reportId]);
                formData.append('report_ids', reportId);
                formData.append('image_paths', imagePath[reportId]);
            });
            formData.append('pre_joine_user_id', props.id);

            const response = await uploadImage(formData);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                fetchData();
                setSelectedImages({});
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        } catch (err) {
            console.error('Error uploading files:', err);
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    if (reportData === undefined) {
        return <div>Loading...</div>;
    }

    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    const isSubmitDisabled = Object.keys(selectedImages).length === 0 || Object.keys(selectedImages).length !== lenghtOfRejectedReports;

    return (
        <>
            <Container maxWidth="xl">
                <>
                    {shownMsg && (
                        <Grid item xs={12} md={12}>
                            <Box
                                sx={{
                                    backgroundColor: '#f8d7da',
                                    padding: 2,
                                    borderRadius: '8px',
                                    border: '1px solid #f5c2c7'
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    component="p"
                                    sx={{ color: '#842029', fontWeight: 'bold' }}
                                >
                                    Some of Document rejected by doctor. Please upload the document again.
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                    {props.reportStatus === '1' && (
                        <Container maxWidth="xl">
                            <Box
                                sx={{
                                    backgroundColor: '#d1e7dd',
                                    padding: 2,
                                    borderRadius: '8px',
                                    border: '1px solid #badbcc',
                                    mt: 4
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    component="p"
                                    sx={{ color: '#0f5132', fontWeight: 'bold' }}
                                >
                                    Your documents are verified. HR will send the offer letter soon.
                                </Typography>
                            </Box>
                        </Container>
                    )}
                    <Box mt={4} textAlign="left">
                        <Typography gutterBottom>
                            Package Name: {packageInfo?.name}
                        </Typography>
                    </Box>
                    <Box mt={4} textAlign="left">
                        <TableContainer component={Paper} sx={{ mb: 2 }}>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow sx={styles.TableRow}>
                                        <StyledTableCell><strong>Report Name</strong></StyledTableCell>
                                        <StyledTableCell><strong>Created On</strong></StyledTableCell>
                                        <StyledTableCell><strong>Uploaded Images</strong></StyledTableCell>
                                        <StyledTableCell><strong>Document Status</strong></StyledTableCell>
                                        <StyledTableCell><strong>Doctor Reject Comment</strong></StyledTableCell>
                                        <StyledTableCell><strong>Actions</strong></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reportData.length === 0 ? (
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={8} align="center">
                                                No records found
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ) : (
                                        reportData?.map((report, index) => (
                                            <StyledTableRow key={index} sx={styles.TableRow}>
                                                <StyledTableCell>{report?.name || `Report ${index + 1}`}</StyledTableCell>
                                                <StyledTableCell>
                                                    {dayjs(report?.created_at).format('YYYY-MM-DD HH:mm:ss')}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {selectedImages[report.id] ? (
                                                        <img
                                                            src={URL.createObjectURL(selectedImages[report.id])}
                                                            alt="Selected"
                                                            style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                                        />
                                                    ) : report?.image_path ? (
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={`${baseUrl}uploads/${report?.image_path}`}
                                                            alt="Uploaded"
                                                            style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                                        />
                                                    ) : (
                                                        "Image not Uploaded"
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {checkStatus(report?.status)}
                                                </StyledTableCell>
                                                <StyledTableCell>{report?.comment}</StyledTableCell>
                                                <StyledTableCell>
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        ref={el => fileInputRefs.current[index] = el}
                                                        style={{ display: 'none' }}
                                                        onChange={(event) => handleFileChange(event, report.id)}
                                                    />
                                                    <Grid container spacing={1} justifyContent="flex-start" alignItems="start" display={'flex'} flexDirection={'column'}>
                                                        <Grid item>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={!checkStatusCondition(report.status)}
                                                                onClick={() => handleButtonClick(index)}
                                                            >
                                                                {report?.image_path || selectedImages[report.id] ? "Change" : "Upload"} Image
                                                            </Button>
                                                        </Grid>

                                                        <Grid item>
                                                            {(report?.image_path || selectedImages[report.id]) && (
                                                                <DeleteButton
                                                                    id={report?.id}
                                                                    fieldName={`${report?.name + ' report image'}`}
                                                                    index={report?.index}
                                                                    status={report?.status}
                                                                    titleName="image"
                                                                    handleDeleteRow={() => deleteUploadImg(report?.id)}
                                                                />
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    )}
                                    <StyledTableRow />
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box>
                        <Grid item xs={12} md={5} sx={{ float: 'right' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                sx={{ width: '100%', padding: '10px !important' }}
                                disabled={isSubmitDisabled || loading}
                            >
                                {loading ? <CircularProgress size={24} /> : 'Submit'}
                            </Button>
                        </Grid>
                    </Box>
                </>
            </Container>
        </>
    );
}