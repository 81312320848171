import React from 'react';
import { Route } from 'react-router-dom';
import ExaminationAll from "../component/Navbar/Examination-SubMenus/Examination/examinationAll";
import LetterIssue from "../component/Navbar/Examination-SubMenus/Examination/letterIssue";
import UnfitListing from "../component/Navbar/Examination-SubMenus/Examination/unfitListing";
import FitnessCertificate from "../component/Navbar/Examination-SubMenus/Examination/fitnessCertificate";
import PeriodicDashboard from "../component/Navbar/Examination-SubMenus/Examination/periodicDashboard";
import ExaminationCounselling from "../component/Navbar/Examination-SubMenus/Examination/examinationCounselling";
import PersonalDetailReport from "../component/Navbar/Examination-SubMenus/Examination-Report/personalDetailReport";
import ClinicalExaminationReport from "../component/Navbar/Examination-SubMenus/Examination-Report/clinicalExaminationReport";
import ComplianceReport from "../component/Navbar/Examination-SubMenus/Examination-Report/complianceReport";
import HealthCardReport from "../component/Navbar/Examination-SubMenus/Examination-Report/healthCardReport";
import PulmonaryReport from "../component/Navbar/Examination-SubMenus/Examination-Report/pulmonaryReport";
import HearingReport from "../component/Navbar/Examination-SubMenus/Examination-Report/hearingReport";
import VisionReport from "../component/Navbar/Examination-SubMenus/Examination-Report/visionReport";
import DiagnosisReport from "../component/Navbar/Examination-SubMenus/Examination-Report/diagnosisReport";
import ExaminationStatusReport from "../component/Navbar/Examination-SubMenus/Examination-Report/examinationStatusReport";
import OtherExaminationReport from "../component/Navbar/Examination-SubMenus/Examination-Report/otherExaminationReport";
import ExportTestReport from "../component/Navbar/Examination-SubMenus/Examination-Report/exportTestReport";
import ExportClinicalReport from "../component/Navbar/Examination-SubMenus/Examination-Report/exportClinicalReport";
import ExportVitalReport from "../component/Navbar/Examination-SubMenus/Examination-Report/exportVitalReport";
import ExportAudiometryReport from "../component/Navbar/Examination-SubMenus/Examination-Report/exportAudiometryReport";
import ExportPersonalDetailReport from "../component/Navbar/Examination-SubMenus/Examination-Report/exportPersonalDetailReport";
import ExportReports from "../component/Navbar/Examination-SubMenus/Examination-Report/exportReports";
import ExaminationSummaryReport from "../component/Navbar/Examination-SubMenus/Examination-Report/examinationSummaryReport";
import ExaminationPlanning from "../component/Navbar/Examination-SubMenus/Freeze-Periodic-List/examinationPlanning";
import FreezePeriodicAllList from "../component/Navbar/Examination-SubMenus/Freeze-Periodic-List/freezePeriodicAllList";
import FreezePeriodicList from "../component/Navbar/Examination-SubMenus/Freeze-Periodic-List/freezePeriodicList";
import TestDue from "../component/Navbar/Examination-SubMenus/Freeze-Periodic-List/testDue";
import CriteriaGroup from "../component/Navbar/Examination-SubMenus/Freeze-Periodic-List/criteriaGroup";
import SearchCriteriaGroup from "../component/Navbar/Examination-SubMenus/Freeze-Periodic-List/searchCriteriaGroup";
import EmployeeFreezeStatus from "../component/Navbar/Examination-SubMenus/Freeze-Periodic-List/employeeFreezeStatus";
import ExaminationVital from "../component/Navbar/Examination-SubMenus/Examination/examinationVital";

// Export routes directly as an array
const ExaminationRoutes = [
    <Route path="examination/examination-all" element={<ExaminationAll />} />,
    <Route path="examination/examination-all/examination-vital" element={<ExaminationVital />} />,
    <Route path="examination/letter-issue" element={<LetterIssue />} />,
    <Route path="examination/unfit-listing" element={<UnfitListing />} />,
    <Route path="examination/fitness-certificate" element={<FitnessCertificate />} />,
    <Route path="examination/periodic-dashboard" element={<PeriodicDashboard />} />,
    <Route path="examination/examination-counselling" element={<ExaminationCounselling />} />,
    <Route path="examination/examination-report/personal-detail-report" element={<PersonalDetailReport />} />,
    <Route path="examination/examination-report/clinical-examination-report" element={<ClinicalExaminationReport />} />,
    <Route path="examination/examination-report/compliance-report" element={<ComplianceReport />} />,
    <Route path="examination/examination-report/health-card-report" element={<HealthCardReport />} />,
    <Route path="examination/examination-report/pulmonary-report" element={<PulmonaryReport />} />,
    <Route path="examination/examination-report/hearing-report" element={<HearingReport />} />,
    <Route path="examination/examination-report/vision-report" element={<VisionReport />} />,
    <Route path="examination/examination-report/diagnosis-report" element={<DiagnosisReport />} />,
    <Route path="examination/examination-report/examination-status-report" element={<ExaminationStatusReport />} />,
    <Route path="examination/examination-report/other-examination-report" element={<OtherExaminationReport />} />,
    <Route path="examination/examination-report/export-test-report" element={<ExportTestReport />} />,
    <Route path="examination/examination-report/export-clinical-report" element={<ExportClinicalReport />} />,
    <Route path="examination/examination-report/export-vital-report" element={<ExportVitalReport />} />,
    <Route path="examination/examination-report/export-audiometry-report" element={<ExportAudiometryReport />} />,
    <Route path="examination/examination-report/export-personal-detail-report" element={<ExportPersonalDetailReport />} />,
    <Route path="examination/examination-report/export-reports" element={<ExportReports />} />,
    <Route path="examination/examination-report/examination-summary-report" element={<ExaminationSummaryReport />} />,
    <Route path="examination/freeze-periodic-list/examination-planning" element={<ExaminationPlanning />} />,
    <Route path="examination/freeze-periodic-list/freeze-periodic-all-list" element={<FreezePeriodicAllList />} />,
    <Route path="examination/freeze-periodic-list/freeze-periodic-list" element={<FreezePeriodicList />} />,
    <Route path="examination/freeze-periodic-list/test-due" element={<TestDue />} />,
    <Route path="examination/freeze-periodic-list/criteria-group" element={<CriteriaGroup />} />,
    <Route path="examination/freeze-periodic-list/search-criteria-group" element={<SearchCriteriaGroup />} />,
    <Route path="examination/freeze-periodic-list/employee-freeze-status" element={<EmployeeFreezeStatus />} />,
];

export default ExaminationRoutes;
