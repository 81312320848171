import React from 'react';
import { Route } from 'react-router-dom';

// Diagnostic Components
import TestListing from '../component/Navbar/Diagnostic-SubMenus/Diagnostic/TestListing';
import TestAnalysis from '../component/Navbar/Diagnostic-SubMenus/Diagnostic/testAnalysis';
import EmployeeAnalysis from '../component/Navbar/Diagnostic-SubMenus/Diagnostic/employeeAnalysis';
import ServiceCostAnalysis from '../component/Navbar/Diagnostic-SubMenus/Report/serviceCostAnalysis';
import TestReport from '../component/Navbar/Diagnostic-SubMenus/Report/testReport';
import CostReport from '../component/Navbar/Diagnostic-SubMenus/Report/costReport';
import TestListReport from '../component/Navbar/Diagnostic-SubMenus/Report/testListReport';
import TestMaster from '../component/Navbar/Diagnostic-SubMenus/Master/testMaster';
import TestListingOpd from '../component/Navbar/Diagnostic-SubMenus/Diagnostic/TestListingOPD';
import LipidProfileUpdate from '../component/Navbar/Diagnostic-SubMenus/Diagnostic/LipidProfileUpdate';
import TestParameterUpdate from '../component/Navbar/Diagnostic-SubMenus/Master/TestParameterUpdate';

// Export routes directly as an array
const DiagnosticRoutes = [
    // Diagnostic => Sub-Menus
    <Route key="test-listing" path="diagnostic/test-listing" element={<TestListing />} />,
    <Route key="test-analysis" path="diagnostic/test-analysis" element={<TestAnalysis />} />,
    <Route key="employee-analysis" path="diagnostic/employee-analysis" element={<EmployeeAnalysis />} />,
    <Route path="diagnostic/test-listing-opd" element={<TestListingOpd />} />,
    <Route path="diagnostic/lipid-profile-update" element={<LipidProfileUpdate />} />,
    
    // Report => Sub-Menus
    <Route key="service-cost-analysis" path="diagnostic/report/service-cost-analysis" element={<ServiceCostAnalysis />} />,
    <Route key="test-report" path="diagnostic/report/test-report" element={<TestReport />} />,
    <Route key="cost-report" path="diagnostic/report/cost-report" element={<CostReport />} />,
    <Route key="test-list-report" path="diagnostic/report/test-list-report" element={<TestListReport />} />,
    
    // Master Setup => Sub-Menus
    <Route key="test-setup" path="diagnostic/master/test-setup" element={<TestMaster />} />,
    <Route path="diagnostic/master/test-parameter-update" element={<TestParameterUpdate />} />,
];

export default DiagnosticRoutes;
