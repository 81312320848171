import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { SendGetRequest,SendPostRequestWithFile } from '../../../../api/config/ApiCall';
import { saveAs } from 'file-saver';
import {
    Box,
    Button,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    Alert,
    IconButton,
    Stack,
    Divider,
    CssBaseline,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { CommanAppBar } from '../../../Comman/commanAppBar';

export default function ImportPreJoinee() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [validationErrors, setValidationErrors] = useState([]);
    const [isImportEnabled, setIsImportEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const resetInputValue = () => {
        const fileInput = document.querySelector('input[type="file"]');
        if (fileInput) {
            fileInput.value = '';
        }
    };
    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                enqueueSnackbar('Please upload only Excel files (.xlsx)', { variant: 'error' });
                event.target.value = ''; // Reset input
                return;
            }
            setSelectedFile(file);
            setValidationErrors([]);
            setIsImportEnabled(false);
        }
    };

    const handleDownloadTemplate = async () => {
        try {
            setIsLoading(true);
            const response = await SendGetRequest('downloadPreJoineeTemplate', {});
            if (response.status) {
                const bufferData = new Uint8Array(response.data.data);
                const blob = new Blob([bufferData], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                saveAs(blob, 'pre-joinee-template.xlsx');
            } else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        } catch (error) {
            console.error('Error downloading template:', error);
            enqueueSnackbar('Error downloading template', { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleValidate = async () => {
        if (!selectedFile) {
            enqueueSnackbar('Please select a file first', { variant: 'warning' });
            return;
        }

        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await SendPostRequestWithFile('validatePreJoineeEmployee', formData);
            if (response.status) {
                enqueueSnackbar('File validation successful', { variant: 'success' });
                setIsImportEnabled(true);
                setValidationErrors([]);
            } else {
                setIsImportEnabled(false);
                if (response.errors && Array.isArray(response.errors)) {
                    setValidationErrors(response.errors);
                }
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        } catch (error) {
            setIsImportEnabled(false);
            enqueueSnackbar('Error validating file', { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleImport = async () => {
        if (!selectedFile || !isImportEnabled) return;
        const link = `${window.location.origin}/public-form/`;

        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('publicUrl', link);

        try {
            const response = await SendPostRequestWithFile('importPreJoineeEmployee', formData);
            if (response.status) {
                enqueueSnackbar('Pre-joinee data imported successfully', { variant: 'success' });
                setSelectedFile(null);
                setIsImportEnabled(false);
                setValidationErrors([]);
                resetInputValue();
            } else {
                enqueueSnackbar(response.message || 'Import failed', { variant: 'error' });
            }
        } catch (error) {
            enqueueSnackbar('Error importing file', { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleRemoveFile = () => {
        setSelectedFile(null);
        setValidationErrors([]);
        setIsImportEnabled(false);
        // Reset the file input
        resetInputValue();
    };

    return (
        <Box>
            <CssBaseline />
            <CommanAppBar appBarTitle="Import Pre Joinee" showButtons={false} />
            
            <Box sx={{ mt: 7, mx: 2 }}>
                <Paper elevation={3} sx={{ p: 3 }}>
                    <Alert severity="info" sx={{ mb: 3 }}>
                        <Typography variant="body1" component="div">
                            <strong>Important Notes:</strong>
                            <ul>
                                <li>Mandatory Fields: First Name, Email, Date of Birth, Gender And Medical Date</li>
                                <li>Mobile Number should not exceed 10 digits</li>
                                <li>Please use the template file for correct data format.</li>
                            </ul>
                        </Typography>
                    </Alert>

                    <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
                        <Button
                            variant="outlined"
                            startIcon={<DownloadIcon />}
                            onClick={handleDownloadTemplate}
                            disabled={isLoading}
                        >
                            Download Template
                        </Button>

                        <Button
                            variant="contained"
                            component="label"
                            startIcon={<CloudUploadIcon />}
                            disabled={isLoading}
                        >
                            Upload File
                            <input
                                type="file"
                                hidden
                                onChange={handleFileSelect}
                                accept=".xlsx"
                            />
                        </Button>
                    </Stack>

                    {selectedFile && (
                        <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="body1">
                                    Selected File: {selectedFile.name}
                                </Typography>
                                <IconButton onClick={handleRemoveFile} disabled={isLoading}>
                                    <DeleteIcon />
                                </IconButton>
                            </Stack>
                        </Paper>
                    )}

                    {validationErrors.length > 0 && (
                        <Paper variant="outlined" sx={{ p: 2, mb: 3, bgcolor: '#fff3f3' }}>
                            <Typography variant="h6" color="error" gutterBottom>
                                Validation Errors:
                            </Typography>
                            <List dense>
                                {validationErrors.map((error, index) => (
                                    <ListItem key={index}>
                                        <ListItemText primary={error} />
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    )}

                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            onClick={handleValidate}
                            disabled={!selectedFile || isLoading}
                        >
                            Validate
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleImport}
                            disabled={!isImportEnabled || isLoading}
                        >
                            Import
                        </Button>
                    </Stack>
                </Paper>
            </Box>
        </Box>
    );
}
