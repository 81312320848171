import React, { useState, useEffect } from 'react';
import { SendGetRequest } from 'api/config/ApiCall';
import {
    AppBar, Toolbar, Typography, Container, Box, TableContainer, Table, TableHead, TableRow, TableBody, Paper, Button, Grid
} from '@mui/material';
import dayjs from 'dayjs';
import styles from 'css/styles';
import { StyledTableCell, StyledTableRow } from 'helper/employee/employeeHelpers';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';


const DoctorForm = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const fetchUserInfo = async () => {
        try {
            const res = await SendGetRequest("doctorPreJoineeDetail", { doctorID: id });
            setData(res.data);
        } catch (error) {
            console.error('Error fetching job data:', error);
        }
    };
    useEffect(() => {
        fetchUserInfo();
    }, [id]);


    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h5" component="div">
                        Health Care
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container maxWidth="xl">
                <Box mt={4} textAlign="left">
                    <Typography variant="h4" component="h1" gutterBottom>
                        Reports
                    </Typography>
                    <TableContainer component={Paper} sx={{ mb: 2 }}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow sx={styles.TableRow}>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Email</StyledTableCell>
                                    <StyledTableCell>Phone No.</StyledTableCell>
                                    <StyledTableCell>Date of birth</StyledTableCell>
                                    <StyledTableCell>Appointment date time</StyledTableCell>
                                    <StyledTableCell>Blood group</StyledTableCell>
                                    <StyledTableCell>Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.length === 0 || !data ? (
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={8} align="center">
                                            No records found
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ) : (
                                    data?.map((row, index) => (
                                        <StyledTableRow key={index} sx={styles.TableRow}>
                                            <StyledTableCell>
                                                {row?.full_name}
                                            </StyledTableCell>
                                            <StyledTableCell>{row?.email || ''}</StyledTableCell>
                                            <StyledTableCell>{row?.phone_number || ''}</StyledTableCell>
                                            <StyledTableCell> {row?.dob} </StyledTableCell>
                                            <StyledTableCell>{row?.appointment_date_time}</StyledTableCell>
                                            <StyledTableCell>{row?.blood_group}</StyledTableCell>
                                            <StyledTableCell>
                                                <Grid item>
                                                    <Button onClick={() =>
                                                        navigate(`/pre-joine-detail/${row?.id}`, {
                                                            state: { isDoctor: true },
                                                        })
                                                    } variant='contained' sx={{ backgroundColor: '#1976d2', color: '#fff', '&:hover': { backgroundColor: '#1976d2' } }}>View</Button>
                                                </Grid>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Container>
        </div>
    );
};

export default DoctorForm;
