import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    TextField,
    Table,
    AppBar,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Toolbar,
    Grid,
    Typography,
    styled,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import * as Papa from "papaparse";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import SearchIcon from "@mui/icons-material/Search";
import PaginationControls from '../../../Comman/paginationControls';

import PageSizeSelector from 'component/Comman/PageSizeSelector';

import styles from "css/styles";
import { SendGetRequest ,SendPostRequest} from 'api/config/ApiCall';
import { enqueueSnackbar } from "notistack";
import {StyledTableCell, StyledTableRow} from "helper/employee/employeeHelpers";
const drawerWidth = 300;

const initalState ={
    full_name: '',
    email: '',
    code: '',
    phone_number: '',
    specialization: '',
    registration_no: '',
}
const DoctorMaster = () => {
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [globalFilter, setGlobalFilter] = useState("");
    const [searchName, setSearchName] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isReferesh, setIsRefresh] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [isSearched, setIsSearched] = useState(false);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState(initalState);

    const handleSearch = () => {
        if (searchName.trim()) {
            setIsSearched(true);
            fetchData();
        }
    };

    const handleClear = () => {
        if (isSearched) {
            setSearchName("");
            setIsSearched(false);
            fetchData();
        }
    };

    const handleExport = () => {
        const modifiedData = data.map(item => {
            const { id, ...rest } = item;
            return rest;
        });
        const exportedData = Papa.unparse(modifiedData);
        const blob = new Blob([exportedData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'doctors.xlsx');
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const params = {
                name: searchName,
                freeSearch: globalFilter,
                page: pagination.pageIndex + 1,
                limit: pagination.pageSize,
            };
            const response = await SendGetRequest('doctorList', params);
            if (response.status) {
                setData(response.data.drUsers);
                setRowCount(response.data.count);
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            enqueueSnackbar("Error fetching data", { variant: "error" });
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!isSearched) {
            fetchData();
        }
    }, [pagination,globalFilter, isSearched,isReferesh]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSave = async () => {
        try {
            const response = await SendPostRequest('addDoctor', formData);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: 'success' });
                setFormData(initalState);
                setIsRefresh(!isReferesh);
                handleClose();
            } else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }

        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    };

    return (
        <>
        <Box >
            <AppBar
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    mt: { xs: '53px', sm: '64px' },
                    zIndex: 1099
                }}
                elevation={1}
            >
                <Toolbar sx={[styles.h, styles.bgWhite]}>
                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid item>
                            <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                Doctor  Master
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant='contained' sx={{ textTransform: 'none', m: 1 }} onClick={handleClickOpen}> Add Doctor </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Box sx={{ mt: 8, p: 3 }}>
                {/* Search Section */}
                <Paper sx={{ p: 2, mb: 3 }}>
                    <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                        <TextField
                            label="Name"
                            variant="outlined"
                            size="small"
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                            placeholder="Name Like"
                        />
                        <Button
                            variant="contained"
                            startIcon={<SearchIcon />}
                            onClick={handleSearch}
                            disabled={!searchName.trim()}
                        >
                            Search
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={handleClear}
                            disabled={!isSearched}
                        >
                            Clear
                        </Button>
                    </Box>
                </Paper>

                <Box sx={{ mt: 2, mb: 7, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                    <Box component="div" xs={12}>
                        <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                <PageSizeSelector pageSize={pagination.pageSize} setPagination={setPagination} />

                                <Grid item>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>entries </Box>
                                    <Button onClick={handleExport} variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                <TextField
                                    id="filled-search"
                                    type="search"
                                    size='small'
                                    value={globalFilter}
                                    onChange={(e) => setGlobalFilter(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <TableContainer component={Paper} sx={{ mb: 2 }}>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow sx={styles.TableRow}>
                                        <StyledTableCell >

                                        </StyledTableCell>
                                        <StyledTableCell >
                                            Code
                                        </StyledTableCell>

                                        <StyledTableCell >
                                            Name
                                        </StyledTableCell>
                                        <StyledTableCell >
                                            Registration No
                                        </StyledTableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        rowCount === 0 ? (
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={7} align="center">
                                                    No data found
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ) : (
                                            data?.map((row) => (
                                                <StyledTableRow key={row.id} sx={styles.TableRow}>
                                                    <StyledTableCell>Select</StyledTableCell>
                                                    <StyledTableCell>{row.code}</StyledTableCell>
                                                    <StyledTableCell> {row.full_name}</StyledTableCell>
                                                    <StyledTableCell>{row.registration_no}</StyledTableCell>

                                                </StyledTableRow>
                                            )))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <PaginationControls
                            pagination={pagination}
                            rowCount={rowCount}
                            setPagination={setPagination}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
        <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Doctor</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="full_name"
                        label="Full Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.full_name}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="code"
                        label="Code"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.code}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="email"
                        label="Email"
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="registration_no"
                        label="Registration Number"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.registration_no}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="phone_number"
                        label="Phone Number"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.phone_number}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="specialization"
                        label="Specialization"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.specialization}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            </>
    );
};

export default DoctorMaster;
