import React from 'react';
import { Route } from 'react-router-dom';

import PatientRegister from "../component/Navbar/AppointmentModules-SubMenus/patientRegister";
import VisitingDoctorAppointment from "../component/Navbar/AppointmentModules-SubMenus/visitingDoctorAppointment";
import PatientRegisterAnalysis from "../component/Navbar/AppointmentModules-SubMenus/patientRegisterAnalysis";
import AppointmentMasterSetup from "../component/Navbar/AppointmentModules-SubMenus/appointmentMasterSetup";
import VisitingDoctorMaster from "../component/Navbar/AppointmentModules-SubMenus/visitingDoctorMaster";
import TimeSlotSetup from "../component/Navbar/AppointmentModules-SubMenus/timeSlotSetup";
import AppointmentSetup from "../component/Navbar/AppointmentModules-SubMenus/appointmentSetup";
import PatientRegisterAnalysisAdd from "../component/Navbar/AppointmentModules-SubMenus/patientRagisterAnalysisAdd";

// Export routes directly as an array
const AppointmentRoutes = [
    <Route path="appointment-module/patient-register" element={<PatientRegister />} />,
    <Route path="appointment-module/visiting-doctor-appointment" element={<VisitingDoctorAppointment />} />,
    <Route path="appointment-module/patient-register-analysis" element={<PatientRegisterAnalysis />} />,
    <Route path="appointment-module/appointment-master-setup" element={<AppointmentMasterSetup />} />,
    <Route path="appointment-module/appointment-master-setup/patient-register-analysis-add" element={<PatientRegisterAnalysisAdd />} />,
    <Route path="appointment-module/appointment-master-setup/visiting-doctor-master" element={<VisitingDoctorMaster />} />,
    <Route path="appointment-module/appointment-master-setup/time-slot-setup" element={<TimeSlotSetup />} />,
    <Route path="appointment-module/appointment-master-setup/appointment-setup" element={<AppointmentSetup />} />,
];

export default AppointmentRoutes;
