import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    TextField,
    Table,
    AppBar,
    TableBody,
    TableContainer,
    TableHead,
    Paper,
    Toolbar,
    Grid,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Checkbox,
    FormControlLabel,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';
import PaginationControls from '../../../Comman/paginationControls';

import PageSizeSelector from 'component/Comman/PageSizeSelector';

import styles from "css/styles";
import { SendGetRequest, SendPostRequest, SendPutRequest } from 'api/config/ApiCall';
import { enqueueSnackbar } from "notistack";
import { StyledTableCell, StyledTableRow } from "helper/employee/employeeHelpers";
import * as Papa from 'papaparse';
import { saveAs } from 'file-saver';
const drawerWidth = 300;
const DoctorActivityTypeMaster = () => {
    const navigate = useNavigate();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [globalFilter, setGlobalFilter] = useState("");
    const [searchCode, setSearchCode] = useState("");
    const [searchName, setSearchName] = useState("");
    const [isSearched, setIsSearched] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({});

    const handleSearch = () => {
        if (searchCode.trim() || searchName.trim()) {
            setIsSearched(true);
            fetchData();
        }
    };

    const handleClear = () => {
        if (isSearched) {
            setSearchCode("");
            setSearchName("");
            setIsSearched(false);
            fetchData();
        }
    };

    const handleExport = () => {
        //remove id from data
        const modifiedData = data.map(item => {
            const { id, ...rest } = item;
            return rest;
        });
        const exportedData = Papa.unparse(modifiedData);
        const blob = new Blob([exportedData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'doctors_activity.xlsx');
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const params = {
                code: searchCode,
                name: searchName,
                freeSearch: globalFilter,
                page: pagination.pageIndex + 1,
                limit: pagination.pageSize,
            };
            const response = await SendGetRequest("doctorActivityTypeMain", params);
            if (response.status) {
                setData(response.data.data);
                setRowCount(response.data.total);
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            enqueueSnackbar("Error fetching data", { variant: "error" });
        } finally {
            setLoading(false);
        }
    }

    const handleAdd = async (formData) => {
        try {
            const response = await SendPostRequest("doctorActivityTypeMain", formData);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" })
                fetchData(); // Refresh the list
            } else {
                enqueueSnackbar(response.message, { variant: "error" })
            }
        } catch (error) {
            console.error("Error adding doctor activity:", error);
        }
    };

    const handleUpdate = async (id, formData) => {
        try {
            await SendPutRequest("doctorActivityTypeMain", formData, id);
            fetchData(); // Refresh the list
        } catch (error) {
            console.error("Error updating doctor activity:", error);
        }
    };

    const handleOpen = () => {
        setOpen(true);
        setIsEditing(false);
        setFormData({});
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isEditing) {
            handleUpdate(formData.id, formData);
        } else {
            handleAdd(formData);
        }
        handleClose();
    };

    const handleEdit = (row) => {
        setOpen(true);
        setIsEditing(true);
        setFormData(row);
    };

    useEffect(() => {
        if (!isSearched) {
            fetchData();
        }
    }, [pagination,globalFilter, isSearched]);

    const handleSort = (columnId) => {
        console.log(columnId, "handleSort");
    };
    return (
        <Box >
            <AppBar
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    mt: { xs: '53px', sm: '64px' },
                    zIndex: 1099
                }}
                elevation={1}
            >
                <Toolbar sx={[styles.h, styles.bgWhite]}>
                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid item>
                            <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                Doctor Activity Type Master
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant='contained' sx={{ textTransform: 'none', m: 1 }} onClick={handleOpen}> Add</Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Box sx={{ mt: 8, p: 3 }}>
                {/* Search Section */}
                <Paper sx={{ p: 2, mb: 3 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Search by Code"
                                variant="outlined"
                                value={searchCode}
                                onChange={(e) => setSearchCode(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Search by Name"
                                variant="outlined"
                                value={searchName}
                                onChange={(e) => setSearchName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Button
                                variant="contained"
                                onClick={handleSearch}
                                disabled={!searchCode.trim() && !searchName.trim()}
                                sx={{ mr: 1 }}
                            >
                                Search
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={handleClear}
                                disabled={!isSearched}
                            >
                                Clear
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>

                <Box sx={{ mt: 2, mb: 7, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                    <Box component="div" xs={12}>
                        <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                <PageSizeSelector pageSize={pagination.pageSize} setPagination={setPagination} />

                                <Grid item>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>entries </Box>
                                    <Button onClick={handleExport} variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                <TextField
                                    id="filled-search"
                                    type="search"
                                    size='small'
                                    value={globalFilter}
                                    onChange={(e) => setGlobalFilter(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <TableContainer component={Paper} sx={{ mb: 2 }}>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <StyledTableRow sx={styles.TableRow}>
                                        <StyledTableCell onClick={() => handleSort('EmployeeName')}>
                                            Sr no.
                                            <UnfoldMoreOutlinedIcon />
                                        </StyledTableCell>
                                        <StyledTableCell onClick={() => handleSort('EmployeeCode')}>
                                            Code
                                            <UnfoldMoreOutlinedIcon />
                                        </StyledTableCell>

                                        <StyledTableCell onClick={() => handleSort('ToLocation')}>
                                            Name
                                            <UnfoldMoreOutlinedIcon />
                                        </StyledTableCell>
                                        <StyledTableCell onClick={() => handleSort('FromDate')}>
                                            Status
                                            <UnfoldMoreOutlinedIcon />
                                        </StyledTableCell>

                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        rowCount === 0 ? (
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={7} align="center">
                                                    No data found
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ) : (
                                            data.map((row, index) => (
                                                <StyledTableRow key={row.id} sx={styles.TableRow}>
                                                    <StyledTableCell>{index + 1}
                                                    </StyledTableCell>
                                                    <StyledTableCell>{row.code}</StyledTableCell>
                                                    <StyledTableCell> {row.name} </StyledTableCell>
                                                    <StyledTableCell>{row.is_active ? 'Active' : 'Inactive'}</StyledTableCell>

                                                </StyledTableRow>
                                            )))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <PaginationControls
                                rowCount={rowCount}
                                pagination={pagination}
                                setPagination={setPagination}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* Add/Edit Dialog */}
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    {isEditing ? 'Edit Doctor Activity Type' : 'Add Doctor Activity Type'}
                </DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Code"
                                    name="code"
                                    value={formData.code || ''}
                                    onChange={(e) => setFormData({ ...formData, code: e.target.value })}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Name"
                                    name="name"
                                    value={formData.name || ''}
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formData.is_active || false}
                                            onChange={(e) => setFormData({ ...formData, is_active: e.target.checked })}
                                            name="is_active"
                                        />
                                    }
                                    label="Active"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ p: 2 }}>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            {isEditing ? 'Update' : 'Save'}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    );
};

export default DoctorActivityTypeMaster;
