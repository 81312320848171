import React from 'react';
import { FormControl, Box, Select, MenuItem } from '@mui/material';

const Dropdown = ({ label, name, value, options, handleChange, onOpen  }) => (
    <FormControl fullWidth >
        <Box sx={{ fontWeight: 'bold' }}>{label}</Box>
        <Select
            name={name}
            value={value}
            onChange={handleChange}
            onOpen={onOpen}
            displayEmpty
            inputProps={{ 'aria-label': `Select ${name}` }}
        >
            <MenuItem value="" sx={{ ml: 2 }}>-All-</MenuItem>
            {options.map((option) => (
                <MenuItem sx={{ ml: 2 }} key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

export default Dropdown;
