import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  TextField,
  Menu,
  MenuItem,
} from "@mui/material";
import { SendGetRequest } from "api/config/ApiCall";
import CustomDialog from "../../../../Common/CustomDialog";
import * as Papa from "papaparse";
import { saveAs } from "file-saver";
import "jspdf-autotable";
import {generateHealthReport} from "./generatePdfHelper";

const dummyResults = [
  {
    testName: "CBC",
    parameterName: "HB",
    result: "13.5",
    specification: "",
    requestRemark: "",
    range: "13.5000 - 17.5000( gm% )",
    type: "Numeric",
    remark: "",
    dilution: "",
  },
  {
    testName: "CBC",
    parameterName: "R.B.C. COUNT",
    result: "4.83",
    specification: "",
    requestRemark: "",
    range: "4.5000 - 6.5000( mill/cmm )",
    type: "Numeric",
    remark: "",
    dilution: "",
  },
  {
    testName: "CBC",
    parameterName: "MCV",
    result: "84.1",
    specification: "",
    requestRemark: "",
    range: "80.0000 - 96.0000( FL )",
    type: "Numeric",
    remark: "",
    dilution: "",
  },
];

const TestDetailsView = ({ open, onClose, testId }) => {
  const [testData, setTestData] = useState(null);
  const [results, setResults] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const fetchTestDetails = React.useCallback(async () => {
    try {
      const response = await SendGetRequest("diagnosticTest", { id: testId });
      if (response.status) {
        setTestData(response.data?.data);
        setResults(
          dummyResults.map((result) => ({
            ...result,
            result: result.result || "",
            remark: result.remark || "",
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching test details:", error);
    }
  }, [testId]);

  useEffect(() => {
    if (testId) {
      fetchTestDetails();
    }
  }, [fetchTestDetails, testId]);

  const handleResultChange = (index, value) => {
    const newResults = [...results];
    newResults[index] = { ...newResults[index], result: value };
    setResults(newResults);
  };

  const handleRemarkChange = (index, value) => {
    const newResults = [...results];
    newResults[index] = { ...newResults[index], remark: value };
    setResults(newResults);
  };

  const handleSave = () => {
    console.log("Saving test results:", results);
  };

  const handleClear = () => {
    console.log("Clicked on clear");
    setResults(
      dummyResults.map((result) => ({
        ...result,
        result: "",
        remark: "",
      }))
    );
  };

  const handleExportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setAnchorEl(null);
  };

  const handleExportCSV = () => {
    const selectedData = results.map((result) => ({
      EmployeeCode: result.testName,
      EmployeeName: result.testName,
      TestName: result.testName,
      TestParameter: result.parameterName,
      Result: result.result,
      remark: result.remark,
    }));
    const csv = Papa.unparse(selectedData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "test_results.csv");
    handleExportClose();
  };
  const handleGenerateReport = async () => {
    try {
      const pdfDoc = await generateHealthReport(testData);
      pdfDoc.download("health_report.pdf");
    } catch (error) {
      console.error("Error generating health report:", error);
    }
  };
  if (!testData) return null;

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title="Test Listing Result"
      maxWidth="md"
      actions={
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={handleSave}
          >
            Save
          </Button>
          <Button variant="outlined" size="small" onClick={handleClear}>
            Clear
          </Button>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={handleExportClick}
          >
            View Report
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleExportClose}
          >
            <MenuItem onClick={handleExportCSV}>Export as CSV</MenuItem>
            <MenuItem onClick={handleGenerateReport}>
              Export as PDF
            </MenuItem>
          </Menu>
        </Box>
      }
    >
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={4}>
            <Typography>
              <strong>Main Type:</strong> {testData?.examinationType}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>
              <strong>Code:</strong> {testData?.patientName}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>
              <strong>Name:</strong> {testData?.patientName}
            </Typography>
          </Grid>
          {testData?.testDescription && (
            <Grid item xs={12}>
              <Typography>
                <strong>Remark:</strong> {testData?.testDescription}
              </Typography>
            </Grid>
          )}
        </Grid>

        <TableContainer
          component={Paper}
          sx={{ mb: 2 }}
          id="test-results-table"
        >
          <Table size="small">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                <TableCell sx={{ fontWeight: "bold" }}>Test Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Parameter Name
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Result</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Specification</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Request Remark
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Range</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Remark</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Dilution</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((result, index) => (
                <TableRow key={index}>
                  <TableCell>{result.testName}</TableCell>
                  <TableCell>{result.parameterName}</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      value={result.result}
                      onChange={(e) =>
                        handleResultChange(index, e.target.value)
                      }
                      sx={{ "& .MuiOutlinedInput-root": { height: "32px" } }}
                    />
                  </TableCell>
                  <TableCell>{result.specification}</TableCell>
                  <TableCell>{result.requestRemark}</TableCell>
                  <TableCell>{result.range}</TableCell>
                  <TableCell>{result.type}</TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      value={result.remark}
                      onChange={(e) =>
                        handleRemarkChange(index, e.target.value)
                      }
                      sx={{ "& .MuiOutlinedInput-root": { height: "32px" } }}
                    />
                  </TableCell>
                  <TableCell>{result.dilution}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </CustomDialog>
  );
};

export default TestDetailsView;
